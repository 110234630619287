import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField, Autocomplete } from "formik-mui";
import MuiTextField from "@mui/material/TextField";
import * as Yup from "yup";
import Button from "../button";
import BtnSwitch from "../btnSwitch";
import InputImg from "../inputImg";
import { SwitchCadastro, Btns, CamposVideo } from "./styles";

function Login({ values = null, onSubmit }) {
  const history = useHistory();

  // const storage = JSON.parse(localStorage.getItem('login'));

  const initialValues = {
    label: values?.label ?? "",
    login: values?.login ?? undefined,
    video_titulo: values?.video_titulo ?? "",
    video_subtitulo: values?.video_subtitulo ?? "",
    senha: values?.senha ?? undefined,
    exibe_video: values?.exibe_video ?? false,
    obrigatorio: values?.obrigatorio ?? false,
    lgpd_cpf_truncado: values?.lgpd_cpf_truncado ?? false,
    link: values?.link ?? "",
    thumbnail: values?.thumbnail ?? null,
    cad_completo: values?.cad_completo ?? false,
  };

  const optionsLogin = [
    { value: "FUNCIONAL", label: "Funcional" },
    { value: "EMAIL", label: "E-mail" },
    { value: "TELEFONE", label: "Telefone" },
  ];
  const optionsSenha = [
    { value: "LIVRE", label: "Livre" },
    { value: "EMAIL", label: "E-mail" },
    { value: "TELEFONE", label: "Telefone" },
  ];

  const validationSchema = Yup.object().shape({
    label: Yup.string().required("Campo Obrigatório"),
    login: Yup.string().required("Campo Obrigatório"),
    senha: Yup.string().required("Campo Obrigatório"),
    exibe_video: Yup.boolean(),
    obrigatorio: Yup.boolean(),
    lgpd_cpf_truncado: Yup.boolean(),
    link: Yup.string().when("obrigatorio", {
      is: true,
      then: Yup.string().required("Campo Obrigatório"),
      otherwise: Yup.string(),
    }),
    video_subtitulo: Yup.string().when("exibe_video", {
      is: true,
      then: Yup.string().required("Campo Obrigatório"),
      otherwise: Yup.string(),
    }),
    video_titulo: Yup.string().when("exibe_video", {
      is: true,
      then: Yup.string().required("Campo Obrigatório"),
      otherwise: Yup.string(),
    }),
    thumbnail: Yup.string()
      .nullable(true)
      .when("obrigatorio", (obrigatorio) => {
        if (obrigatorio)
          return Yup.string().nullable(true).required("Campo Obrigatório");
      }),
  });

  const senhaCheck = (values, setFieldValue) => {
    if (values.cad_completo === true) {
      setFieldValue("senha", "LIVRE");
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={values ?? initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <SwitchCadastro>
              <span>EXIGIR VÍDEO APÓS O LOGIN</span>
              <BtnSwitch name="exibe_video" />
            </SwitchCadastro>
            {values.exibe_video && (
              <CamposVideo>
                <SwitchCadastro>
                  <span>OBRIGATÓRIO</span>
                  <BtnSwitch name="obrigatorio" />
                </SwitchCadastro>
                <div className="container-form">
                  <Field
                    type="text"
                    label="Titulo do video"
                    component={TextField}
                    name="video_titulo"
                    variant="filled"
                    color="secondary"
                  />
                </div>
                <div className="container-form">
                  <Field
                    type="text"
                    label="Subtitulo do video"
                    component={TextField}
                    name="video_subtitulo"
                    variant="filled"
                    color="secondary"
                  />
                </div>
                <div className="container-form">
                  <Field
                    type="text"
                    label="Link do Vídeo"
                    name="link"
                    component={TextField}
                    variant="filled"
                    color="secondary"
                  />
                </div>
                <InputImg
                  name="thumbnail"
                  placeholder="Thumbnail"
                  showPreview={false}
                />
              </CamposVideo>
            )}
            <SwitchCadastro>
              <span>LOGIN CPF TRUNCADO</span>
              <BtnSwitch name="lgpd_cpf_truncado" />
            </SwitchCadastro>
            <div className="container-form">
              <Field
                name="login"
                component={Autocomplete}
                options={optionsLogin}
                isOptionEqualToValue={(option, value) => option.code === value}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label="Login"
                    variant="filled"
                    color="secondary"
                  />
                )}
                onChange={(_, opt) =>
                  setFieldValue("login", opt?.value ?? undefined)
                }
              />
            </div>
            <div className="container-form">
              <Field
                type="text"
                label="Label Login"
                name="label"
                component={TextField}
                variant="filled"
                color="secondary"
              />
              <ErrorMessage component="span" name="label" />
            </div>
            {values.cad_completo === false ? (
              <div className="container-form">
                <Field
                  name="senha"
                  component={Autocomplete}
                  options={optionsSenha}
                  // getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value
                  }
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label="Senha"
                      variant="filled"
                      color="secondary"
                      value={values.cad_completo ? "LIVRE" : ""}
                    />
                  )}
                  onChange={(_, opt) =>
                    setFieldValue("senha", opt?.value ?? undefined)
                  }
                />
              </div>
            ) : (
              ""
            )}
            <SwitchCadastro>
              <span>EXIGIR CADASTRO COMPLETO</span>
              <BtnSwitch name="cad_completo" />
            </SwitchCadastro>
            <Btns>
              <Button
                title="Cancelar"
                type="button"
                bgc="red"
                onClick={() => history.goBack()}
              />
              <Button
                title="Avançar"
                onClick={() => senhaCheck(values, setFieldValue)}
              />
            </Btns>
          </Form>
        );
      }}
    </Formik>
  );
}

export default Login;
