import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { TextField, Autocomplete } from "formik-mui";
import MuiTextField from "@mui/material/TextField";
import FormikTextField from "../../../components/inputMask";
import * as Yup from "yup";
import PasswordStrengthBar from "react-password-strength-bar";

import { USUARIOS } from "../../../routes/paths";

import { api } from "../../../services/api";

import Topo from "../../../components/topoCadastro";
import Button from "../../../components/button";
import { Alert } from "../../../components/alert/index";

import logo from "../../../assets/img/admin/logo3.svg";

import { Novo } from "./styles";

function NovoUsuario({ values = null }) {
  const history = useHistory();
  const [contratantes, setContratantes] = useState([]);

  const metricas = ["Fraca", "Fraca", "Certo", "Bom", "Forte"];

  const optionsTipo = [
    { value: "1", label: "Ver todas as ações" },
    { value: "2", label: "Ver apenas as ações da sua contratante" },
  ];

  const initialValues = {
    nome: values?.nome ?? "",
    email: values?.email ?? "",
    celular: values?.celular ?? "",
    contratante_id: values?.contratante_id ?? undefined,
    tipo: values?.tipo ?? undefined,
    senha: values?.senha ?? "",
    confirma_senha: values?.confirma_senha ?? "",
  };

  const validationSchema = Yup.object().shape({
    nome: Yup.string().required("Campo Obrigatório"),
    email: Yup.string().required("Campo Obrigatório"),
    celular: Yup.string().required("Campo Obrigatório"),
    contratante_id: Yup.number().required("Campo Obrigatório"),
    tipo: Yup.string().required("Campo Obrigatório"),
    senha: Yup.string().required("Campo Obrigatório"),
    confirma_senha: Yup.string().required("Campo Obrigatório"),
  });

  useEffect(() => {
    api
      .get(`/contratante`)
      .then((data) => {
        const options = data.map(({ id, nome }) => ({
          value: id,
          label: nome,
        }));

        setContratantes(options);
      })
      .catch(() => {
        setContratantes([]);
      });
  }, []);

  async function handleSubmit(values) {
    api
      .post(`/usuario/cadastra`, {
        nome: values.nome,
        email: values.email,
        celular: values.celular,
        contratante_id: values.contratante_id,
        tipo: values.tipo,
        senha: values.senha,
      })
      .then((data) => {
        Alert({
          title: "sucesso",
          text: "Sucesso ao cadastro",
          icon: "success",
        });
        history.push(USUARIOS);
      })
      .catch((error) => {
        Alert({
          title: "Erro",
          text: error,
          icon: "error",
        });
      });
  }

  return (
    <Novo>
      <Topo />
      <div className="container">
        <div className="s-esq">
          <img src={logo} alt="" />
          <span>Novo Usuário</span>
        </div>
        <div className="s-dir">
          <h1>Novo Usuário</h1>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const { handleChange, values, setFieldValue } = props;
              const { senha } = values;
              return (
                <Form>
                  <div className="container-form">
                    <Field
                      type="text"
                      name="nome"
                      label="Nome"
                      component={TextField}
                      variant="filled"
                      color="secondary"
                    />
                  </div>
                  <div className="container-form">
                    <Field
                      type="email"
                      name="email"
                      label="Email"
                      component={TextField}
                      variant="filled"
                      color="secondary"
                    />
                  </div>
                  <div className="container-form">
                    <Field
                      component={TextField}
                      label="Celular"
                      name="celular"
                      variant="filled"
                      InputProps={{
                        inputComponent: FormikTextField,
                        inputProps: { mask: "(99) 99999-9999", formik: props },
                      }}
                    />
                  </div>
                  <div className="container-form">
                    <Field
                      component={Autocomplete}
                      name="contratante_id"
                      options={contratantes}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label="Contratante"
                          variant="filled"
                          color="secondary"
                        />
                      )}
                      onChange={(_, opt) =>
                        setFieldValue("contratante_id", opt?.value ?? undefined)
                      }
                    />
                  </div>
                  <div className="container-form">
                    <Field
                      component={Autocomplete}
                      name="tipo"
                      options={optionsTipo}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          label="Tipo usuário"
                          variant="filled"
                          color="secondary"
                        />
                      )}
                      onChange={(_, opt) =>
                        setFieldValue("tipo", opt?.value ?? undefined)
                      }
                    />
                  </div>
                  <div className="container-form">
                    <Field
                      type="password"
                      name="senha"
                      label="Senha"
                      component={TextField}
                      variant="filled"
                      color="secondary"
                    />
                    <PasswordStrengthBar
                      onChange={handleChange}
                      password={senha}
                      scoreWords={metricas}
                      shortScoreWord="Muito curta"
                      type="password"
                      placeholder="Senha"
                      name="senha"
                      autoComplete="new-password"
                    />
                  </div>
                  <div className="container-form">
                    <Field
                      type="password"
                      name="confirma_senha"
                      label="Confirmar Senha"
                      component={TextField}
                      variant="filled"
                      color="secondary"
                    />
                  </div>

                  <Button title="Cadastrar" />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Novo>
  );
}

export default NovoUsuario;
