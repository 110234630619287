import Login from "../pages/login/index";
import Principal from "../pages/principal/index";
import Usuarios from "../pages/admin/usuarios";
import NovoUsuario from "../pages/admin/usuarios/novo";
import Acoes from "../pages/admin/acoes";
import NovaAcao from "../pages/admin/acoes/nova";
import DetalhesAcao from "../pages/admin/acoes/detalhes";
import Produtos from "../pages/admin/produtos";
import Contratantes from "../pages/admin/contratantes";
import NovoContratante from "../pages/admin/contratantes/novo";

import {
  LOGIN,
  PRINCIPAL,
  USUARIOS,
  NOVO_USUARIO,
  ACOES,
  NOVA_ACAO,
  DETALHES_ACAO,
  PRODUTOS,
  CONTRATANTES,
  NOVO_CONTRATANTE,
} from "./paths";

const routes = [
  { path: LOGIN, exact: true, component: Login, isPublic: true },

  { path: PRINCIPAL, exact: true, component: Principal },

  { path: USUARIOS, exact: true, component: Usuarios },
  { path: NOVO_USUARIO, component: NovoUsuario },
  { path: PRODUTOS, exact: true, component: Produtos },

  { path: ACOES, exact: true, component: Acoes },
  { path: NOVA_ACAO, component: NovaAcao },
  { path: DETALHES_ACAO, component: DetalhesAcao },

  { path: CONTRATANTES, exact: true, component: Contratantes },
  { path: NOVO_CONTRATANTE, component: NovoContratante },
];

export default routes;
