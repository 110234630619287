import styled from "styled-components";

import { Container as Button } from "../../../components/button/styles";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.Cinza05};
  margin-left: 160px;
  min-height: 100vh;
  .conteudo {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding: 30px;
    .titulo {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      padding: 20px;
      align-items: center;
      justify-items: center;
      background: #6f3e98;
      border-radius: 8px 8px 0px 0px;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #fff;
      }
    }
    .img-loading {
      display: block;
      margin: 50px auto;
      width: 100px;
      height: 100px;
    }
  }
`;

export const Novo = styled.div`
  min-height: 100vh;
  .container {
    margin-top: 100px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 241px 1fr;
    grid-gap: 150px;
    padding-left: 150px;
    .s-esq {
      span {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        color: #6f3e98;
        position: relative;
        height: 48px;
        &::before {
          content: "";
          background-color: ${(props) => props.theme.colors.Laranja1};
          width: 9px;
          height: 9px;
          display: block;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      img {
        margin-bottom: 40px;
        width: 190px;
      }
    }
    .s-dir {
      h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.preto};
        margin-bottom: 50px;
      }
      ${Button} {
        width: 181px;
      }
    }
  }
  @media (max-width: 1366px) {
    .container {
      grid-gap: 50px;
      padding-left: 50px;
    }
  }
`;
