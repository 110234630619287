import styled from 'styled-components';

export const PreviewContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 8px;

  width: 48px;
  height: 48px;

  border-radius: 7px;

  background: ${({ background }) => background};

  & + input {
    padding-right: 64px;
  }
`;
