import styled from "styled-components";
import { Container as Select } from "../../../components/select/styles";
import { Container as Progress } from "../../../components/progress/styles";

import busca from "../../../assets/img/admin/busca.svg";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.Cinza05};
  margin-left: 160px;
  min-height: 100vh;
  .conteudo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
    grid-gap: 30px;
    padding: 30px;
  }
  .img-loading {
    display: block;
    margin: 50px auto;
    width: 100px;
    height: 100px;
  }
`;

export const Nova = styled.div`
  min-height: 100vh;
  .container {
    margin-top: 100px;
    margin-bottom: 100px;
    display: grid;
    grid-template-columns: 241px 1fr;
    grid-gap: 150px;
    padding-left: 150px;
    .s-esq {
      img {
        margin-bottom: 40px;
        width: 190px;
      }
    }
    .s-dir {
      ${Select} {
        background: #f6f6f6;
        border-radius: 4px;
      }
      h1 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.preto};
        margin: 50px 0;
      }
    }
  }

  @media (max-width: 1366px) {
    .container {
      grid-gap: 50px;
      padding-left: 50px;
    }
  }
`;

export const Detalhes = styled.div`
  min-height: 100vh;
  .container {
    width: 1600px;
  }

  .progress-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    ${Progress} {
      width: 300px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .btns {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      button {
        width: auto;
        /* border: 1px solid #703d98; */
        border-radius: 4px;
        font-size: 14px;
        padding: 0 20px;
        text-transform: uppercase;
      }
      span{
        margin-left:78px
      }
    }
  }

  .abas-tabela {
    display: flex;
    position: relative;
    margin-top: 20px;
    .abas {
      display: flex;
      z-index: 1;
    }
    .item {
      font-size: 16px;
      line-height: 18px;
      color: #626262;
      background: #eeeeee;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 159px;
      height: 64px;
      cursor: pointer;
      &.active {
        font-weight: 500;
        color: #6f3e98;
        background: #ffffff;
        border: 1px solid #ba82ea;
        border-bottom: none;
        position: relative;
        z-index: 10;
      }
    }
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #ba82ea;
      position: absolute;
      bottom: 0;
      left: 0px;
    }
  }

  .campos-busca {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    input {
      background: #ffffff;
      border: 1px solid #cecece;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .busca {
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 18px;
        background-image: url(${busca});
        z-index: 20;
      }
    }
    .select {
      display: flex;
      grid-gap: 10px;
      ${Select} {
        width: 200px;
        background: #ffffff;
        border: 1px solid #cecece;
        box-sizing: border-box;
        border-radius: 4px;
        height: 56px;
        .react-select__control {
          height: 56px;
        }
      }
    }
  }
  .img-loading {
    display: block;
    margin: 50px auto;
    width: 100px;
    height: 100px;
  }
`;
