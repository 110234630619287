import { api } from '../services/api';
import { useCallback, useState } from "react";
import _ from "lodash";

export const useDebounce = (obj = null, wait = 1000) => {
  const [state, setState] = useState(obj);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounce = useCallback(
    _.debounce((_prop) => {
      setState(_prop);
    }, wait),
    []
  );

  const setDebouncedState = (_val) => {
    debounce(_val);
  };

  return [state, setDebouncedState];
};

export async function getAcaoParticipantes(id, paginacao = null, search) {
  const data = await api.post(`/acao/getAcaoParticipantesPaginacao${paginacao ? `?page=${paginacao}` : ""}`,
   { id_acao: id, 'search': search }
  );
  if (!data) return { result: [], page: {} };
  
  const page = {
    ...data.participantes
  };
  const result = data.participantes.data;
  return { result, page };
}

export const optionsDisparo = [
  { value: 1, label: 'Disparo Agendado' },
  { value: 2, label: 'Imediato' },
  { value: 3, label: 'Data da Planilha' },
];

export const RegExp = {
  celular: /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
  phone: /^(?:\()[0-9]{2}(?:\))\s?[0-9]{4,5}(?:-)[0-9]{4}$/,
  cnpj: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
};

export function validatePhoneInput(event) {
  let { value } = event.target;

  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})/, '($1) ');
  const isMobilePhone = /^[(][0-9][0-9][)][\s][9]/.test(value);

  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }

  if (isMobilePhone) {
    event.currentTarget.maxLength = 16;
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d{1})/, '($1) $2.');
    value = value.replace(/(\d{4})/, '$1-');
    value = value.replace(/(\d{4})/, '$1');
  } else {
    event.currentTarget.maxLength = 14;
    value = value.replace(/(\d{4})/, '$1-');
    value = value.replace(/(\d{4})/, '$1');
  }

  event.currentTarget.value = value;
}

export function errorMenssage(error) {
  if (!ObjIsEmpty(error)) {
    return {
      err: error.request.response,
      status: error.request.status,
      url: error.config.url,
    };
  }

  return { err: 'Não autorizado', status: 401, url: '' };
}

export function filtroContratante(data = [], busca) {
  return data.filter((data) => {
    return JSON.stringify(data, [
      'area',
      'nome',
      'cnpj',
      'email',
      'contato',
      'telefone',
    ])
      .toLocaleLowerCase()
      .includes(busca.toLocaleLowerCase());
  });
}

export function filtroAcoes(data = [], busca, status, tipo, date) {
  if (status && status !== 'status') {
    data = data.filter((item) => item.status.toLocaleLowerCase() === status);
  }

  if (tipo && tipo !== 'tipo') {
    data = data.filter((item) => item.tipo.toLocaleLowerCase() === tipo);
  }

  if (date) {
    data = data.filter((item) => item.data.split(' ')[0] === date);
  }

  return data.filter((data) => {
    return JSON.stringify(data, [
      'cliente',
      'data',
      'links',
      'nome',
      'status',
      'tipo',
      'solicitante',
    ])
      .toLocaleLowerCase()
      .includes(busca.toLocaleLowerCase());
  });
}

export function getMonth(today) {
  return today.getMonth() + 1 < 10
    ? '0' + (today.getMonth() + 1)
    : today.getMonth() + 1;
}

export function convertDatePickerTimeToMySQLTime(str) {
  var hours, minutes, seconds;
  var date = new Date(str);
  var month = ('0' + (date.getMonth() + 1)).slice(-2);
  var day = ('0' + date.getDate()).slice(-2);
  hours = ('0' + date.getHours()).slice(-2);
  minutes = ('0' + date.getMinutes()).slice(-2);
  seconds = ('0' + date.getSeconds()).slice(-2);

  var mySQLDate = [date.getFullYear(), month, day].join('-');
  var mySQLTime = [hours, minutes, seconds].join(':');
  return [mySQLDate, mySQLTime].join(' ');
}

export function convertDatePickerTimeToUser(str) {
  const data = str.split(' ')[0];
  const tempo = str.split(' ')[1];

  return data.split('-').reverse().join('/') + ' ' + tempo;
}

export function formatDataTime(dt) {
  const arr = dt.split(' ');
  const data = arr[0].split('-').reverse().join('.');
  const tempo = arr[1];
  return data + ' - ' + tempo;
}

export function file(arq) {
  var file = new File(arq, 'sample.xlsx', {
    lastModified: new Date(0), // optional - default = now
  });

  var fr = new FileReader();

  return fr.readAsText(file);
}

export async function convertBlobToFileFromUrl(
  url,
  name = 'modelo-planilha-yetz-now.xlsx'
) {
  let arqblob = await fetch(url).then((r) => r.blob());

  return new File([arqblob], name);
}

export async function convertBlobToImgFromUrl(
  url,
  name = 'modelo-planilha-yetz-now.xlsx'
) {
  let imgblob = await fetch(url).then((r) => r.blob());
  return new File([imgblob], '431205_303844113064860_1743074902_n.jpg', {
    type: 'image/png',
    lastModified: new Date().getTime(),
  });
}

export function dataURLtoFile(dataurl, filename) {
  if (dataurl === 'editar-file') return false;

  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function ObjIsEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function filterSelect(id, arr) {
  if (!arr.length) return [];
  return {
    ...arr.filter((value) => value.value === id)[0],
  };
}

export function nomeArq(name, size) {
  const extensions = name.split('.').pop();

  return name.substr(0, size) + '...' + extensions;
}

export function fmtMSS(s) {
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
}

export const TimeAgo = (function () {
  var self = {};

  // Public Methods
  self.locales = {
    prefix: '',
    sufix: 'atrás',

    seconds: 'menos de um minuto',
    minute: 'cerca de um minuto',
    minutes: '%d minutos',
    hour: 'cerca de uma hora',
    hours: 'cerca de %d horas',
    day: 'um dia',
    days: '%d dias',
    month: 'cerca de um mês',
    months: '%d meses',
    year: 'cerca de um ano',
    years: '%d anos',
  };

  self.inWords = function (timeAgo) {
    var seconds = Math.floor((new Date() - parseInt(timeAgo)) / 1000),
      separator = this.locales.separator || ' ',
      words = this.locales.prefix + separator,
      interval = 0,
      intervals = {
        year: seconds / 31536000,
        month: seconds / 2592000,
        day: seconds / 86400,
        hour: seconds / 3600,
        minute: seconds / 60,
      };

    var distance = this.locales.seconds;

    for (var key in intervals) {
      interval = Math.floor(intervals[key]);

      if (interval > 1) {
        distance = this.locales[key + 's'];
        break;
      } else if (interval === 1) {
        distance = this.locales[key];
        break;
      }
    }

    distance = distance.replace(/%d/i, interval);
    words += distance + separator + this.locales.sufix;

    return words.trim();
  };

  return self;
})();

export function getToken() {
  if (Object.entries(localStorage)[0]) {
    if (Object.entries(localStorage)[0][1]) {
      const local = JSON.parse(Object.entries(localStorage)[0][1]);
      const user = JSON.parse(local.user);
      if (user.token) return user.token;
    }
  }

  return false;
}

export async function getContratante() {
  const { data } = await api.get('contratante');
  return data.map((data) => {
    const { nome: label, id: value } = data;
    return { label, value };
  });
}

export async function getTipo() {
  const { data } = await api.get('acao/tipo');
  const tipos = data.tipos.map((data) => {
    const { tipo: label, id: value } = data;
    return { label, value };
  });

  const periodos = data.periodos.map((data) => {
    const { descricao: label, id: value } = data;
    return { label, value };
  });

  return { tipos, periodos };
}

export async function getTemplate() {
  const { data } = await api.get('template');
  const { templates_whatsapp, templates_mail, templates_sms } = data;
  return { templates_whatsapp, templates_mail, templates_sms };
}

export function checkAcaoData(data, objFormik) {
  const {
    contratante_id,
    nome,
    acao_tipo_id,
    template_id,
    template_arte,
    fields,
    checkPreview,
  } = data;

  const initialValues = {
    contratante_id,
    nome,
    acao_tipo_id,
    template_id,
    template_arte,
    fields,
    checkPreview,
  };

  return ObjIsEmpty(data) ? objFormik.acoes.dados : initialValues;
}

export function checkDisparoData(data, objFormik) {
  const { tipo_disparo, data_disparo } = data;

  const initialValues = {
    tipo_disparo,
    data_disparo,
  };

  return ObjIsEmpty(initialValues) ? objFormik.acoes.tipo : initialValues;
}

export function checkParticipantesData(data, objFormik) {
  const { recipientes } = data;

  const initialValues = {
    recipientes,
  };

  return ObjIsEmpty(initialValues) ? objFormik.acoes.dados : initialValues;
}

export function sendDisparo(data, value) {
  const {
    template_arte,
    nome_arquivo,
    nome_img,
    recipientes,
    contratante_id,
    nome,
    acao_tipo_id,
    template_id,
    tipo_disparo,
    data_disparo,
    acao_periodo_id,
  } = data;
  const arquivo = dataURLtoFile(
    value.recipientes ? value.recipientes : recipientes,
    nome_arquivo
  );

  const img = template_arte ? dataURLtoFile(template_arte, nome_img) : null;
  const dados = {
    contratante_id,
    nome,
    acao_tipo_id,
    template_id,
    tipo_disparo,
    data_disparo,
    acao_periodo_id,
  };

  const formData = new FormData();

  const objKeys = Object.keys(dados);

  objKeys.forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append('recipientes', arquivo);
  if (template_arte) {
    formData.append('template_arte', img);
  }

  return formData;
}

export function tipoDisparo(id) {
  if (id === 2) return 'imediato';
  if (id === 3) return 'planilha';
}

export function changeBreadcrumb(resumo) {
  if (resumo.disparo_agendado) {
    return true;
  } else {
    return false;
  }
}

export async function getDetalhe(id) {
  const { data } = await api.get(`/acao/${id}`);

  if (!data) return { result: [], topo: {} };

  const {
    status: { status },
    cliente = 'Banco Itaú',
    nome,
    tipo: { tipo },
    qtd_links_entregues,
    qtd_links_enviados,
    data_disparo,
  } = data;

  const links = qtd_links_enviados + ' / ' + qtd_links_entregues;

  const topo = {
    status,
    cliente,
    nome,
    tipo,
    links,
    data_disparo,
  };

  const result = data.recipientes.map((users) => {
    const {
      data_recebimento,
      data_visualizacao,
      status,
      custom_fields,
    } = users;

    const { nome, email, celular } = JSON.parse(custom_fields);

    return {
      usuario: nome,
      telefone: celular,
      email,
      recebimento: data_recebimento,
      status,
      visualizacao: data_visualizacao,
    };
  });

  return { result, topo };
}

export async function getDados(id, template, periodos) {
  const { data } = await api.get(`/acao/${id}`);

  const {
    acao_tipo_id,
    contratante: { id: contratante_id },
    data_disparo,
    nome,
    template_id,
    acao_periodo_id,
    arte_temporary_url,
    arte_original,
    recipient_file,
  } = data;

  const tipoTemp = tipoTemplate(acao_tipo_id, template);

  const campos = tipoTemp.filter(
    (item) => item.id === template_id && item.has_image === true
  );

  const preview_tema = objTemplate(tipoTemp).filter(
    (item) => item.value === template_id
  );

  const disparo_tipo = periodos.filter(
    (item) => item.value === acao_periodo_id
  );

  const [preview = 'Sem Template'] = preview_tema;
  const { label = '' } = preview;

  const [disparo] = disparo_tipo;
  const { label: tipo_disparo_name } = disparo;

  return {
    toggle_acao: acao_tipo_id,
    campos: campos.length ? campos[0].fields : '',
    has_image: campos.length ? campos[0].has_image : '',
    contratante_id,
    preview_tema,
    nome,
    disparo: periodos,
    acao_tipo_id,
    template_id,
    tipo_disparo: acao_periodo_id,
    data_disparo: convertDatePickerTimeToUser(data_disparo),
    template: label,
    tipo_disparo_name,
    arte_hash: arte_original,
    preview_img: arte_temporary_url,
    acao_periodo_id,
    toggle_disparo: acao_periodo_id,
    breadcrumb_disparo: false,
    breadcrumb_participante: false,
    nome_arquivo: recipient_file,
    id_disparo: id,
    template_arte: arte_temporary_url && 'editar-file',
  };
}

export async function getLinkExport(id) {
  const {
    data: { file_url },
  } = await api.get(`acao/${id}/export`);
  return file_url;
}

export function openTab(link) {
  window.open(link);
}

export function linkDetaOrlheEdit(status, id) {
  if (status.toLowerCase() === 'enviada') {
    return `/acoes/detalhe/${id}`;
  } else {
    return `/acoes/editar/${id}`;
  }
}

export function selectItem(options, id, returnItem) {
  const item = options.filter((op) => op.value === id);

  if (returnItem) return item;

  if (item.length) {
    return item[0].label;
  }
}

export function objTemplate(options) {
  return options.map((data) => {
    const {
      name: label,
      id: value,
      has_image,
      html_content,
      text,
      fields,
    } = data;
    return { label, value, has_image, html_content, text, fields };
  });
}

export function previewAcao(itens, id) {
  // return itens.filter((item) => item.value === id && item.has_image === true);
  return itens.filter((item) => item.value === id);
}

export function tipoTemplate(id, tempTemplate) {
  const { templates_mail, templates_whatsapp, templates_sms } = tempTemplate;
  switch (id) {
    case 1:
      return templates_sms;

    case 2:
      return templates_whatsapp;

    case 3:
      return templates_mail;

    default:
      break;
  }
}

export function getAcoes(data) {
  return data.map((dados) => {
    const {
      id,
      status: { status },
      contratante: { nome: cliente, contato },
      nome,
      tipo: { tipo },
      qtd_links_entregues,
      qtd_links_enviados,
      data_disparo,
    } = dados;

    const data = formatDataTime(data_disparo);
    const links = qtd_links_enviados + ' / ' + qtd_links_entregues;
    return {
      id,
      status,
      cliente,
      nome,
      tipo,
      links,
      data,
      solicitante: contato,
      qtd_links_enviados,
      qtd_links_entregues,
    };
  });
}

export function formataNumeroTelefone(numero) {
  if (!numero) return false;

  const ddd = numero.substr(2, 2);
  numero = numero.substr(4);

  var length = numero.length;
  var telefoneFormatado;

  if (length === 8) {
    telefoneFormatado =
      '(' + ddd + ') ' + numero.substring(0, 4) + '-' + numero.substring(4, 8);
  } else if (length === 9) {
    telefoneFormatado =
      '(' + ddd + ') ' + numero.substring(0, 5) + '-' + numero.substring(5, 9);
  }

  var arr = telefoneFormatado.split('');

  var telefone = arr
    .map((item, key) => {
      if (key >= 5 && key <= 12) {
        return '*';
      } else {
        return item;
      }
    })
    .join('');

  return telefone;
}

export function returnLastItemUrl(str) {
  return str.split('/').pop();
}
