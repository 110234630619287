import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import Swal from "sweetalert2";

import { api } from "../../services/api";

import Button from "../../components/button";

import { EstoqueParcial } from "./styles";

import close from "../../assets/img/admin/close.png";

export default function ModalEstoqueParcial({ active, handleClose, id_acao }) {
  const initialValues = {
    quantidade: "",
  };
  const validationSchema = Yup.object().shape({
    quantidade: Yup.string().required("Campo Obrigatório"),
  });

  async function handleSubmit(values) {
    api
      .post(`/acao/detalhes/estorno/parcial`, {
        id_acao: id_acao,
        qtd_estornar: values.quantidade,
      })
      .then((data) => {
        if (data.status) {
          handleClose(!active);
          Swal.fire(data.mensagem, "", "info");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <EstoqueParcial>
      <h3>Estorno Parcial</h3>
      <img
        className="close"
        src={close}
        alt=""
        onClick={() => handleClose(!active)}
      />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="container-form">
            <Field
              type="text"
              name="quantidade"
              label="Quantidade"
              component={TextField}
              variant="filled"
              color="secondary"
            />
          </div>

          <Button title="LIBERAR" />
        </Form>
      </Formik>
    </EstoqueParcial>
  );
}
