import React, { useState, useEffect, useContext } from "react";

import TopoUsuarios from "../../../components/topo/topoUsuarios";
import BoxUsuarios from "../../../components/rowTabelas/usuarios";
import Filtro from "../../../components/filtro/index";
import { useHistory } from "react-router-dom";
import { api } from "../../../services/api";
import { Container } from "./styles";
import ScrollToTop from "../../../utils/scrollToTop";
import Menu from "../../../components/menuLateral";

import img from "../../../assets/img/admin/loader-yetz-1.svg";
import { AuthContext } from '../../../contexts/AuthContext';

function Usuarios() {
  const [active, toggleFiltro] = useState(false);
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const { user } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (user?.tipo === 2) {
      history.push('/')
    }
  }, [user, history])

  useEffect(() => {
    api
      .post(`/usuario/lista`)
      .then((data) => {
        setUsuarios(data.usuarios);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSearch = (e) => {
    // setBusca(e.target.value)
    // // eslint-disable-next-line no-unused-expressions
    // dadosFiltrados
  };

  return (
    <>
      <ScrollToTop />
      <>
        <Menu />
      </>
      <Container>
        <TopoUsuarios activeFiltro={active} toggleFiltro={toggleFiltro} />
        <Filtro activeFiltro={active} onChange={(e) => handleSearch(e)} />
        <div className="conteudo">
          <div className="titulo">
            <span>Nome do Usuário</span>
            <span>Data de Cadastro</span>
            <span>Nível de Acesso</span>
            {/* <span>Status</span> */}
            <span></span>
          </div>
          {loading ? (
            <img className="img-loading" src={img} alt="" />
          ) : (
            usuarios.map((data, index) => {
              return <BoxUsuarios data={data} key={index} />;
            })
          )}
        </div>
      </Container>
    </>
  );
}

export default Usuarios;
