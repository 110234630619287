import React from 'react';

import { nomeArq } from '../../utils/helper';

import upload from '../../assets/img/admin/upload.svg';
// import fechar from '../../assets/img/admin/fechar-modal.svg';

function InputFile({ field: { name, value }, form: { setFieldValue } }) {
  return (
    <div className="container-form">
      <label className={value ? 'custom-file nome-arquivo' : 'custom-file'}>
        <input
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={(event) => {
            setFieldValue(name, event.target.files[0]);
          }}
        />

        {value
          ? value.name.length > 25
            ? nomeArq(value.name, 25)
            : value.name
          : 'Upload do Arquivo'}
      </label>

      <img src={upload} alt="" />
    </div>
  );
}

export default InputFile;
