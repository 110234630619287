import React from 'react';

import { Container } from './styles';

export default function Modal({ component: Component, ...props }) {
  const {
    active = false,
    overlay = 'rgba(0,0,0, 0.4)',
    scroll = false,
  } = props;

  return (
    <Container
      className="modal"
      active={active}
      overlay={overlay}
      scroll={scroll}
    >
      <div className="container-body">
        <Component {...props} />
      </div>
    </Container>
  );
}
