import { useEffect, useRef, useState, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

import TextField from "@mui/material/TextField";
import { CountDown } from "../CountDown";

import { Container } from "./styles";

import ValidarLogin from "../../assets/sistema/validae-login.svg";
import Fechar from "../../assets/img/admin/close.png";
// import SetaCima from "../../assets/sistema/seta-cima.svg";
import { ReactComponent as SetaCima } from "../../assets/sistema/seta-cima.svg";

export function ModalValidaLogin({email, onclose, verificaCodigo, error, loadingConfirmCode}) {
    const [disabled, setDisabled] = useState(true);
    const [timer, setTimer] = useState(false);
    const input = useRef(null);
    const authContext = useContext(AuthContext);
    let statusErrorCode = error;
    async function reveenviarCode() {
       const {success} = await authContext.enviaSms(email)
       if (!success){
         statusErrorCode = true;
       }
    }

    useEffect(() => {
        if (statusErrorCode) {
            setTimer(false);
            setDisabled(false);
        } else {
            setTimer(true);
        }
    }, [statusErrorCode]);

    return (
        <Container>
            <button
                className="close"
                onClick={() => {
                    if (onclose) {
                         onclose();
                    }
                }}
            >
                <img src={Fechar} alt="" />
            </button>

            <div className="text-img">
                <div className="text-validadacao">
                    <img className="icon" src={ValidarLogin} alt="" />
                </div>
                <h3 fontSize="mdl">VALIDAÇÃO DE LOGIN</h3>
                <p>
                    Sua conta é protegida com autenticação de dois fatores.
                    Enviamos um SMS. Por favor, digite o código abaixo.
                </p>
                <div className="input-validacao">
                    <TextField
                        className={`input-text ${
                            statusErrorCode ? "error" : ""
                        }`}
                        label="Código de validação"
                        variant="outlined"
                        fullWidth={true}
                        ref={input}
                    />
                </div>

                {statusErrorCode ? <span className="codigo-invalido">Código inválido</span> : null}

                {timer ? (
                    <CountDown setDisabled={setDisabled} setTimer={setTimer} />
                ) : null}

                <div width="100%" mt="16px" mb="24px" className="container-btn">
                    <button
                        loading={loadingConfirmCode}
                        className="btn"
                        onClick={() => {
                            const value =
                                input.current.querySelector("input").value;

                            if (value) {
                                verificaCodigo(value)
                            }
                        }}
                    >
                        CONTINUE
                    </button>
                </div>

                <div className="container-btn reenviar">
                    <button
                        className="btn"
                        disabled={disabled}
                        onClick={() => {
                            reveenviarCode();
                            setTimer(true);
                            setDisabled(true);
                        }}
                    >
                        Reenviar código <SetaCima/>
                    </button>
                </div>
            </div>
        </Container>
    );
}
