import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';

import { LOGIN } from '../../routes/paths';

function RouteWrapper(props) {
  const { isAuthenticated } = useContext(AuthContext);

  if (!props.isPublic && !isAuthenticated) {
    return <Redirect to={LOGIN} from={props.path} />;
  }

  return <Route {...props} />;
}

export default RouteWrapper;
