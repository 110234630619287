import React from 'react';

import Loading from '../loading';

import { Container } from './styles';

export default function Button(props) {
  const {
    title = 'Acessar',
    img = "",
    type = 'submit',
    loading,
  } = props;

  return (
    <Container type={type} {...props}>
      {loading ? (
        <Loading width={100} height={100} item={1} />
      ) : (
        <>
          {title}
          {img ?
            <div className="img">
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src={img} />
            </div> : null}
        </>
      )}
    </Container>
  );
}
