import axios from 'axios';

import { getToken } from '../utils/token';

const baseUrl = `${process.env.REACT_APP_BASE_HTTP}`;
// const baseUrl = 'http://localhost:8000/api';

export const api = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-type": "application/json"
  }
});

api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => Promise.resolve(response.data),
  (error) => {
    // TODO: redirecionar para o login caso receba status code 401
    return Promise.reject(error.response.data);
  }
);
