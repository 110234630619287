import styled from 'styled-components';

export const Container = styled.div`
  width: 31px;
  height: 17px;
  position: relative;
  background-color: ${(props) => props.theme.colors.Cinza04};
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s;
  &.active {
    transition: all 0.3s;
    background-color: ${(props) => props.theme.colors.Verde2};
    .square {
      right: 2px;
      transition: all 0.4s;
    }
  }
  .square {
    width: 13px;
    height: 13px;
    background-color: ${(props) => props.theme.colors.branco};
    border-radius: 50%;
    position: absolute;
    right: 16px;
    top: 2px;
    transition: all 0.4s;
  }
`;
