import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.branco};
  box-shadow: 0px 10px 60px -10px rgba(3, 3, 3, 0.14);
  border-radius: 8px;
  width: 430px;
  height: 270px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  h4 {
    font-weight: bold;
    font-size: 24px;
    line-height: 38px;
    color: ${(props) => props.theme.colors.Cinza01};
  }
  .icone {
    width: 60px;
  }
  span {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1px;
    color: ${(props) => props.theme.colors.Cinza03};
    display: flex;
    text-transform: uppercase;
    img {
      margin-left: 5px;
      transition: all 0.5s;
    }
  }
  &:hover {
    box-shadow: 0px 30px 60px -10px rgba(3, 3, 3, 0.25);
    span {
      color: #6f3e98;
      img {
        margin-left: 20px;
        transition: all 0.5s;
        filter: invert(30%) sepia(32%) saturate(1200%) hue-rotate(230deg)
          brightness(93%) contrast(95%);
      }
    }
  }
`;
