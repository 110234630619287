import React from "react";
import { Formik, Form, Field } from "formik";
import MuiTextField from "@mui/material/TextField";
import { DateTimePicker } from "formik-mui-lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ptLocale from "date-fns/locale/pt-BR";
import { api } from "../../services/api";
import Button from "../../components/button";
import { AlterarData } from "./styles";
import close from "../../assets/img/admin/close.png";
import Swal from "sweetalert2";
import moment from 'moment-timezone';


export default function AlteraData(props) {

  const initialValues = {
    data_fim: props.data?.data_fim ?? null,
  };

  async function handleSubmit(values) {
    api
      .post(`/acao/edit`, {
        id_acao: props.acao,
        campo_editar: 'data_fim',
        value : moment(values.data_fim).tz("America/Sao_Paulo").format("YYYY-MM-DD HH:mm:ss")
      })
      .then((data) => {
          if (data.status === true){
            Swal.fire({
                icon: 'success',
                title: 'Data alterada com sucesso',
                confirmButtonText: 'Continuar'
             }).then((result) => { window.location.reload(true)})
          }else{
            Swal.fire({
                icon: 'error',
                title: 'Ops...',
                text: 'Erro ao atualizar a data',
              })
          }
       
      })
      .catch((error) => {
        Swal.fire({
            icon: 'error',
            title: 'Ops...',
            text: 'Erro ao atualizar a data',
          })
      });
  }
  return (
    <AlterarData>
      <h3>Alterar data</h3>
      <img
        className="close"
        src={close}
        alt=""
        onClick={() => props.close()}
      />
         <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
          <Formik
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={initialValues}
          >
            <Form>
            <div className="container-form">
                <Field
                    component={DateTimePicker}
                    name="data_fim"
                    label="Data de fim"
                    inputFormat="dd/MM/yyyy HH:mm"
                    renderInput={(params) => (
                    <MuiTextField
                        {...params}
                        label="Data de fim"
                        variant="filled"
                    />
                    )}
                />
                </div>
              <Button title="Alterar" />
            </Form>
          </Formik>
      </LocalizationProvider>
    </AlterarData>
  );
}
