import styled from "styled-components";
import { Container as Select } from "../select/styles";

import busca from "../../assets/img/admin/busca.svg";

export const Detalhes = styled.div`
   .campos-busca {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    input {
      background: #ffffff;
      border: 1px solid #cecece;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .busca {
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 18px;
        background-image: url(${busca});
        z-index: 20;
      }
    }
    .select {
      display: flex;
      grid-gap: 10px;
      ${Select} {
        width: 200px;
        background: #ffffff;
        border: 1px solid #cecece;
        box-sizing: border-box;
        border-radius: 4px;
        height: 56px;
        .react-select__control {
          height: 56px;
        }
      }
    }
  }
`;