import React, { useContext } from "react";
import { AcaoContext } from "../../contexts/AcaoContext";
import { Usuarios } from "./styles";
import img from "../../assets/img/admin/opcoes.svg";

const BoxUsuarios = ({ data }) => {
  const { FormataStringData } = useContext(AcaoContext);

  return (
    <Usuarios>
      <span>{data.name}</span>
      <span>{FormataStringData(data.created_at)}</span>
      {data.tipo === 1 ? (
        <span>Ver todas as ações</span>
      ) : (
        <span>Ver apenas as ações da sua contratante</span>
      )}
      {/* <Switch onChange={handleChange} onColor="#703d98" checked={checked} /> */}
      <img src={img} alt="" />
    </Usuarios>
  );
};

export default BoxUsuarios;
