import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.branco};
  border-radius: 8px;
  padding: 20px;
  transition: all 0.3s;
  border: 1px solid transparent;
  box-sizing: border-box;
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: ${(props) => props.theme.colors.Cinza02};
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #6f3e98;
  }
  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.Cinza01};
  }
  h2 {
    font-weight: bold;
    font-size: 29.7273px;
    line-height: 100%;
    color: ${(props) => props.theme.colors.Cinza01};
  }
  .s-topo {
    display: flex;
    grid-gap: 30px;
    position: relative;
    .imagens-detalhes {
      display: flex;
      grid-gap: 30px;
      flex-flow: wrap;
      width: 330px;
    }
    .imagem {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
    }
    .img-destaque {
      display: flex;
      border-radius: 4px;
      margin-right: 10px;
      margin-bottom: 10px;
      margin-left: 10px;
      background-color: ${(props) => props.color};
      width: 130px;
      height: 90px;
      z-index: 1;
      position: relative;
      .img {
        max-height: 70px;
        max-width: 70px;
        height: auto;
        width: auto;
        align-self: center;
        display: block;
        margin: 0 auto;
      }
      .icone {
        max-height: 60px;
        max-width: 60px;
        align-self: end;
        align-content: end;
        margin-right: 10px;
        z-index: 2;
        position: absolute;
        bottom: -10px;
        left: -10px;
      }
    }
    .s-dir {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .datas {
        display: flex;
        justify-content: space-between;
        grid-gap: 60px;
        .data {
          display: flex;
          img {
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
        span {
          display: inline-block;
          margin-left: 2px;
        }
      }
      .opcoes {
        position: absolute;
        right: 0;
        cursor: pointer;
        transition: 0.3s all;
      }
      .opcoes-bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        cursor: pointer;
        transition: 0.3s all;
      }
    }
  }
  .conteudoVariacao {
    display: ${(props) => props.display};
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: 100%;
    padding: 10px;
    /* justify-items: center; */
    .titulo {
      display: grid;
      grid-template-columns: 2fr 2fr 1fr;
      grid-template-rows: auto;
      span {
        /* margin-left: auto;
        margin-right: auto; */
        font-weight: normal;
        font-size: 12px;
        line-height: 120%;
        color: ${(props) => props.theme.colors.Cinza03};
      }
    }
  }
`;
