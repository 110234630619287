import React from "react";
import Select from "react-select";
import { Container } from "./styles";

const select = ({
  label,
  onChange,
  Search = true,
  ...props
}) => {
  return (
    <Container>
      {console.log(props.options)}
      <Select
        placeholder={label}
        classNamePrefix="react-select"
        onChange={onChange}
        options={props.options}
        isSearchable={Search}
        {...props}
      />
    </Container>
  );
};

export default select;
