import styled from 'styled-components';

export const Container = styled.div`
  height: 3px;
  background-color: ${(props) => props.color !== 'vinho' ? props.theme.colors.Verde4 : props.theme.colors.vinho};
  border-radius: 15px;
  margin-top: 8px;
  position: relative;

  span {
    display: block;
    position: relative;
    height: 100%;
    background-color: ${(props) => props.color !== 'vinho' ? props.theme.colors.Verde2 : props.theme.colors.vinho};
    border-radius: 15px;
    overflow: hidden;
    color: black;
    text-align: right;
  }
  .progresso{
      background-color: ${(props) => props.color !== 'vinho' ? props.theme.colors.Verde4 : props.theme.colors.vinho};
      display: flex;
      position: relative;
      height: 100%;
      border-radius: 15px;
      overflow: hidden;
      color: black;
      text-align: right;
    } 

  p {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 100% !important;
    color: ${(props) => props.theme.colors.Cinza01} !important;
    position: absolute;
    transform: translateX(-50%);
    top: 10px;
  }
`;
