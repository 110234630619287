import React from 'react';

import { Container } from './styles';

import img from '../../assets/img/admin/cvc.png';

const BoxProdutoDetalhe = ({produto}) =>  {

  return (
    <Container>
      {produto ?
          <>
            <div className="img-titulo">
              <img src={produto.imagem_modal ?? img} alt="" />             
            </div>
            <div className="name">           
                <div className="items"><h4>{ produto.nome ?? ''}</h4></div>
                <div className="items"><span>R$ {produto.quantidade ? produto.quantidade : '00,00'}</span></div>
                <div className="items"><span>Qtd. {produto.qtd_disponivel}</span> </div>                                          
            </div>     
          </>
        : <h3>Nenhum produto selecionado </h3>
       }
    </Container>
  );
}

export default BoxProdutoDetalhe;
