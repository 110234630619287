import styled from 'styled-components';

export const Container = styled.div`
  span {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.Cinza04};
    position: relative;
    height: 48px;
    cursor: pointer;
    &.active {
      color: #6f3e98;
      &::before {
        background-color: ${(props) => props.theme.colors.Laranja1};
      }
    }
    &::before {
      content: '';
      background-color: ${(props) => props.theme.colors.Cinza04};
      width: 9px;
      height: 9px;
      display: block;
      border-radius: 50%;
      margin-right: 10px;
    }
    &:not(:last-child) {
      &::after {
        content: '';
        background-color: ${(props) => props.theme.colors.Cinza04};
        display: block;
        height: 24px;
        width: 2px;
        position: absolute;
        top: 34px;
        left: 2px;
      }
    }
  }
`;
