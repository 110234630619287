import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { ACOES } from "../../../routes/paths";
import { api } from "../../../services/api";
import jsonToFormData from "@ajoelp/json-to-formdata";

import Topo from "../../../components/topoCadastro";
import Breadcrumb from "../../../components/breadcrumb";
import Dados from "../../../components/formsNovaAcao/dados";
import SkinDesign from "../../../components/formsNovaAcao/skinDesign";
import Login from "../../../components/formsNovaAcao/login";
import Produtos from "../../../components/formsNovaAcao/produtos";
import Usuarios from "../../../components/formsNovaAcao/usuario";
import Final from "../../../components/formsNovaAcao/final";
import { Alert } from "../../../components/alert/index";

import { Nova } from "./styles";

import logo from "../../../assets/img/admin/logo3.svg";
import { AuthContext } from "../../../contexts/AuthContext";

function NovaAcao() {
  const history = useHistory();

  const [currentForm, setForm] = useState("dados");

  const [data, setData] = useState({});
  const [hash, setHash] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [buttonTitle, setButtonTitle] = useState("Avançar");
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user?.tipo === 2) {
      history.push("/");
    }
  }, [user, history]);

  useEffect(() => {
    api
      .post(`/acao/criarHash`)
      .then((data) => {
        setHash(data.data.hash);
        updateData("hash", data.data.hash);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  const updateData = async (name, values, nextForm = null) => {
    setData({ ...data, [name]: values });

    if (nextForm) {
      setForm(nextForm);
    }
  };

  const submitData = (e) => {
    setDisableButton(true);
    setButtonTitle("Aguarde...");
    e.preventDefault();

    for (let key in data.dados) {
      if (key === "data_inicio" || key === "data_fim") {
        data.dados[key] = moment(data.dados[key]).format("YYYY-MM-DD HH:mm Z");
      }
    }
    const form = jsonToFormData(data);

    api
      .post(`/acao/criar`, form)
      .then(() => {
        Alert({
          title: "sucesso",
          text: "Sucesso ao cadastro",
          icon: "success",
        });
        history.push(ACOES);
      })
      .catch((error) => {
        setButtonTitle("Avançar");
        Alert({
          title: "Erro",
          text: "Erro ao cadastrar tente novamente mais tarde",
          icon: "error",
        });
      });
  };

  function _renderContent() {
    switch (currentForm) {
      case "dados": {
        return (
          <Dados
            values={data.dados}
            onSubmit={(values) => updateData("dados", values, "skin")}
          />
        );
      }
      case "skin": {
        return (
          <SkinDesign
            values={data.skin}
            onSubmit={(values) => updateData("skin", values, "login")}
          />
        );
      }
      case "login": {
        return (
          <Login
            values={data.login}
            onSubmit={(values) => updateData("login", values, "usuario")}
          />
        );
      }
      case "usuario": {
        return (
          <Usuarios
            hash={hash}
            values={data.usuario}
            dados={data.dados.gerar_participantes}
            onSubmit={(values) => updateData("usuario", values, "produtos")}
          />
        );
      }
      case "produtos": {
        return (
          <Produtos
            reserva_estoque={data?.dados?.reserva_estoque ?? true}
            values={data.produtos}
            qtdLimite={data?.dados?.qtd_limite_resgates ?? 0}
            totalUsers={data?.usuario?.resume?.usuarios_totais ?? 0}
            gerarParticipantes={
              data.dados.gerar_participantes &&
              data.dados.qtd_gerar_participantes
            }
            onSubmit={(values) => updateData("produtos", values, "final")}
          />
        );
      }
      case "final": {
        return (
          <Final
            dados={data}
            onSubmit={submitData}
            disableButton={disableButton}
            title={buttonTitle}
          />
        );
      }
      default: {
        break;
      }
    }
  }

  return (
    <Nova>
      <Topo />
      <div className="container">
        <div className="s-esq">
          <img src={logo} alt="" />
          <Breadcrumb
            active={currentForm}
            onclick={(value) => {
              setForm(value);
            }}
          />
        </div>
        <div className="s-dir">
          <h1>Nova Ação</h1>
          {_renderContent()}
        </div>
      </div>
    </Nova>
  );
}

export default NovaAcao;
