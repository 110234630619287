import { createContext } from 'react';

  const FormataStringData = (data) => {
    let date = new Date(data);
    const ano = date.getFullYear();
    const mes = (`00${date.getMonth() + 1}`).slice(-2);
    const dia = (`00${date.getDate()}`).slice(-2);
  
    return `${dia}/${mes}/${ano}`;
  };
  
  const formatarDateObject = (data) => {
    const ano = data.getFullYear();
    const mes = (`00${data.getMonth() + 1}`).slice(-2);
    const dia = (`00${data.getDate()}`).slice(-2);
  
    return `${dia}/${mes}/${ano}`;
  };

export const AcaoContext = createContext(
    { 
      FormataStringData,
      formatarDateObject
    }
  );
