import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-gap: 10px;
  background-color: ${(props) => props.theme.colors.branco};
  /* width: 45%; */
  align-items: center;
  /* justify-items: center; */
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 10px;
  &:last-child {
    border: none;
  }

  .span {
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #6f3e98;
  }

  .btns {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
`;

export const CargaEstoque = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 10px;
  background-color: ${(props) => props.theme.colors.branco};
  border-radius: 8px;
  padding: 20px;
  align-items: center;
  justify-items: center;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.Cinza02};
  }
`;

export const Usuarios = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  background-color: ${(props) => props.theme.colors.branco};
  border-radius: 8px;
  padding: 20px;
  align-items: center;
  justify-items: center;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.Cinza02};
  }
`;

export const UsuariosAcao = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr) 2fr;
  grid-gap: 10px;
  background-color: ${(props) => props.theme.colors.branco};
  border-radius: 8px;
  padding: 20px;
  align-items: center;
  justify-items: center;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.Cinza02};
  }
  .status{
    img {
      margin-right:5px;
    }
    .data{
      font-size: 15px;
      font-weight: 500;
      margin-right:10px;
      color:black;
    }
    display:flex;
    align-items:center;
    justify-content:center;
  }
`;

export const RelatoriosAcao = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 1fr;
  grid-gap: 10px;
  background-color: ${(props) => props.theme.colors.branco};
  border-radius: 8px;
  padding: 20px;
  align-items: center;
  justify-items: center;
  .icone{
    max-height:40px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.Cinza02};
  }
  .status{
    img {
      margin-right:5px;
    }
    .data{
      font-size: 15px;
      font-weight: 500;
      margin-right:10px;
      color:black;
    }
    display:flex;
    align-items:center;
    justify-content:center;
  }
`;
