import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../services/api";
import Button from "../button";
import BoxProdutoView from "../boxProdutoView";
import img1 from "../../assets/img/admin/user-cinza.svg";
import { ContainerFinal, Btns } from "./styles";
import ImgForm from "../imgForm";
import moment from "moment";

function Final(props) {
  const history = useHistory();
  const [contratante, setContratante] = useState({});
  const [previewUrl, setPreviewUrl] = useState(undefined);

  const { dados } = props;

  function getPreviewUrl() {
    const file = dados.skin.logo;
    const reader = new FileReader();

    reader.onload = function (event) {
      setPreviewUrl(event.target.result);
    };

    reader.readAsDataURL(file);
  }

  useEffect(() => {
    async function getItems() {
      try {
        const data = await api.get(`contratante/${dados.dados.contratante_id}`);
        setContratante(data);
        getPreviewUrl();
      } catch (error) {
        alert("Ocorreu um erro ao buscar o contratante");
      }
    }
    getItems();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {dados.dados &&
      dados.skin &&
      dados.login &&
      dados.produtos &&
      dados.usuario ? (
        <ContainerFinal color={dados.skin.cor ?? ""}>
          <div className="secao">
            <div>
              <span>Contratante</span>
              <p>{contratante.nome}</p>
            </div>
            <div>
              <span>Nome da Ação</span>
              <p>{dados.dados.nome}</p>
            </div>
            <div>
              <span>Descrição da Ação</span>
              <p>{dados.dados.descricao}</p>
            </div>
            <div>
              <span>Chave da Ação</span>
              <p>{dados.dados.chave}</p>
            </div>
            <div>
              <span>Url da Ação</span>
              <p>{dados.dados.url}</p>
            </div>
            <div>
              <span>Data e hora de início</span>
              <p>{moment(dados.dados.data_inicio).format("DD/MM/YYYY")}</p>
            </div>
            <div>
              <span>Data e hora de fim</span>
              <p>{moment(dados.dados.data_fim).format("DD/MM/YYYY")}</p>
            </div>
          </div>
          <div className="secao">
            <div>
              <span className="logo-acao">Logo da Ação</span>
              <div className="logo">
                <ImgForm
                  previewBackground={dados.skin.cor}
                  previewUrl={previewUrl}
                />
                {/* <img src={previewUrl} alt="Logo da Ação" /> */}
              </div>
            </div>
            <div className="cor">
              <span>Cor de fundo</span>
              <p>{dados.skin.cor}</p>
            </div>
            <div>
              <span>Título</span>
              <p>{dados.skin.titulo}</p>
            </div>
            <div>
              <span>Subtítulo</span>
              <p>{dados.skin.subtitulo}</p>
            </div>
            <div>
              <span>Descrição</span>
              <p>
                <p>{dados.skin.descricao}</p>
              </p>
            </div>
          </div>
          <div className="secao">
            <div>
              <span>Login</span>
              <p>{dados.login.login}</p>
            </div>
            <div>
              <span>Label Login</span>
              <p>{dados.login.label}</p>
            </div>
            <div>
              <span>Tipo de Senha</span>
              <p>{dados.login.senha}</p>
            </div>
            <div>
              <span>Exigir Video</span>
              <p>{dados.login.exibe_video === true ? "sim" : "Não"}</p>
            </div>
            <div>
              <span>Exigir Cadastro Completo</span>
              <p>{dados.login.cad_completo === true ? "sim" : "Não"}</p>
            </div>
          </div>
          <div className="secao">
            <div>
              <span>Arquivo de usuários</span>
              <p>
                {" "}
                <p>{dados.usuario.usuarios.name}</p>
              </p>
            </div>
            <div>
              <span>Total de usuários</span>
              <p>
                <img src={img1} alt="" /> usuários
              </p>
            </div>
          </div>
          <div className="secao">
            <div>
              <span className="links">Total de Links reservados</span>
              {dados.dados.gerar_participantes
                ? dados.dados.qtd_gerar_participantes
                : dados.usuario.resume.usuarios_totais}
              {/* <p>240 links</p> */}
            </div>
            <div className="produtos">
              {dados.produtos.map((produto) => {
                return (
                  <BoxProdutoView
                    key={produto.variacao_id}
                    produto={produto}
                    reserva_estoque={dados?.dados?.reserva_estoque ?? true}
                  />
                );
              })}
            </div>
          </div>
          <Btns>
            <Button
              title="Cancelar"
              type="button"
              bgc="red"
              disabled={props.disableButton}
              onClick={() => history.goBack()}
            />
            <Button
              title={props.title}
              bgc={props.disableButton === true ? "gray" : ""}
              onClick={props.onSubmit}
              disabled={props.disableButton}
            />
          </Btns>
        </ContainerFinal>
      ) : (
        <h1>Você deve preencher todos os campos</h1>
      )}
    </>
  );
}

export default Final;
