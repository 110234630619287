const theme = {
  title: {
    h1: '52px',
    h2: '32px',
    h3: '28px',
    h4: '18px',
    h5: '16px',
    h6: '14px',
  },

  colors: {
    branco: '#FFF',
    preto: '#000',

    Cinza01: '#333333',
    Cinza02: '#575757',
    Cinza03: '#9D9D9D',
    Cinza04: '#D4D4D4',
    Cinza05: '#F7F7F7',

    Laranja1: '#F7A81D',
    Laranja2: '#F3B445',
    Laranja3: '#ECCC95',
    Laranja4: '#E7DFD1',

    Vinho1: '#D0456E',
    Vinho2: '#D46586',
    Vinho3: '#DCA5B5',
    Vinho4: '#E3D6DA',

    Verde1: '#1CDE90',
    Verde2: '#44E0A1',
    Verde3: '#94E2C3',
    Verde4: '#D1E4DD',

    vermelho: '#FF0000',

    vinho: '#882484'
  },

  fonts: {
    pr: 'Poppins-Regular',
    pb: 'Poppins-Bold',
  },

  mixins: {
    lineCenter: `
				display: flex;
				align-items: center;
			`,
    center: `
				display: flex;
				align-items: center;
				justify-content: center;
			`,
  },
};

export default theme;
