import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

import img from '../../assets/img/admin/exibi.svg';
import img1 from '../../assets/img/admin/exibi1.svg';
import img2 from '../../assets/img/admin/filtro.svg';
import img3 from '../../assets/img/admin/add.svg';
import { AuthContext } from "../../contexts/AuthContext";

function Topo({ activeFiltro, toggleFiltro, activeBoxRow, setBoxRow }) {
  const { user } = useContext(AuthContext);
  return (
    <Container>
      <h4>Minhas ações</h4>
      <div className="imgs">
        <span
          className={`${activeBoxRow === 'box' && 'active'}`}
          onClick={() => setBoxRow('box')}
        >
          <img src={img} alt="" />
        </span>
        <span
          className={`${activeBoxRow === 'row' && 'active'}`}
          onClick={() => setBoxRow('row')}
        >
          <img src={img1} alt="" />
        </span>
        <span
          className={activeFiltro ? 'active' : ''}
          onClick={() => toggleFiltro(!activeFiltro)}
        >
          <img src={img2} alt="" />
        </span>

        {user?.tipo === 1 ?
          <Link to="/acoes/nova" className="add">
            <img src={img3} alt="" />
          </Link>
          : ''}
      </div>
    </Container>
  );
}

export default Topo;
