import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.branco};
  border-radius: 8px;
  padding: 20px;
  transition: all 0.3s;
  border: 1px solid transparent;
  box-sizing: border-box;
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: ${(props) => props.theme.colors.Cinza02};
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #6f3e98;
  }
  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.Cinza01};
  }
  h2 {
    font-weight: bold;
    font-size: 29.7273px;
    line-height: 100%;
    color: ${(props) => props.theme.colors.Cinza01};
  }
  .s-topo {
    display: flex;
    grid-gap: 30px;
    position: relative;
    .img-destaque {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: ${(props) => props.color};
      width: 177px;
      height: 130px;

      img {
        max-height: 100px;
        max-width: 100px;
        height: auto;
        width: auto;
      }
    }
    .estoque{
      height:5px;
      font-weight: 400;
      font-size: 12px;
      line-height: 100%;
      color:${(props) => props.theme.colors.branco};
      border-radius: 4px;
      padding: 5px;
      &.demanda {
        background-color: ${(props) => props.theme.colors.Laranja1};
      }
      &.sobDemanda{
        background-color: ${(props) => props.theme.colors.vinho};
      }
      &.job{
        background-color: black;
        margin-right: 5px;
      }

    }
    .status {
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.branco};
      border-radius: 4px;
      padding: 5px;
      position: absolute;
      bottom: 5px;
      left: 5px;
      &.ativo {
        background-color: ${(props) => props.theme.colors.Verde1};
      }
      &.finalizado {
        background-color: ${(props) => props.theme.colors.Vinho1};
      }
    }
    .s-dir {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .datas {
        display: flex;
        justify-content: space-between;
        grid-gap: 60px;
        .data {
          display: flex;
          img {
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
        span {
          display: inline-block;
          margin-left: 2px;
        }
      }
      .opcoes {
        position: absolute;
        right: 0;
        cursor: pointer;
        transition: 0.3s all;
      }
    }
  }
  .s-footer {
    border: 1px solid ${(props) => props.theme.colors.Cinza04};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 20px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    .s-esq {
      display: flex;
      flex-direction: column;
      h2 {
        display: flex;
        margin-top: 5px;
        align-items: center;
        img {
          margin-right: 5px;
        }
      }
    }
    .s-dir {
      border-left: 1px dashed ${(props) => props.theme.colors.Cinza04};
      padding: 0 10px 0 30px;
      .linkTitulo {
        display: flex;
        justify-content: center;
      }
      .spanLink {
        font-size: 13px;
        font-weight: 800;
        color: #6f3e98;
      }
      .links {
        display: flex;
        justify-content: space-between;
        .usados {
          display: flex;
          align-items: baseline;
          &::before {
            content: '';
            background-color: ${(props) => props.theme.colors.Verde2};
            width: 7px;
            height: 7px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
        .reservados {
          display: flex;
          align-items: baseline;
          &::before {
            content: '';
            background-color: ${(props) => props.theme.colors.Verde4};
            width: 7px;
            height: 7px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
        .estornados {
          display: flex;
          align-items: baseline;
          &::before {
            content: '';
            background-color: ${(props) => props.theme.colors.vermelho};
            width: 7px;
            height: 7px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
        .usados_reserva {
          display: flex;
          align-items: baseline;
          &::before {
            content: '';
            background-color: ${(props) => props.theme.colors.vinho};
            width: 7px;
            height: 7px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
      }
    }
  }
  &:hover {
    border: 1px solid #caa3eb;
    box-sizing: border-box;
    box-shadow: 0px 40px 60px rgba(129, 48, 145, 0.15);
    transition: all 0.3s;
    .s-topo {
      .s-dir {
        .opcoes {
          transform: translate(10px, -10px);
          transition: 0.3s all;
          img {
            filter: invert(61%) sepia(23%) saturate(1596%) hue-rotate(230deg)
              brightness(91%) contrast(95%);
          }
        }
      }
    }
  }
`;

export const Row = styled.div`
  grid-column: span 3;
  background: ${(props) => props.theme.colors.branco};
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 2fr 50px;
  grid-gap: 20px;
  align-items: center;
  justify-items: center;
  padding: 10px 20px;
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: ${(props) => props.theme.colors.Cinza02};
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #6f3e98;
  }
  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.Cinza01};
  }
  h2 {
    font-weight: bold;
    font-size: 29.7273px;
    line-height: 100%;
    color: ${(props) => props.theme.colors.Cinza01};
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
  }
  .img-destaque {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #6f3e98;
    position: relative;
    width: 177px;
    height: 130px;
    border-radius: 4px;
    background-color: ${(props) => props.color};
    img {
        max-height: 100px;
        max-width: 100px;
        height: auto;
        width: auto;
    }
    .status {
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      color: ${(props) => props.theme.colors.branco};
      border-radius: 4px;
      padding: 5px;
      position: absolute;
      bottom: 10px;
      left: 5px;
      &.ativo {
        background-color: ${(props) => props.theme.colors.Verde1};
      }
      &.finalizado {
        background-color: ${(props) => props.theme.colors.Vinho1};
      }
    }
  }
  .datas {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    .data {
      display: flex;
      img {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    span {
      display: inline-block;
      margin-left: 2px;
    }
  }
  .links-progress {
    width: 100%;
    .linkTitulo {
      display: flex;
      justify-content: center;
    }
    .spanLink {
      font-size: 13px;
      font-weight: 800;
      color: #6f3e98;
    }
    .links {
      display: flex;
      justify-content: space-between;
      .usados {
        display: flex;
        align-items: baseline;
        &::before {
          content: '';
          background-color: ${(props) => props.theme.colors.Verde2};
          width: 7px;
          height: 7px;
          display: block;
          border-radius: 50%;
          margin-right: 4px;
        }
      }
      .reservados {
        display: flex;
        align-items: baseline;
        &::before {
          content: '';
          background-color: ${(props) => props.theme.colors.Verde4};
          width: 7px;
          height: 7px;
          display: block;
          border-radius: 50%;
          margin-right: 4px;
        }
      }
      .estornados {
          display: flex;
          align-items: baseline;
          &::before {
            content: '';
            background-color: ${(props) => props.theme.colors.vermelho};
            width: 7px;
            height: 7px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }

        .usados_reserva {
          display: flex;
          align-items: baseline;
          &::before {
            content: '';
            background-color: ${(props) => props.theme.colors.vinho};
            width: 7px;
            height: 7px;
            display: block;
            border-radius: 50%;
            margin-right: 4px;
          }
        }
    }
  }
  &:hover {
    border: 1px solid #caa3eb;
    box-shadow: 0px 40px 60px rgba(129, 48, 145, 0.15);
    transition: all 0.3s;
    .s-topo {
      .s-dir {
        .opcoes {
          transform: translate(10px, -10px);
          transition: 0.3s all;
          img {
            filter: invert(61%) sepia(23%) saturate(1596%) hue-rotate(230deg)
              brightness(91%) contrast(95%);
          }
        }
      }
    }
  }
`;
