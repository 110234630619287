import styled from 'styled-components';

export const Container = styled.div`
    
    .btn {
      width: 207px;
      height: 39px;
      line-height: 40px;     
      text-align: center;      
      cursor: pointer;
      color: #FFF;
      transition: all 0.3s;
      position: relative;
      border-radius: 2px;
    }
    .btn-one {
      color: #FFF;
      transition: all 0.3s;
      position: relative;
    }
    .btn-one span {
        z-index: 2;	
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;	
    }
`;
