import React from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";

import { api } from "../../services/api";

import Button from "../../components/button";

import { AddDetalhes } from "./styles";

import close from "../../assets/img/admin/close.png";

export default function ModalAddDetalhes({ active, handleClose, id_acao, status }) {
  const initialValues = {
    detalhes: "",
  };
  const validationSchema = Yup.object().shape({
    detalhes: Yup.string().required("Campo Obrigatório"),
  });

  async function handleSubmit(values) {
    api
      .post(`/acao/mudaStatus`, {
        id_acao: id_acao,
        msg_status: values.detalhes,
        status: status
      })
      .then((data) => {
        handleClose(!active);
         window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <AddDetalhes>
      <h3>Adicione uma Observação</h3>
      <img
        className="close"
        src={close}
        alt=""
        onClick={() => handleClose(!active)}
      />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="container-form">
            <Field
              type="text"
              name="detalhes"
              label="Observação"
              component={TextField}
              variant="filled"
              color="secondary"
              multiline
              rows={4}
            />
          </div>         
          <Button title="SALVAR" />
        </Form>
      </Formik>
    </AddDetalhes>
  );
}
