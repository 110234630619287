import React, { memo } from 'react';

import { Container } from './styles';

const CheckBox = ({ title, id, checked, onChange }) => {

  return (
    <Container>
      <div className="container-checks">
        <div className="pretty p-svg p-curve">
          <input
            type="checkbox"
            id={id}
            checked={checked}
            onChange={onChange}
          />
          <div className="state p-success">
            <label>{title}</label>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default memo(CheckBox);
