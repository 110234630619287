import React, { useContext, useEffect } from "react";

import Acoes from "../../components/box";
import NovaAcao from "../../components/box/novaAcao";

import { AuthContext } from "../../contexts/AuthContext";

import logo from "../../assets/img/admin/logo2.svg";

import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import { ACOES } from "../../routes/paths";

function Principal() {
  const history = useHistory();
  const { signOut, user } = useContext(AuthContext);

  const userSplit = () => {
    if (user && user.name) {
      return user.name.split(" ")[0];
    }
  };
  useEffect(() => {
    if (user?.tipo === 2) {
      history.push(ACOES);
    }
  }, [user, history])

  return (
    <Container>
      <div className="container">
        <div className="topo">
          <img src={logo} alt="" />
          <div>
            <p>Olá, {userSplit()}</p>
          </div>
        </div>
        <div className="conteudo">
          <h1>Como deseja prosseguir</h1>
          {user?.tipo === 1 ?
            <div className="box">
              <Acoes />
              <NovaAcao />
            </div>
            : ''}
        </div>
        <div className="footer">
          <button type="button" onClick={signOut}>
            Sair
          </button>
          <span>
          YETZ LTDA | CNPJ: 28.325.166/0001-05 | 2022 | Todos os direitos reservados
          </span>
        </div>
      </div>
    </Container>
  );
}

export default Principal;
