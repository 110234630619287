import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

import img from '../../assets/img/admin/acoes.svg';
import seta from '../../assets/img/admin/seta.svg';

function Box() {
  return (
    <Link to="/acoes">
      <Container>
        <img src={img} alt="" className="icone" />
        <h4>Ações Anteriores</h4>
        <span>
          Acessar
          <img src={seta} alt="" />
        </span>
      </Container>
    </Link>
  );
}

export default Box;
