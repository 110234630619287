import styled from "styled-components";

export const Footer = styled.footer`
  display: grid;
  grid-template-columns: 1fr 220px 1.2fr;
  justify-content: center;
  align-items: center;
  grid-gap: 36px;
  height: 82px;
  border-bottom: 3px solid #6f3e98;
  .container-img {
    position: relative;
    display: flex;
    justify-content: center;
    img {
      &:first-child {
        /* margin-right: 36px; */
      }
    }
    &:before {
      content: "";
      display: block;
      width: 3px;
      height: 44px;
      background-color: #6f3e98;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0px;
    }
    &:after {
      content: "";
      display: block;
      width: 3px;
      height: 44px;
      background-color: #6f3e98;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
    }
  }

  .span-cnpj {
    font-size: 14px!important;
    display: flex;
    justify-content: flex-end;
  }
  
  span {
    font-style: normal;
    font-weight: 500;
    width: 670px;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #6f3e98;
    justify-content: space-around;
    &:first-child {
      margin-left: auto;
    }
    &:last-child {
      margin-right: auto;
    }
  }

  @media (max-width: 1366px) {
    span {
      width: 90%;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .container-img {
      display: none;
    }
    span {
      display: none;
      margin: 0px;
      &:first-child {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 250px;
      }
    }
  }
`;
