import React from 'react';

import { Container } from './styles';

function Breadcrumb({ active, onclick }) {
  return (
    <Container>
      <span className={`${active === 'dados' && 'active'}`} onClick={() => { onclick('dados') }}>Dados da Ação</span>
      <span className={`${active === 'skin' && 'active'}`} onClick={() => { onclick('skin') }}>Personalização</span>
      <span className={`${active === 'login' && 'active'}`} onClick={() => { onclick('login') }}>
        Configurações de Login
      </span>
      <span className={`${active === 'usuario' && 'active'}`} onClick={() => { onclick('usuario') }}>
        Carga de Usuários
      </span>
      <span className={`${active === 'produtos' && 'active'}`} onClick={() => { onclick('produtos') }}>
        Resgates e Produtos
      </span>
      <span className={`${active === 'final' && 'active'}`} >Finalização</span >
    </Container >
  );
}

export default Breadcrumb;
