import React from "react";

import Select from "../select";

import { Acoes } from "./styles";

function FiltroAcoes({ activeFiltro, onChange }) {
  return (
    <Acoes className={activeFiltro ? "active" : ""}>
      <div className="busca">
        <input
          type="text"
          placeholder="Busca"
          onChange={(e) =>
            onChange({
              filter: "nome",
              value: e.target.value,
            })
          }
        />
      </div>
      <Select
        label="Status"
        change={(opt) =>
          onChange({
            filter: "status",
            value: opt.label,
          })
        }
      />
    </Acoes>
  );
}

export default FiltroAcoes;
