import React from 'react';
import { PreviewContainer } from './styles';

function ImgForm({
  showPreview = true,
  previewBackground = '',
  previewUrl = '',
}) {
  return (
    <div>
      {!!showPreview && previewUrl && (
        <PreviewContainer background={previewBackground}>
          <img src={previewUrl} alt="" />
        </PreviewContainer>
      )}
    </div>
  );
}

export default ImgForm;
