import React from "react";

import { Container } from "./styles";

export default function ButtonTransparente(props) {
  const { title = "Acessar", type = "submit" } = props;

  return (
    <Container type={type} {...props}>
      <div className="btn btn-one">
        <span>{title}</span>
      </div>
    </Container>
  );
}
