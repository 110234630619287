import React from "react";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

const InputCpfCnpj = (props) => {
  const { inputRef, name, mask, formik, ...other } = props;

  return (
    <CpfCnpj
      type="text"
      getInputRef={inputRef}
      name={name}
      value={formik.values[name]}
      onChange={(event) => formik.setFieldValue(name, event.target.value)}
      {...other}
    />

    // <CpfCnpj
    //                   type="text"
    //                   placeholder="CNPJ"
    //                   name="cnpj"
    //                   onChange={(event, type) => {
    //                     setFieldValue("cnpj", event.target.value);
    //                   }}
    //                   value={cnpj}
    //                 />
  );
};

export default InputCpfCnpj;
