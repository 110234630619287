import React from "react";

import { Container } from "./styles";
import ellipse from "../../assets/img/admin/ellipse.svg";

export default function LabelDetalhes(props) {
  const { title = "Acessar", subtitle = "", color } = props;

  return (
    <Container color={color} >
       {/* eslint-disable-next-line */}
      <img src={ellipse}/>
      <span className="title">{title}</span>
      <span className="subtitle"> {subtitle}</span>
      {color ? <div className="color"></div> : ""}
    </Container>
  );
}
