import React from 'react';
import { Container } from './styles';

const BoxEstoque = ({data}) => {
  return (
    <>
      {data.map(( variacao, index) => (
      <Container key={index}>
        {/* <img src={img} alt="" /> */}
        <span className="span">{variacao.id}</span>
        <span className="span" >R$ : {variacao.valor}</span>
        <span className="span">{variacao.qtd_estoque}</span>
      </Container>
      ))}
    </>
  );
}

export default BoxEstoque;
