import React, { useState } from "react";

import { Alert } from "../alert/index";
import Swal from "sweetalert2";

import { api } from "../../services/api";

import opcoes from "../../assets/img/admin/opcoes.svg";
import excluir from "../../assets/img/admin/excluir.svg";
// import link from "../../assets/img/admin/link.svg";

// import { Link } from "./styles";

function Opcoes(props) {
  const { id } = props;
  const [dropDown, setdropDown] = useState(false);

  async function excluirContratante(id) {
    Swal.fire({
      title: "Atenção?",
      text: "Esta ação não poderá ser revertida, tem certeza disso?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Continuar!",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/contratante/${id}`)
          .then((data) => {
            Alert({
              title: "sucesso",
              text: "Sucesso ao deletar",
              icon: "success",
            });
            window.location.reload();
          })
          .catch((error) => {
            Alert({
              title: "Erro",
              text: error,
              icon: "error",
            });
          });
      }
    });
  }

  return (
    <>
      <img
        src={opcoes}
        alt=""
        className="opcoes"
        onClick={() => setdropDown(!dropDown)}
      />
      <div className={dropDown ? "dropdown-opcoes active" : "dropdown-opcoes"}>
        {/* <Link
          to={{
            pathname: `/contratantes/${id}`,
            id: id,
          }}
        >
          <img src={link} alt="" />
          Editar
        </Link> */}
        <span onClick={() => excluirContratante(id)}>
          <img src={excluir} alt="" />
          Excluir
        </span>
      </div>
    </>
  );
}

export default Opcoes;
