import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(90deg, #8a288b 0%, #762678 100%);
  height: 80px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 38px;
    color: ${(props) => props.theme.colors.branco};
  }
  .links {
    display: flex;
    grid-gap: 20px;
    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 38px;
      color: rgba(255, 255, 255, 0.6);
      text-align: center;
      position: relative;
      min-width: 131px;
      cursor: pointer;
      &.active {
        color: ${(props) => props.theme.colors.branco};
        &:before {
          content: '';
          display: block;
          background-color: ${(props) => props.theme.colors.Laranja1};
          width: 100%;
          height: 4px;
          position: absolute;
          bottom: -21px;
        }
      }
    }
  }
  .imgs {
    display: flex;
    grid-gap: 10px;
    a,
    span {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 4px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.active {
        background-color: #6f3e98;
      }
      &.add {
        img {
          width: 14px;
          height: 14px;
        }
        background-color: ${(props) => props.theme.colors.Verde1} !important;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
