import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import { AuthContext } from "../../contexts/AuthContext";

import { Container } from "./styles";

import logo from "../../assets/img/admin/logo3.svg";
import img from "../../assets/img/admin/acoes-roxo.svg";
import img2 from "../../assets/img/admin/usuarios-roxo.svg";
import produtos from "../../assets/img/admin/Group.png";
import contratantes from "../../assets/img/admin/solicitantes.svg";


function MenuLateral() {
  const { signOut, user } = useContext(AuthContext);
  const { pathname } = useLocation();

  const userSplit = () => {
    if (user && user.name) {
      return user.name.split(" ")[0];
    }
  };

  return (
    <Container>
      <img src={logo} alt="" />
      <div className="menu-principal">
        <a
          href="/acoes"
          className={`${pathname !== "/acoes" && "icone-cinza"}`}
        >
          <img src={img} alt="" />
        </a>
        {user?.tipo === 1 ?
          <>
            <a
              href="/usuarios"
              className={`${pathname !== "/usuarios" && "icone-cinza"}`}
            >
              <img src={img2} alt="" />
            </a>
            <a
              href="/produtos"
              className={`${pathname !== "/produtos" && "icone-cinza"}`}
            >
              <img src={produtos} alt="" />
            </a>
            <a
              href="/contratantes"
              className={`${pathname !== "/contratantes" && "icone-cinza"}`}
            >
              <img src={contratantes} alt="" />
            </a>
          </> : ''}
      </div>
      <div className="usuario">
        <span>Olá {userSplit()}</span>
        <button type="button" onClick={signOut}>
          Sair
        </button>
      </div>
    </Container>
  );
}

export default MenuLateral;
