import React from "react";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";

import { PreviewContainer } from "./styles";

function InputColor({ name, placeholder }) {
  const { values } = useFormikContext();

  return (
    <div className="container-form">
      <PreviewContainer background={values[name]} />
      <Field
        type="text"
        label={placeholder}
        name={name}
        component={TextField}
        color="secondary"
        variant="filled"
      />
      {/* <ErrorMessage component="span" name={name} /> */}
    </div>
  );
}

export default InputColor;
