import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";

import { api } from "../../services/api";

import Button from "../../components/button";

import { AddUsuario } from "./styles";

import close from "../../assets/img/admin/close.png";

export default function ModalAddUsuario({ active, handleClose, id_acao }) {
  const [hash, setHash] = useState();
  const initialValues = {
    funcional: "",
    racf: "",
    nome: "",
    cargo: "",
    celula: "",
    polo: "",
  };
  const validationSchema = Yup.object().shape({
    funcional: Yup.string().required("Campo Obrigatório"),
    racf: Yup.string().required("Campo Obrigatório"),
    nome: Yup.string().required("Campo Obrigatório"),
    cargo: Yup.string().required("Campo Obrigatório"),
    celula: Yup.string().required("Campo Obrigatório"),
    polo: Yup.string().required("Campo Obrigatório"),
  });
  useEffect(() => {
    api
      .post(`/acao/criarHash`)
      .then((data) => {
        setHash(data.data.hash);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);
  async function handleSubmit(values) {
    api
      .post(`/acao/cadastraNovoUsuario`, {
        id_acao: id_acao,
        hash_acao: hash,
        funcional: values.funcional,
        racf: values.racf,
        nome: values.nome,
        cargo: values.cargo,
        celula: values.celula,
        polo: values.polo,
      })
      .then((data) => {
        handleClose(!active);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <AddUsuario>
      <h3>Novo Usuário</h3>
      <img
        className="close"
        src={close}
        alt=""
        onClick={() => handleClose(!active)}
      />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="container-form">
            <Field
              type="text"
              name="funcional"
              label="Funcional"
              component={TextField}
              variant="filled"
              color="secondary"
            />
          </div>
          <div className="container-form">
            <Field
              type="text"
              name="racf"
              label="RACF"
              component={TextField}
              variant="filled"
              color="secondary"
            />
          </div>
          <div className="container-form">
            <Field
              type="text"
              name="nome"
              label="Nome"
              component={TextField}
              variant="filled"
              color="secondary"
            />
          </div>
          <div className="container-form">
            <Field
              type="text"
              name="cargo"
              label="Cargo"
              component={TextField}
              variant="filled"
              color="secondary"
            />
          </div>
          <div className="container-form">
            <Field
              type="text"
              name="celula"
              label="Célula"
              component={TextField}
              variant="filled"
              color="secondary"
            />
          </div>
          <div className="container-form">
            <Field
              type="text"
              name="polo"
              label="Polo"
              component={TextField}
              variant="filled"
              color="secondary"
            />
          </div>

          <Button title="CADASTRAR" />
        </Form>
      </Formik>
    </AddUsuario>
  );
}
