import React, { useState, useEffect } from "react";

import Button from "../button";
import Box from "../boxProduto";
import Modal from "../modal";
import ModalAddProduto from "../modal/addProduto";
import { api } from "../../services/api";
import { useHistory } from "react-router-dom";
import { ContainerProdutos, Btns } from "./styles";
import Swal from "sweetalert2";

function Produtos({
  onChange,
  onSubmit,
  values = null,
  totalUsers = 0,
  qtdLimite = 0,
  reserva_estoque,
  gerarParticipantes = 0,
}) {
  const history = useHistory();
  const [modalAddProdutoIsOpen, setModalAddProdutoIsOpen] = useState(false);
  const [produtoSelecionado, setProdutoSelecionado] = useState([]);
  const [produtoResult, setProdutoResult] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [disabled, setDisabled] = useState("");

  const alertSwal = (totalUser, sumVoucher) => {
    Swal.fire({
      title: "Atenção?",
      text:
        "A quantidade de produtos  é menor que a de usuarios, total de usuarios : " +
        totalUser +
        " total de voucher : " +
        sumVoucher +
        " deseja continuar ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, Continuar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        onSubmit(produtoResult);
      }
    });
  };

  const HandleProdutoResult = (e, value) => {
    let newProduct = {
      img: value?.produto?.foto_capa,
      produto_id: value?.produto_id,
      variacao_id: value?.id,
      nome: value?.nome,
      valor_reais: value?.valor_reais,
      total_disponivel: value?.total_disponivel,
      quantidade: parseInt(e.target.value),
    };

    let index = produtoResult.findIndex((val) => val.variacao_id === value.id);

    if (index < 0) {
      setProdutoResult((produtoResult) => [...produtoResult, newProduct]);
    } else {
      produtoResult[index].quantidade = parseInt(e.target.value);
    }
  };

  function handleNewVariation() {
    const produto = produtoSelecionado.map((produto) => {
      return {
        img: produto?.produto?.foto_capa,
        produto_id: produto?.produto_id,
        variacao_id: produto?.id,
        nome: produto?.nome,
        valor_reais: produto?.valor_reais,
        total_disponivel: produto?.total_disponivel,
        quantidade: 1,
      };
    });
    return produto;
  }

  useEffect(() => {
    api
      .post(`/acao/produtos`)
      .then((data) => {
        setProdutos(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (produtoSelecionado.length > 0) {
      setDisabled("");
    } else {
      setDisabled("disabled");
    }
  }, [produtoSelecionado]);

  useEffect(() => {
    if (values) {
      setProdutoSelecionado(values);
    }
  }, [values]);

  const verificaVourcherUser = () => {
    let totalUser = 0;
    if (gerarParticipantes > 0) {
      totalUser = qtdLimite * gerarParticipantes;
    } else {
      totalUser = qtdLimite * totalUsers;
    }
    let sumVoucher = produtoResult.reduce(function (prevVal, elem) {
      return prevVal + elem.quantidade;
    }, 0);
    if (totalUser >= sumVoucher && reserva_estoque === true) {
      alertSwal(totalUser, sumVoucher);
    } else {
      if (reserva_estoque === true) {
        onSubmit(produtoResult);
      } else {
        onSubmit(handleNewVariation());
      }
    }
  };

  return (
    <ContainerProdutos>
      <Button
        className="add"
        title="+ Adicionar Produto"
        type="button"
        color="#703D98"
        bgc="#fff"
        onClick={() => setModalAddProdutoIsOpen(!modalAddProdutoIsOpen)}
      />
      <div className="produtos">
        {produtoSelecionado.length > 0 ? (
          produtoSelecionado.map((produto) => {
            return (
              <Box
                key={produto.id}
                reserva_estoque={reserva_estoque}
                onKeyUp={(e, values) => HandleProdutoResult(e, values)}
                produto={produto}
              />
            );
          })
        ) : (
          <Box />
        )}
      </div>
      <Btns>
        <Button
          title="Cancelar"
          type="button"
          bgc="red"
          onClick={() => history.goBack()}
        />
        <Button
          disabled={disabled}
          onClick={() => {
            verificaVourcherUser();
          }}
          title="Avançar"
        />
      </Btns>

      <Modal
        onChange={onChange}
        data={produtos}
        component={ModalAddProduto}
        active={modalAddProdutoIsOpen}
        setProdutos={(produto) => {
          setProdutoSelecionado(produto);
        }}
        close={() => setModalAddProdutoIsOpen(false)}
      />
    </ContainerProdutos>
  );
}

export default Produtos;
