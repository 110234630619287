import styled from 'styled-components';

export const PreviewContainer = styled.div`
  width: 383px;
  height: 131px;

  border-radius: 5px;

  padding: 8px;
  margin-bottom: 14px;

  background-color: ${({ theme, background }) =>
    background || theme.colors.Cinza05};

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 90%;
    max-width: 90%;
  }
`;
