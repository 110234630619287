import React from 'react';
import { useField } from 'formik';
import { Container } from './styles';

const BtnSwitch = ({name, statusSenha}) => {
  // eslint-disable-next-line
  const [field, _, helpers] = useField({ name, type: 'checkbox' });

  return (
    <Container
      className={field.value ? 'btn-switch active' : 'btn-switch'}
      onClick={() => {helpers.setValue(!field.value)}}
    >
      <input {...field} hidden />
      <div className="square"></div>
    </Container>
  );
}

export default BtnSwitch;
