import styled from "styled-components";

export const PaginacaoStyles = styled.div`
    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 16px;
        position: relative;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        .pre {
            margin-right: 10px;
        }

        .next {
            margin-right: 10px;
        }

        .container-input {
            margin: 0px 15px;
            input {
                height: 35px;
                padding: 0px 15px;
                border: 1px solid ${({ theme }) => theme.colors.Cinza04};
                border-radius: 5px;
            }
        }

        button {
            width: 40px;
            height: 40px;
            background-color: #7f2780;
            color: ${(props) => props.theme.colors.branco};
            font-size: 20px;
            border: none;
            border-radius: 4px;
            font-size: 14px;
            line-height: 100%;
            margin-left: 16px;
            cursor: pointer;
            border: 0.900002px solid;
            transition: all 0.5s;
            &[disabled] {
                background-color: ${({ theme }) => theme.colors.Cinza04};
                cursor: not-allowed;
            }
        }
    }
`;
