import React from 'react';
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  usePagination,
} from 'react-table';

// import Paginacao from './paginacao';

import { Container } from './styles';

import img from '../../assets/img/admin/status.svg';



function Table(props) {

  // function handlePagination(id) {
  // dispatch(AcoesCreators.paginacao({ id }));
  // }

  function renderItens(cell) {
    switch (cell.column.Header) {
      case 'Status':
          return (
            <button className={`status`}>
              <img src={img} alt="" />          
            </button>
          );
       case 'Primeiro Acesso':
        return <span>{cell.value ? cell.render('Cell') : ' - '}</span>;  
      default:
        return <span>{cell.value ? cell.render('Cell') : ' - '}</span>;
    }
  }
  
  const { modal } = props;

  const headerProps = (props, { column }) => getStyles(props, 'left');

  const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

  const getStyles = (props, align = 'left') => [
    props,
    {
      style: {
        justifyContent:
          align === 'right'
            ? 'flex-end'
            : align === 'left'
            ? 'flex-start'
            : 'center',
        alignItems: 'flex-start',
        display: 'flex',
      },
    },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      initialState: {
        pageIndex: 0,
        pageSize: props.pageSize ? props.pageSize : 20,
      },
    },
    useFlexLayout,
    useResizeColumns,
    usePagination
  );

  return (
    <Container className={modal ? 'tabela-modal' : ''}>
      <div {...getTableProps()} className="table">
        <div>
          {headerGroups.map((headerGroup, index) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className="tr header"
              key={index}
            >
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  {column.render('Header')}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? 'isResizing' : ''
                    }`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr body" key={i}>
                {row.cells.map((cell, j) => {
                  // const {
                  //   row: {
                  //     original: { id, status },
                  //   },
                  // } = cell;
                  return (
                    <div
                      {...cell.getCellProps(cellProps)}
                      className={`td ${cell.value ? '' : 'center'}`}
                      key={`${i}:${j}`}
                    >
                      {renderItens(cell)}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        {/* <Paginacao handlePagination={handlePagination} paginacao={paginacao} /> */}
      </div>
    </Container>
  );
}

export default Table;
