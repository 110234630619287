export const LOGIN = "/login";

export const PRINCIPAL = "/";

export const USUARIOS = "/usuarios";
export const NOVO_USUARIO = "/usuarios/novo";

export const ACOES = "/acoes";
export const NOVA_ACAO = "/acoes/nova";
export const DETALHES_ACAO = "/acoes/detalhes/:id/:acao";
export const PRODUTOS = "/produtos";

export const CONTRATANTES = "/contratantes";
export const NOVO_CONTRATANTE = "/contratantes/novo";
