import styled from "styled-components";

import border from "../../assets/img/admin/border.svg";
import { Container as Button } from "../../components/button/styles";

export const Login = styled.div`
  background: linear-gradient(180deg, #6f3e98 53.23%, #8b288c 92.92%);
  .container {
    min-height: calc(100vh - 82px);
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .container-img-fundo {
      position: absolute;
      top: 0px;
      right: 0px;
      height: 100%;
      img {
        height: 100%;
      }
    }

    .container-esq {
      overflow: hidden;
      img {
        width: 90%;
      }
    }

    .container-dir {
      width: 396px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      z-index: 20;
      img {
        &.logo {
          margin-bottom: 50px;
          width: 396px;
        }
      }

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        display: block;
        margin-bottom: 7px;
      }

      h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 41px;
        line-height: 50px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 63px;
        text-align: center;
      }

      form {
        margin-bottom: 123px;
        width: 100%;

        small {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
          display: block;
          margin-bottom: 14px;
          &.esqueci-senha {
            position: relative;
            bottom: -30px;
            cursor: pointer;
          }
        }

        .container-form {
          position: relative;
          width: 100%;
          &:last-child {
            margin-bottom: 28px;
          }

          img {
            position: absolute;
            top: 50%;
            left: 16px;
            transform: translateY(-50%);
          }

          .check-password {
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
            height: auto;
            width: auto;
            &.active {
              &:before {
                height: 30px;
                transition: all 0.5s;
              }
            }
            &:before {
              content: "";
              display: block;
              width: 2px;
              height: 0px;
              background-color: #858585;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
              transition: all 0.5s;
            }
            img {
              position: static;
              transform: unset;
              margin-bottom: 0px;
            }
          }
          input {
            padding-left: 0px;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 100px;
            width: 100%;
            height: 60px;
            background: #ffffff;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            display: flex;
            align-items: center;
            text-align: center;

            color: rgba(0, 0, 0, 0.5);
          }
        }
      }

      ${Button} {
        width: 100%;
        height: 68px;
        background: transparent;
        border-radius: 4px;
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        letter-spacing: 0.05em;
        color: #6f3e98;
        border-radius: 100px;
        position: relative;
        z-index: 30;
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 101%;
          height: 100%;
          -webkit-mask: url(${border});
          -webkit-mask-repeat: no-repeat;
          -webkit-mask-size: cover;
          background-color: #fff;
          z-index: -1;
        }
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: #898989;
        margin-bottom: 48px;
      }
      .container-imgs {
        width: 247px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    @media (max-width: 1024px) {
      flex-direction: column;
      .container-dir {
        margin-top: 25px;
        margin-left: 0px;
      }
    }

    @media (max-width: 480px) {
      min-height: auto;
      .container-esq {
        img {
          width: 282px;
        }
      }

      .container-dir {
        width: 223px;
        span {
          margin-bottom: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          text-align: center;

          color: #ffffff;
        }

        h2 {
          margin-bottom: 28px;
        }

        form {
          margin-bottom: 39px;
          .container-form {
            .check-password {
              &:before {
                top: 60%;
              }
              &.active {
                &:before {
                  height: 18px;
                  width: 1px;
                  top: 60%;
                }
              }
              img {
                width: 20px;
              }
            }
            input {
              width: 221.73px;
              height: 37.88px;
              &::placeholder {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                display: flex;
                align-items: center;
                text-align: center;

                color: rgba(0, 0, 0, 0.5);
              }
            }
          }
        }

        ${Button} {
          height: 38.5px;
          -webkit-mask-size: contain;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 27px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.05em;
          color: #005d88;
          &:before {
            width: 100%;
          }
        }

        h2 {
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          color: #ffffff;
        }
        img {
          &.logo {
            width: 100%;
            margin-bottom: 17px;
          }
        }
      }
    }
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 50px;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.branco};
    margin: 50px 0;
    width: 383px;
  }
  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
    color: ${(props) => props.theme.colors.branco};
    width: 383px;
  }
  .yetz {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 383px;
    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 150%;
      color: ${(props) => props.theme.colors.branco};
    }
  }
  .img1 {
    position: absolute;
    right: 70px;
    top: 180px;
  }
  .img2 {
    position: absolute;
    right: -30px;
    top: 450px;
  }
  .img3 {
    position: absolute;
    right: 60px;
    top: 600px;
  }

  @media (max-width: 1366px) {
    .img1 {
      right: 30px;
    }
  }

  @media (max-width: 480px) {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    img {
      height: 70px;
    }
    .img1,
    .img2,
    .img3 {
      display: none;
    }
    h1,
    p {
      width: auto;
      margin: 20px 0;
    }
    h1 {
      font-size: 28px;
    }
    p {
      font-size: 15px;
    }
    .yetz {
      display: none;
    }
  }
`;
