import React from "react";
import InputMask from "react-input-mask";

const FormikTextField = (props) => {
  const { inputRef, name, mask, formik, ...other } = props;

  return (
    <InputMask
      getInputRef={inputRef}
      name={name}
      mask={mask}
      value={formik.values[name]}
      onChange={(event) => formik.setFieldValue(name, event.target.value)}
      {...other}
    />
  );
};

export default FormikTextField;
