import React, { useEffect, useMemo, useState } from "react";
import TabelaAcoes from "../../components/tabelas/acao";
import { api } from "../../services/api";


function Acessos(props) {
  const [dados, setDados] = useState([]);
  const [busca, setBusca] = useState("");

  useEffect(() => {
    api
      .post(`/acao/getAcaoAcessos`, { id_acao: props.id })
      .then((data) => {
        setDados(data.acessos);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.id]);

  const dadosFiltrados = useMemo(() => {
    return dados.filter(
      (item) =>
        item.celula.toLowerCase().includes(busca.toLowerCase()) ||
        item.total_usuarios.toString().toLowerCase().includes(busca.toLowerCase())
    );
  }, [busca, dados]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Áreas",
        accessor: "celula",
      },
      {
        Header: "Total de Usuários",
        accessor: "total_usuarios",
      },
      {
        Header: "Resgates",
        accessor: "resgates",
      },
      {
        Header: "%",
        accessor: "porcentagem",
      }
    ],
    []
  );
  return (
    <>
      <div className="campos-busca">
          <div className="busca">
          <input
              type="text"
              placeholder="Busca"
              onChange={(e) => setBusca(e.target.value)}
          />
          </div>           
      </div>
      <TabelaAcoes columns={columns} data={dadosFiltrados} />
    </>
  );
}

export default Acessos;