import React from 'react';

import { Container } from './styles';

import img from '../../assets/img/admin/cvc.png';

const BoxProdutoView = ({produto, reserva_estoque}) =>  {

  return (
    <Container>
      {produto ?
          <>
            <div className="img-titulo">
              <img src={produto.img ?? img} alt="" />              
            </div>
            <div className="name">
                <h4>{ produto.nome ?? ''}</h4>
                <span>{'R$ ' + produto.valor_reais?? 0.00}</span>
            </div>
            {reserva_estoque === true ?
            <>
            <span>{'Estoque : ' + produto.total_disponivel?? 0}</span>
            <div className="qtd">
              <span>Qtd.</span>
              <span>{produto.quantidade ?? 0}</span>             
            </div>
            </>
            :''}
          </>
        : <h3>Nenhum produto selecionado </h3>
       }
    </Container>
  );
}

export default BoxProdutoView;
