import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

import img2 from '../../assets/img/admin/filtro.svg';
import img3 from '../../assets/img/admin/add.svg';

function TopoUsuarios({ activeFiltro, toggleFiltro}) {
  return (
    <Container>
      <h4>Usuários</h4>

      <div className="imgs">
        <span
            className={activeFiltro ? 'active' : ''}
            onClick={() => toggleFiltro(!activeFiltro)}
          >
            <img src={img2} alt="" />
        </span>
        <Link to="/usuarios/novo" className="add">
          <img src={img3} alt="" />
        </Link>
      </div>
    </Container>
  );
}

export default TopoUsuarios;
