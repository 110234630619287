import React, { useState } from "react";

import Button from "../button";
import { AddProduto, Products, Btns } from "./styles";
import Box from "../boxProduto";
import closed from "../../assets/img/admin/close.png";
import Swal from "sweetalert2";
import { api } from "../../services/api";

const ModalAddVoucher = ({ data, reserva_estoque, close, acao }) => {
  const [produtoResult, setProdutoResult] = useState([]);

  const HandleProdutoResult = (e, value) => {

    let newProduct = {
      img: value.produto.foto_capa,
      produto_id: value.produto_id,
      variacao_id: value.id,
      nome: value.nome,
      valor_reais: value.valor_reais,
      total_disponivel: value.total_disponivel,
      quantidade: parseInt(e.target.value),
    };

    let index = produtoResult.findIndex((val) => val.variacao_id === value.id);

    if (index < 0) {
      setProdutoResult((produtoResult) => [...produtoResult, newProduct]);
    } else {
      produtoResult[index].quantidade = parseInt(e.target.value);
    }
  };

  function handleNewVariation() {

    const produto = data.map((produto) => {
      return {
        img: produto?.produto?.foto_capa,
        produto_id: produto?.produto_id,
        variacao_id: produto?.id,
        nome: produto?.nome,
        valor_reais: produto?.valor_reais,
        total_disponivel: produto?.total_disponivel,
        quantidade: 1,
      };
    })
    return produto
  }

  const verificaVourcherEstoque = () => {

    let produtoData;

    if (reserva_estoque === 1) {
      produtoData = produtoResult;
      const totalVoucher = produtoResult.filter(function (obj) {
        return obj.quantidade === 0;
      })
      if (totalVoucher.length > 0 || produtoResult.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          text: 'A quantidade não pode ser vazia ou 0',
        })
      } else {
        adicionaVoucher(acao, produtoData)
      }
    } else {
      produtoData = handleNewVariation();
      adicionaVoucher(acao, produtoData)
    }
  };

  function adicionaVoucher(acao, produtoData) {
    api
      .post(`/acao/adicionaVoucher`, { 'id_acao': acao, produtoData })
      .then((data) => {
        if (data.retorno === true) {
          Swal.fire({
            icon: 'success',
            title: 'Voucher adicionado com sucesso',
            confirmButtonText: 'Continuar',
          }).then((result) => {
            if (result.isConfirmed) {
              close()
              window.location.reload(true);
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: data.mensagem,
          })
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.mensagem,
        })
      });
  }

  return (
    <AddProduto>
      <h3>Produtos selecionados</h3>
      <img className="close" src={closed} alt="" onClick={() => close()} />
      <Products>
        <div className="produtos">
          {data.length > 0 ? (
            data.map((produto) => {
              return (
                <Box
                  reserva_estoque={reserva_estoque}
                  key={produto.id}
                  onKeyUp={(e, values) => HandleProdutoResult(e, values)}
                  produto={produto}
                />
              );
            })
          ) : (
            <Box />
          )}
        </div>
        <Btns>
          <Button
            className="cancell"
            title="Cancelar"
            type="button"
            bgc="red"
            onClick={() => close()}
          />
          <Button
            onClick={() => {
              verificaVourcherEstoque();
            }}
            title="Avançar"
          />
        </Btns>
      </Products>
    </AddProduto>
  );
};

export default ModalAddVoucher;