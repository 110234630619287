import { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { LOGIN, PRINCIPAL } from "../routes/paths";

import { api } from "../services/api";

import { getToken, setToken, removeToken } from "../utils/token";

export const AuthContext = createContext([]);

export const AuthProvider = ({ children }) => {
  const history = useHistory();

  const [isAuthenticated, setIsAuthenticated] = useState(!!getToken());
  const [errorLogin, setErrorLogin] = useState(false);
  const [user, setUser] = useState({});
  const [loginInProgress, setLoginInProgress] = useState(false);

  useEffect(() => {
    const token = getToken();
    if (token) {
      api
        .post("/me")
        .then((data) => {
          setUser(data);
        })
        .catch((error) => {
          setIsAuthenticated(false);
          setUser(undefined);
          removeToken();
          history.push(LOGIN);
        });
    }
    // eslint-disable-next-line
  }, [getToken(), history]);

  async function me() {
    try {
      const data = await api.post("/me");
      setUser(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function enviaSms(email) {
    try {
      const response = await api.post("/generate_code_auth", { email });
      setErrorLogin(false);
      return response;
    } catch (error) {
      setErrorLogin(true);
    } finally {
      setLoginInProgress(false);
    }
  }

  async function verificaCodigo(code, email) {
    try {
      const { success } = await api.post("/confirm-code", { code, email });
      return success;
    } catch (error) {
      setErrorLogin(true);
    } finally {
      setLoginInProgress(false);
    }
  }

  async function signIn({ email, password }) {
    try {
      setLoginInProgress(true);

      const { access_token } = await api.post("/login", {
        email,
        password,
      });

      me();

      setIsAuthenticated(true);
      setToken(access_token);
      setErrorLogin(false);

      history.push(PRINCIPAL);
    } catch (error) {
      setErrorLogin(true);
    } finally {
      setLoginInProgress(false);
    }
  }

  function signOut() {
    removeToken();
    setIsAuthenticated(false);
    setUser(undefined);
    history.push(LOGIN);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loginInProgress,
        errorLogin,
        signIn,
        signOut,
        enviaSms,
        verificaCodigo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
