import React from 'react';

import { AuthProvider } from './contexts/AuthContext';

// Router
import { BrowserRouter } from 'react-router-dom';
import SwitchRoutes from './components/switchRoutes';

import routes from './routes';

// Styled
import { ThemeProvider } from 'styled-components';
import GlobalStyled from './assets/styles/global';
import Themes from './assets/styles/themes';

const App = () => (
  <ThemeProvider theme={Themes.default}>
    <GlobalStyled />
    <BrowserRouter>
      <AuthProvider>
        <SwitchRoutes routes={routes} />
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>
);

export default App;
