import Countdown from "react-countdown";

import Timer from "../../assets/sistema/timer.svg";

export function CountDown(props) {
    const { setDisabled, setTimer } = props;
    return (
        <Countdown
            date={Date.now() + 20000}
            onComplete={() => {
                setDisabled(false);
                setTimer(false);
            }}
            renderer={(props) => {
                const { hours, minutes, seconds } = props;

                return (
                    <div className="timer">
                        <img src={Timer} alt="" />
                        <p ml="5px">{hours <= 9 ? "0" + hours : hours}</p>:
                        <p>{minutes <= 9 ? "0" + minutes : minutes}</p>:
                        <p>{seconds <= 9 ? "0" + seconds : seconds}</p>
                    </div>
                );
            }}
        />
    );
}
