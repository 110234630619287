import styled from "styled-components";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.Cinza05};
  margin-left: 160px;
  min-height: 100vh;
  overflow: auto; 
  white-space: nowrap; 
  
  
  .conteudo {    
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
    grid-gap: 30px;
    padding: 30px;
  }
`;

export const ImagemIcone = styled.table`

  width: 120px;
  height: 100%;
  background-image: url(${(props) => props.img});
  background-size: auto;
  background-repeat: no-repeat;
  Background-position: center center;
  display: flex;  
  background-color: coral;
  justify-content: start;
  img{
    cursor: pointer;
    margin-top:50px;    
  }
`;

export const CustomTable = styled.table`
 
.container{
    background: white;  
    width:100%; 
    display: flex;
    align-items: center;
    justify-content: space-around; 
    margin: 10px 50px 20px 0;
    padding: 30px 0px 30px 0;

  td {  
    background: #fff;
    border: none;

    span {
      color: #9d9d9d;
      font-size: 0.75rem;
    }
    h2 {
      font-size: 1rem;
      font-weight: 600;
      color: #333333;
    }
    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .title__theme-table {
    color: #6f3e98;
  }
  .imagem-icone {
    width: 120px;
    height: 100%;
    background-image: url(${(props) => props.img});
    background-size: auto;
    background-repeat: no-repeat;
    Background-position: center center;
    display: flex;  
    background-color: white;
    border: 1px solid grey;
    justify-content: start;
      img{
        cursor: pointer;
        margin-top:50px;    
      }
    }
  }
`;

export const ContainerSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 60px;
  max-width: 100%;
  p {
    font-size: 1rem;
    font-weight: 600;
  }
`;
