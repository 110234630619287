import styled from "styled-components";
import { Container as Select } from "../../components/select/styles";
import { Container as Progress } from "../../components/progress/styles";

import busca from "../../assets/img/admin/busca.svg";


export const Tabela = styled.div`
  min-height: 100vh;
  .conteudo {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    padding-top: 10px;
    .titulo_relatorio {
      display: grid;
      grid-template-columns: repeat(3, 1fr) 1fr;
      grid-gap: 10px;
      padding: 20px;
      align-items: center;
      justify-items: center;
      background: #6f3e98;
      border-radius: 8px 8px 0px 0px;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #fff;
      }
    }
    .titulo {
      display: grid;
      grid-template-columns: repeat(6, 1fr) 2fr;
      grid-gap: 10px;
      padding: 20px;
      align-items: center;
      justify-items: center;
      background: #6f3e98;
      border-radius: 8px 8px 0px 0px;
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #fff;
      }
    }
    .img-loading {
      display: block;
      margin: 50px auto;
      width: 100px;
      height: 100px;
    }
  }
`;

export const Container = styled.div`
   min-height: 100vh;
  .grid {
    padding: 0px 0px 0px 0px;
    display: grid;
    grid-template-columns: 267px 343px 360px 600px;
    height: 404px;
    margin-top: 46px;
    margin-bottom: 100px;
    .produtos{
      .labelName{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
  .item {
    margin: 5px;
    font-size: 1.5em;

    .label-detalhes{
      display:flex;
      flex-direction: column;
    }
  } 
  .detalhes{
    display: flex;
    flex-direction: row;
    margin-top: 62px;
  }
  .detalhes-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    .detalhes-arquivo{
      display: flex;
      justify-content: flex-end;
      align-items:center;  
      
      margin-bottom: 38px;
      .detalhes-item{
        margin-right:75px;
        align-items:center;      
      }    
      .subtitle {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #6F3E98;
        margin-left:16px;   
        
        .imgVoucher{
          margin-bottom: -2px;
        }
        
        img{
          padding-top:5px;
          margin-right:10px;         
          width:15px;
          height:auto;         
        } 
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        color: #888888;
      }
    }
  }
  .clicavel{
    cursor: pointer;
  }
  .block-card {
    display: flex;
  }
  .logo{
    /* position: absolute; */
    width: 255px;
    height: 185px auto;
    left: 156px;
    top: 299px;
    background: ${(props) => props.color ?? '#8B288C'} ;
    border-radius: 5px;
    display:flex;
    align-items:center;
    justify-content:center;

    img{
      max-width:150px;
      max-height: 150px;
    }
  }
  hr {
    border: 1px solid #E9E9E9;  
    margin-top:53px;  
  }
  .conteudo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
    grid-gap: 30px;
    padding: 30px;
  }
  .img-loading {
    display: block;
    margin: 50px auto;
    width: 100px;
    height: 100px;
  }
`;

export const Detalhes = styled.div`
  min-height: 100vh;
  .container {
    width: 1300px;
  }
  .progress-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    ${Progress} {
      width: 300px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .btns {
      display: flex;
      align-items: center;
      grid-gap: 10px;
      button {
        width: auto;
        border: 1px solid #703d98;
        border-radius: 4px;
        font-size: 14px;
        padding: 0 20px;
        text-transform: uppercase;
      }
    }
  }

  .abas-tabela {
    display: flex;
    position: relative;
    margin-top: 20px;
    .abas {
      display: flex;
      z-index: 1;
    }
    .item {
      font-size: 16px;
      line-height: 18px;
      color: #626262;
      background: #eeeeee;
      border: 1px solid #d9d9d9;
      box-sizing: border-box;
      border-radius: 4px 4px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 159px;
      height: 64px;
      cursor: pointer;
      &.active {
        font-weight: 500;
        color: #6f3e98;
        background: #ffffff;
        border: 1px solid #ba82ea;
        border-bottom: none;
        position: relative;
        z-index: 10;
      }
    }
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #ba82ea;
      position: absolute;
      bottom: 0;
      left: 0px;
    }
  }

  .campos-busca {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    input {
      background: #ffffff;
      border: 1px solid #cecece;
      box-sizing: border-box;
      border-radius: 4px;
    }
    .busca {
      position: relative;
      &:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 18px;
        background-image: url(${busca});
        z-index: 20;
      }
    }
    .select {
      display: flex;
      grid-gap: 10px;
      ${Select} {
        width: 200px;
        background: #ffffff;
        border: 1px solid #cecece;
        box-sizing: border-box;
        border-radius: 4px;
        height: 56px;
        .react-select__control {
          height: 56px;
        }
      }
    }
  }
`;
