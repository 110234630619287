import React from 'react';

import { Container } from './styles';

function Progress(props) {

  let links_usados = props.usados;
  var porcentagem_usados = 0;
  let links_estornados = props.estornados;
  let porcentagem_estornados = 0;
  let links_reservados = props.reservados;
  if (links_reservados === 0) {
    links_reservados = 1;
  }
  porcentagem_estornados = (links_estornados * 100) / links_reservados;
  porcentagem_usados = (links_usados * 100) / links_reservados;

  return (
    <>
      {props.reserva_estoque === 1 ?
        <Container color={'verde'}>
          <div className='progresso'>
            <span style={{ width: `${porcentagem_usados}%` }}></span>
            <div className='estornado' style={{ width: `${porcentagem_estornados}%` }}></div>

          </div>
          <p style={{ left: `${0}%` }}>{links_usados} </p>
          <p style={{ left: `${porcentagem_usados === 0 ? porcentagem_usados + 3 : porcentagem_usados}% ` }}>{links_estornados === 0 ? '' : links_estornados} </p>
          <p style={{ left: `100%` }}>{links_reservados}</p>

        </Container>
        :
        <Container color={'vinho'}>
          <div className='progresso'>
            <span style={{ width: `${porcentagem_usados}%` }}></span>

          </div>
          <p style={{ left: `${100}%` }}>{links_usados} </p>
        </Container>
      }
    </>
  );
}

export default Progress;

