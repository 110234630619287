import styled from 'styled-components';

export const Container = styled.div`
  background: linear-gradient(90deg, #8a288b 0%, #762678 100%);
  height: 80px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    font-weight: 500;
    font-size: 14px;
    line-height: 38px;
    color: ${(props) => props.theme.colors.branco};
    display: flex;
    grid-gap: 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 38px;
    color: ${(props) => props.theme.colors.branco};
  }
`;
