import React from 'react';
// import { Link } from 'react-router-dom';

import { Container } from './styles';
import img2 from '../../assets/img/admin/filtro.svg';
import ButtonTransparente from '../../components/buttonTransparente';

function TopoProdutos({  
  activeFiltro, 
  toggleFiltro, 
  activeBoxRow, 
  setBoxRow,
  atualizaProduto}) {
  return (
    <Container>
      <h4>Catalogo de Produtos</h4>
      <div className="imgs">
      <ButtonTransparente title = 'Atualizar produtos'
       onClick={atualizaProduto} />
        <span
          className={activeFiltro ? 'active' : ''}
          onClick={() => toggleFiltro(!activeFiltro)}
        >
          <img src={img2} alt="" />
        </span>
      </div>
    </Container>
  );
}

export default TopoProdutos;
