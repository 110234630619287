import React, { useState, useEffect } from "react";

import Menu from "../../../components/menuLateral";
import Filtro from "../../../components/filtro/index";
import Topo from "../../../components/topo/topoContratante";
import TabelaContratantes from "../../../components/tabelas/contratante";

import { api } from "../../../services/api";

import { Container, Conteudo } from "./styles";

function Contratantes() {
  const [active, toggleFiltro] = useState(false);
  const [dados, setDados] = useState([]);

  useEffect(() => {
    api
      .get(`/contratante`)
      .then((data) => {
        setDados(data);
        // setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSearch = (e) => {
    // setBusca(e.target.value)
    // // eslint-disable-next-line no-unused-expressions
    // dadosFiltrados
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Nome do Contratante",
        accessor: "nome",
      },
      {
        Header: "Área",
        accessor: "area",
      },
      {
        Header: "CNPJ",
        accessor: "cnpj",
      },
      {
        Header: "Contato",
        accessor: "contato",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Telefones",
        accessor: "telefone",
      },
    ],
    []
  );

  return (
    <Container>
      <Menu />
      <Conteudo>
        <Topo activeFiltro={active} toggleFiltro={toggleFiltro} />

        <Filtro activeFiltro={active} onChange={(e) => handleSearch(e)} />

        <div className="conteudo">
          <TabelaContratantes columns={columns} data={dados} />
        </div>
      </Conteudo>
    </Container>
  );
}

export default Contratantes;
