import React from "react";
import { UsuariosAcao } from "./styles";
import _ from "lodash";
import status from "../../assets/img/admin/status.svg";
import statusOn from "../../assets/img/admin/statusOn.svg";

const usuariosAcao = ({ data }) => {
  return (
    <UsuariosAcao>
      {data && data.acao_vinculada ? (
        <>
          <span>{data.funcional}</span>
          <span>{data.celula}</span>
          <span>{data.nome}</span>
          <span>{data.campo1}</span>
          <span>{data.campo2}</span>
          <span>{data.dt_primeiro_acesso}</span>
          <span>
            {_.times(data.resgates_limite - data.resgates.length, (i) => (
              <div className="status" index={i}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={status}></img>
              </div>
            ))}
            {data.resgates?.map((data, index) => (
              <div className="status" index={index}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={statusOn}></img>
                <span className="data">{data.data_abertura}</span>
                <span> {data.alias_name}</span>
                <span>- R$ {data.valor} </span>
              </div>
            ))}
          </span>
        </>
      ) : (
        ""
      )}
    </UsuariosAcao>
  );
};
export default usuariosAcao;
