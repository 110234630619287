import styled from 'styled-components';

export const Container = styled.div`
  width: 160px;
  height: 100%;
  padding: 50px 0;
  position: fixed;
  left: 0;
  height: 100%;
  background-color: ${(props) => props.theme.colors.branco};
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-rows: 1fr 1fr 3fr;
  justify-content: center;
  .menu-principal {
    display: grid;
    grid-gap: 40px;
    justify-content: center;
    .icone-cinza {
      filter: invert(88%) sepia(13%) saturate(0%) hue-rotate(194deg)
        brightness(92%) contrast(89%);
    }
  }
  .usuario {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    text-align: center;
    button {
      margin-top: 10px;
      background: none;
      color: #6f3e98;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
