import Swal from "sweetalert2";

import { api } from "../../services/api";

import Button from "../../components/button";

import { EstoqueParcial } from "./styles";

import close from "../../assets/img/admin/close.png";

export default function ModalExportaRelatorio({
  active,
  handleClose,
  id_acao,
}) {
  function exportarResgates() {
    api
      .post(`acao/detalhes/relatorio/resgates`, {
        id_acao: id_acao,
      })
      .then(({ data, status }) => {
        if (status === true) {
          msg();
          monitoraExport(data.id_relatorio);
        } else {
          // setMensagem(data.mensagem);
          // setActive(true);
        }
      })
      .catch((erro) => {
        console.log("error ", erro);
      });
  }

  function exportarAcessos() {
    api
      .post(`acao/detalhes/relatorio/acessos`, {
        id_acao: id_acao,
      })
      .then(({ data, status }) => {
        if (status === true) {
          msg();
          monitoraExport(data.id_relatorio);
        } else {
          // setMensagem(data.mensagem);
          // setActive(true);
        }
      })
      .catch((erro) => {
        console.log("error ", erro);
      });
  }

  function monitoraExport(v) {
    api
      .post(`acao/detalhes/relatorio/verificar`, {
        id_relatorio: v,
      })
      .then((data) => {
        if (data.falhou) {
        } else {
          if (data.status === true) {
            window.open(data.data.link);
            Swal.close();
          } else {
            setTimeout(function () {
              monitoraExport(v);
            }, 20000);
          }
        }
      })
      .catch((error) => {
        console.log("error ", error);
      });
  }

  function msg() {
    Swal.fire({
      icon: "success",
      title: "Arquivo em processo!",
      html: "Por favor aguarde enquanto processamos seu pedido...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  }

  return (
    <EstoqueParcial>
      <h3>Exportar Relatório</h3>
      <img
        className="close"
        src={close}
        alt=""
        onClick={() => handleClose(!active)}
      />

      <Button
        className="btnExporta"
        title="Relatório de Resgates"
        type="button"
        onClick={() => exportarResgates()}
      />
      <Button
        className="btnExporta"
        title="Relatório de Acessos (Logins dos Participantes)"
        type="button"
        onClick={() => exportarAcessos()}
      />
    </EstoqueParcial>
  );
}
