import React from "react";

import { Container } from "./styles";
import iconDefault from "../../assets/img/admin/camera.svg";

export default function CardInformacao(props) {
  const { title = "Acessar", subtitle = "teste", icon } = props;

  return (
    <Container >
       {/* eslint-disable-next-line */}
      <img src={icon ?? iconDefault}/>
      <span className="title">{title}</span>
      <span className="subtitle"> {subtitle}</span>
    </Container>
  );
}
