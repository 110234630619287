import { toast } from "react-toastify";
import { DebounceInput } from "react-debounce-input";
import { PaginacaoStyles } from "./styles";
export function Paginacao(props) {
    const {
        page,
        lastPage,
        goToPage,
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
    } = props;

    const debaounce = (value) => {
        if (parseInt(value) <= lastPage) {
            goToPage(parseInt(value));
        } else {
            if (value !== "") {
                toast.error("Página inválida");
            }
        }
    };
    return (
        <PaginacaoStyles>
            <div className="pagination">
                <div className="pre">
                    <button
                        onClick={() => goToPage(0)}
                        disabled={!canPreviousPage}
                    >
                        {"<<"}
                    </button>{" "}
                    <button
                        onClick={() => previousPage(page - 1)}
                        disabled={!canPreviousPage}
                    >
                        {"<"}
                    </button>{" "}
                </div>
                <div className="next">
                    <button
                        onClick={() => nextPage(page + 1)}
                        disabled={!canNextPage}
                    >
                        {">"}
                    </button>{" "}
                    <button
                        onClick={() => goToPage(lastPage)}
                        disabled={!canNextPage}
                    >
                        {">>"}
                    </button>{" "}
                </div>
                <span>
                    Pagina <strong>{`${page} de ${lastPage } `  }</strong>
                    {"  "}
                </span>
                <span> &nbsp; | Vá para pagina: </span>{" "}
                <div className="container-input">
                    <DebounceInput
                        placeholder=""
                        type="number"
                        style={{ width: "100px" }}
                        minLength={1}
                        debounceTimeout={500}
                        onChange={(event) => debaounce(event.target.value)}
                    />
                </div>
            </div>
        </PaginacaoStyles>
    );
}
