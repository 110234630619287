import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField, Autocomplete } from "formik-mui";
import { DatePicker } from "formik-mui-lab";
import MuiTextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ptLocale from "date-fns/locale/pt";
import BtnSwitch from "../btnSwitch";
import { SwitchCadastro } from "./styles";

import { api } from "../../services/api";

import Button from "../button";

import { Btns } from "./styles";

function Dados({ values = null, onSubmit }) {
  const history = useHistory();
  // const storage = JSON.parse(localStorage.getItem('dados'));

  const [chave, setChave] = useState([]);
  const [url, setUrl] = useState([]);

  const [contratantes, setContratantes] = useState([]);

  const optionsQtd = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  useEffect(() => {
    api
      .get(`/contratante`)
      .then((data) => {
        const options = data.map(({ id, nome }) => ({
          value: id,
          label: nome,
        }));

        setContratantes(options);
      })
      .catch(() => {
        setContratantes([]);
      });
  }, []);

  useEffect(() => {
    api
      .get(`/acao/all`)
      .then((data) => {
        const chaves = data.acoes.map((chave) => chave.chave);
        const urls = data.acoes.map((res) => `www.yetzpromo.com.br/${res.url}`);
        setChave(chaves);
        setUrl(urls);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const initialValues = {
    contratante_id: values?.contratante_id ?? undefined,
    nome: values?.nome ?? "",
    descricao: values?.descricao ?? "",
    chave: values?.chave ?? "",
    url: values?.url ?? "https://yetzpromo.com.br/",
    qtd_limite_resgates: values?.qtd_limite_resgates ?? undefined,
    entregar_em_conjunto: values?.entregar_em_conjunto ?? true,
    data_inicio: values?.data_inicio ?? null,
    data_fim: values?.data_fim ?? null,
    reserva_estoque: values?.reserva_estoque ?? true,
    gerar_participantes: values?.gerar_participantes ?? true,
    qtd_gerar_participantes: values?.qtd_gerar_participantes ?? "",
    // estoque_visivel: values?.estoque_visivel ?? true,
  };

  const validationSchema = Yup.object().shape({
    contratante_id: Yup.string().required("Campo Obrigatório"),
    nome: Yup.string().required("Campo Obrigatório"),
    descricao: Yup.string().required("Campo Obrigatório"),
    chave: Yup.string()
      .required("Campo Obrigatório")
      .notOneOf(chave, "Essa chave já existe"),
    url: Yup.string()
      .required("Campo Obrigatório")
      .notOneOf(url, "Essa Url já existe")
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Por favor digite uma url correta!"
      )
      .required("Por favor digite uma url correta"),
    qtd_limite_resgates: Yup.string().required("Campo Obrigatório"),
    data_inicio: Yup.date().required("Campo Obrigatório"),
    data_fim: Yup.date().required("Campo Obrigatório"),
    qtd_gerar_participantes: Yup.string().when("gerar_participantes", {
      is: true,
      then: Yup.string().required("Campo Obrigatório"),
      otherwise: Yup.string(),
    }),
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <div className="container-form">
                <Field
                  component={Autocomplete}
                  name="contratante_id"
                  options={contratantes}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label="Contratante"
                      variant="filled"
                      color="secondary"
                    />
                  )}
                  onChange={(_, opt) =>
                    setFieldValue("contratante_id", opt?.value ?? undefined)
                  }
                />
              </div>
              <div className="container-form">
                <Field
                  type="text"
                  name="nome"
                  label="Nome da Ação"
                  component={TextField}
                  variant="filled"
                  color="secondary"
                />
              </div>
              <div className="container-form">
                <Field
                  type="text"
                  name="job"
                  label="Job"
                  component={TextField}
                  variant="filled"
                  color="secondary"
                />
              </div>
              <div className="container-form">
                <Field
                  component={Autocomplete}
                  name="qtd_limite_resgates"
                  options={optionsQtd}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value
                  }
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label="Total de resgates permitidos"
                      variant="filled"
                      color="secondary"
                    />
                  )}
                  onChange={(_, opt) =>
                    setFieldValue(
                      "qtd_limite_resgates",
                      opt?.value ?? undefined
                    )
                  }
                />
              </div>
              {values.qtd_limite_resgates > 1 && (
                <SwitchCadastro>
                  <span>Entregar vouchers em conjunto</span>
                  <BtnSwitch name="entregar_em_conjunto" />
                </SwitchCadastro>
              )}
              <SwitchCadastro>
                <span>Reservar estoque</span>
                <BtnSwitch name="reserva_estoque" />
              </SwitchCadastro>
              <SwitchCadastro>
                <span>Gerar participantes</span>
                <BtnSwitch name="gerar_participantes" />
              </SwitchCadastro>
              {values.gerar_participantes && (
                <div className="container-form">
                  <Field
                    type="number"
                    label="Quantidade de Participantes"
                    component={TextField}
                    name="qtd_gerar_participantes"
                    variant="filled"
                    color="secondary"
                  />
                </div>
              )}
              {/* <SwitchCadastro>
                <span>Mostrar estoque</span>
                <BtnSwitch name="estoque_visivel" />
              </SwitchCadastro> */}
              <div className="container-form">
                <Field
                  type="text"
                  label="Descrição da Ação"
                  component={TextField}
                  name="descricao"
                  multiline
                  rows={4}
                  variant="filled"
                  color="secondary"
                />
              </div>
              <div className="container-form">
                <Field
                  type="text"
                  label="Chave da Ação"
                  name="chave"
                  component={TextField}
                  variant="filled"
                  color="secondary"
                />
                <img src={url} alt="" />
              </div>
              <div className="container-form">
                <Field
                  type="text"
                  label="Url da Ação"
                  name="url"
                  component={TextField}
                  variant="filled"
                  color="secondary"
                />
                <img src={url} alt="" />
              </div>
              <div className="container-form">
                <Field
                  component={DatePicker}
                  name="data_inicio"
                  label="Data de inicio"
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label="Data de inicio"
                      variant="filled"
                    />
                  )}
                />
              </div>
              <div className="container-form">
                <Field
                  component={DatePicker}
                  name="data_fim"
                  label="Data de fim"
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label="Data de fim"
                      variant="filled"
                    />
                  )}
                />
              </div>
              <Btns>
                <Button
                  title="Cancelar"
                  type="button"
                  bgc="red"
                  onClick={() => history.goBack()}
                />
                <Button title="Avançar" />
              </Btns>
            </Form>
          );
        }}
      </Formik>
    </LocalizationProvider>
  );
}

export default Dados;
