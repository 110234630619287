import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";

import { api } from "../../services/api";

import Button from "../../components/button";
import Footer from "../../components/footer";
import { AuthContext } from "../../contexts/AuthContext";
import Modal from "../../components/modal";
import { PRINCIPAL } from "../../routes/paths";
import logo from "../../assets/img/admin/logo2.svg";
import olho from "../../assets/img/admin/olho-login.svg";

import { Login as Container } from "./styles";
import { ModalValidaLogin } from "../../components/ModalValidaLogin";

// install Swiper modules
SwiperCore.use([EffectFade, Autoplay]);

function Login() {
  const history = useHistory();

  const authContext = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [data, setData] = useState({});
  const [statusErrorCode, setStatusErrorCode] = useState(false);

  const [imagens, setImagens] = useState([]);

  const initialValues = {
    email: "",
    password: "",
  };

  const config = {
    onclose: () => {
      setModalConfirm(false);
    },
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Insira um E-mail válido")
      .required("E-mail e um campo obrigatório"),
    password: Yup.string().required("Campo Obrigatório"),
  });

  function onclose() {
    setModalConfirm(false);
  }

  async function enviaSms(data) {
    const result = await authContext.enviaSms(data.email);
    if (result) {
      setData(data);
      setModalConfirm(true);
    }
  }

  async function verificaCodigo(code) {
    const result = await authContext.verificaCodigo(code, data.email);
    if (result === true) {
      setModalConfirm(false);
      authContext.signIn(data);
    } else {
      setStatusErrorCode(true);
    }
  }

  useEffect(() => {
    api
      .post(`/acao/banners`, {
        id_acao: "",
      })
      .then((data) => {
        setImagens(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      history.push(PRINCIPAL);
    }
  }, [authContext.isAuthenticated, history]);

  return (
    <>
      <Container>
        <div className="container">
          <div className="container-esq">
            <Swiper
              // install Swiper modules
              modules={[EffectFade, Autoplay]}
              effect="fade"
              fadeEffect={{
                crossFade: true,
              }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop="true"
            >
              {imagens.map((data, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img src={data.link} alt="" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <div className="container-dir login">
            <img src={logo} alt="" className="logo" />

            <h2>ACESSO ADMINISTRADOR</h2>

            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(data) => enviaSms(data)}
            >
              <Form>
                <div className="container-form">
                  <Field
                    type="text"
                    placeholder="DIGITE SEU LOGIN"
                    name="email"
                  />
                </div>
                <ErrorMessage component="small" name="email" />
                <div className="container-form">
                  <Field
                    type={showPassword ? "text" : "password"}
                    placeholder="DIGITE SUA SENHA"
                    name="password"
                    autoComplete="new-password"
                  />
                  <span
                    className={`check-password ${
                      !showPassword ? "active" : ""
                    }`}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <img src={olho} alt="" className="" />
                  </span>
                </div>
                <ErrorMessage component="small" name="password" />
                {authContext.errorLogin === true ? (
                  <span>Email ou senha inválido</span>
                ) : (
                  ""
                )}
                <Button title="ENTRAR" />

                {/* <small className="esqueci-senha">Esqueci minha senha</small> */}
              </Form>
            </Formik>
            <Modal
              component={ModalValidaLogin}
              active={modalConfirm}
              rest={config}
              onclose={onclose}
              email={data.email}
              error={statusErrorCode}
              verificaCodigo={(value) => verificaCodigo(value)}
            />
          </div>
        </div>
      </Container>

      <Footer />
    </>
  );
}

export default Login;
