import styled, { css } from "styled-components";

import { Container as Box } from "../boxProduto/styles";
import { Container as Button } from "../button/styles";

const active = css`
  background: ${(props) => props.overlay};
  pointer-events: all;
  z-index: 99;
  transition: all 0.5s;
  .container-body {
    top: 50% !important;
    transition: all 0.5s;
  }

  @media (max-width: 480px) {
    .container-body {
      top: 40% !important;
    }
  }
`;

export const Products = styled.div`
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const scroll = css`
  overflow-y: scroll;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    border-radius: 5px;
  }

  &::-webkit-scrollbar:vertical {
    width: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 12px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(89, 92, 118, 0.5);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
  }
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  background-color: transparent;
  z-index: -1;
  pointer-events: none;
  ${(props) => (props.active ? active : null)};
  transition: all 0.5s;
  .container-body {
    position: absolute;
    top: -5000px;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 736px; */
    height: 596px;
    display: flex;
    align-items: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.5s;
    ${(props) => (props.scroll ? scroll : null)};
  }

  @media (max-width: 480px) {
    .container-body {
      width: 90%;
    }
  }
`;

export const AddProduto = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.branco};
  width: 736px;
  height: 725px;
  border-radius: 8px;
  padding: 48px;
  margin: 0 auto;
  position: relative;
  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    margin: 0;
  }
  input[type="text"] {
    border: 1px solid #dfdfdf;
    background: #fff;
    width: 100%;
    border: 1px solid #6f3e98;
    padding: 20px;
    margin: 20px 0px 20px 0px;
  }
  ${Box} {
    margin: 20px 0;
  }
  ${Button} {
    margin: 0;
  }
  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 20px;
  }
  button {
    width: 253px;
    height: 50px;
    background-color: #1cde90;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    padding: 14px 20px 14px 20px;
  }
`;

export const AddUsuario = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.branco};
  width: 518px;
  min-height: 586px;
  border-radius: 8px;
  padding: 30px 67px;
  margin: 0 auto;
  position: relative;
  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    margin: 0;
  }
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #713d97;
    margin-bottom: 20px;
  }
  ${Button} {
    background: #713d97;
    border-radius: 4px;
    width: 235px;
    height: 43px;
    font-size: 14px;
    display: block;
    margin: 0 auto;
  }
`;

export const EstoqueParcial = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.branco};
  width: 518px;
  border-radius: 8px;
  padding: 30px 67px;
  margin: 0 auto;
  position: relative;
  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    margin: 0;
  }
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #713d97;
    margin-bottom: 20px;
  }
  ${Button} {
    background: #713d97;
    border-radius: 4px;
    width: 235px;
    height: 43px;
    font-size: 14px;
    display: block;
    margin: 0 auto;
    &.btnExporta {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

export const AddDetalhes = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.branco};
  width: 518px;
  min-height: 350px;
  border-radius: 8px;
  padding: 30px 67px;
  margin: 0 auto;
  position: relative;
  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    margin: 0;
  }
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #713d97;
    margin-bottom: 20px;
  }
  ${Button} {
    background: #713d97;
    border-radius: 4px;
    width: 100%;
    height: 43px;
    font-size: 14px;
    display: block;
    margin: 0 auto;
  }
`;

export const AlterarData = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.branco};
  width: 518px;
  min-height: 100px;
  border-radius: 8px;
  padding: 30px 67px;
  margin: 0 auto;
  position: relative;
  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    margin: 0;
  }
  h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #713d97;
    margin-bottom: 20px;
  }
  ${Button} {
    background: #713d97;
    border-radius: 4px;
    width: 100%;
    height: 43px;
    font-size: 14px;
    display: block;
    margin: 0 auto;
  }
`;

export const Btns = styled.div`
  width: 383px;
  display: flex;
  grid-gap: 20px;
  .cancell {
    background: red;
  }
  button {
    font-size: 18px;
    border-radius: 4px;
  }
`;
