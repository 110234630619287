import styled from "styled-components";

import { Container as Box } from "../boxProduto/styles";

import erro from "../../assets/img/admin/erro-cinza.svg";

export const ContainerProdutos = styled.div`
  p {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: ${(props) => props.theme.colors.Cinza02};
  }
  .contadores {
    display: flex;
    grid-gap: 10px;
    margin: 20px 0;
    span {
      cursor: pointer;
      font-weight: 500;
      font-size: 18.6004px;
      line-height: 23px;
      color: ${(props) => props.theme.colors.Cinza03};
      background-color: #f6f6f6;
      border-radius: 3px;
      width: 54px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        background-color: ${(props) => props.theme.colors.branco};
        color: ${(props) => props.theme.colors.Vinho1};
        font-weight: bold;
        border: 1px solid ${(props) => props.theme.colors.Laranja1};
      }
    }
  }
  .add {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #6f3e98;
    border: 1px solid #6f3e98;
    box-sizing: border-box;
    border-radius: 4px;
    width: 244px;
  }
  .produtos {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin: 20px 0;
  }
  button {
    width: 168px;
  }
`;

export const ContainerFinal = styled.div`
  width: 383px;
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: ${(props) => props.theme.colors.Vinho1};
    margin-bottom: 20px;
  }
  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #888888;
  }
  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;
    color: ${(props) => props.theme.colors.Cinza02};
    margin-bottom: 16px;
  }
  .links {
    margin: 10px;
  }
  .secao {
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 16px;
    .logo {
      border-radius: 5px;
      height: 131px;
      width: 383px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
      margin-top: 16px;
    }
    .cor {
      position: relative;
      &:before {
        content: "";
        display: block;
        background-color: ${(props) => props.color};
        border-radius: 7px;
        width: 29px;
        height: 29px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }
    ${Box} {
      width: 383px;
      border: none;
      padding: 0;
      margin-bottom: 20px;
      img {
        height: 46px;
      }
      h4,
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #000000;
      }
      .qtd {
        span {
          border: none;
          background: none;
          padding: 0;
        }
      }
    }
  }
`;

export const ResumoUpload = styled.div`
  background-color: rgba(212, 212, 212, 0.2);
  border-radius: 4px;
  max-width: 383px;
  padding: 20px;
  margin-top: 30px;
  order: 2;
  margin-bottom: 15px;
  h4 {
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.colors.preto};
    margin-bottom: 20px;
  }
  .erro {
    position: relative;
    padding-left: 32px;
    &.success {
      span {
        color: #1cde90;
      }
      &:before {
        background-color: #1cde90;
      }
    }

    &.error {
      span {
        color: #eb5757;
      }
      &:before {
        background-color: #eb5757;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      -webkit-mask: url(${erro});
      background-color: #848484;
      z-index: 20;
    }
  }
  .usuarios,
  .link,
  .erro {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      margin-right: 10px;
    }
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: ${(props) => props.theme.colors.Cinza01};
    }
  }
  .detalhes-arquivo {
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1px;
    color: ${(props) => props.theme.colors.Cinza03};
    display: flex;
    img {
      margin-left: 5px;
      transition: all 0.5s;
      filter: grayscale(1);
      opacity: 0.4;
    }
    &:hover {
      color: ${(props) => props.theme.colors.Vinho1};
      img {
        margin-left: 20px;
        transition: all 0.5s;
        filter: none;
        opacity: 1;
      }
    }
  }
`;

export const Btns = styled.div`
  width: 383px;
  display: flex;
  grid-gap: 20px;
  margin-top: 20px;
  button {
    font-size: 18px;
    border-radius: 4px;
  }
`;

export const SwitchCadastro = styled.div`
  display: flex;
  justify-content: space-between;
  width: 383px;
  margin: 14px 0;
  span {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }
`;

export const CamposVideo = styled.div`
  margin-bottom: 40px;
`;
