import React from "react";
import Select from "../select";
import {Detalhes} from './styles';

function FiltroUsuario({ value, handleChange, filtroCelula,filtroStatus, filtroPolo}) {

    return (
        <Detalhes>
            <div className="campos-busca">
            <div className="busca">
                <input
                    value={value}
                    type="text"
                    placeholder="Busca"
                    onChange={handleChange}
                />                 
            </div>
                <div className="select">
                <Select label="Status" options={filtroStatus} />
                <Select label="Célula" options={filtroCelula} 
                onChange={handleChange} />
                <Select label="Polo" options={filtroPolo} 
                onChange={handleChange} />
                </div>
            </div> 
        </Detalhes>
    );
  }
  
  export default FiltroUsuario;
