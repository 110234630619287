import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import FormData from "form-data";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import Button from "../button";
import InputFile from "../inputFile";

import ResumoUpload from "./resumoUpload";

import { api } from "../../services/api";

import { Btns } from "./styles";

function Usuario({ hash, values = null, dados, onSubmit }) {
  const history = useHistory();

  const [resume, setResume] = useState(undefined);

  const retrieveTheResume = () => {
    if (values) {
      setResume(values.resume);
    }
  };
  useEffect(() => {
    retrieveTheResume();
    // eslint-disable-next-line
  }, []);

  const initialValues = {
    usuarios: "",
  };

  const validationSchema = Yup.object().shape({
    usuarios: Yup.mixed(),
  });

  async function handleSubmit(values) {
    if (!dados) {
      if (resume) {
        onSubmit({ ...values, resume });
      } else {
        try {
          const formData = new FormData();

          formData.append("hash", hash);
          formData.append("planilha", values.usuarios);

          const { erro, erroMsg, retorno } = await api.post(
            "acao/carregaUsuarios",
            formData
          );

          if (erro) {
            alert(erroMsg);
          } else {
            setResume(retorno);
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      onSubmit({ ...values, resume });
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values ?? initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        {!dados ? (
          <>
            <Field name="usuarios" component={InputFile} />

            <ErrorMessage component="span" name="usuarios" />

            {!!resume && <ResumoUpload resume={resume} />}
          </>
        ) : (
          <>
            <h3>Você optou por gerar os participantes automaticamente.</h3>
          </>
        )}
        <Btns>
          <Button
            title="Cancelar"
            type="button"
            bgc="red"
            onClick={() => history.goBack()}
          />
          <Button title="Avançar" />
        </Btns>
      </Form>
    </Formik>
  );
}

export default Usuario;
