import React, { useEffect, useState } from "react";
import { api } from "../../services/api";
import { Tabela } from "./styles";
import img from "../../assets/img/admin/loader-yetz-1.svg";
import RelatoriosTable from "../../components/rowTabelas/relatoriosAcao";

function Relatorios({ id }) {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .post(`/acao/detalhes/relatorio/todos`, { id_acao: id })
      .then((data) => {
        console.log(data);
        setDados(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <>
      {loading ? (
        <img className="img-loading" src={img} alt="" />
      ) : (
        <>
          <Tabela>
            <div className="conteudo">
              <div className="titulo_relatorio">
                <span>Data</span>
                <span>Nome</span>
                <span>Status</span>
                <span>Acesso</span>
              </div>
              {dados.map((datas, index) => {
                return <RelatoriosTable data={datas} key={index} />;
              })}
            </div>
          </Tabela>
        </>
      )}
    </>
  );
}

export default Relatorios;
