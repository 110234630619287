import styled from 'styled-components';

export const Container = styled.div`
  background: #F8F8F8;
  width: 300px;
  height:40px;
  display: flex;
  align-items: center;
  margin-bottom:10px;
  border-radius: 8px;
  .name{
    display:flex;
    width:100%;
    align-items: center;
    justify-content: center;
    .items{
      flex: 1;
      text-align: center;
      align-content: center;
      h4{
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 100%;
        color: #000000;
      }
      span{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #575757;
      }
    }
  }  
`;
