import React from "react";
import { Container } from "./styles";
import img from "../../assets/img/admin/vendeu-ganhou.png";
import iconeFoto from "../../assets/img/admin/camera.svg";
import iconePonto from "../../assets/img/admin/icone-tres-pontos.svg";
import iconeSeta from "../../assets/img/admin/icone-seta.svg";
import iconeSetaAcima from "../../assets/img/admin/icone-seta-acima.svg";
import BoxProdutoVariacao from "../../components/rowTabelas/estoque";

const BoxProdutoEstoque = ({
  data,
  onClick,
  setClass = 0,
  onChangeFileModal,
  onChangeFilePrincipal,
}) => {
  return (
    <Container
      color={data.cor ? data.cor : "#6f3e98"}
      display={setClass === 1 ? "grid" : "none"}
    >
      <div className="s-topo">
        <div className="s-dir">
          <div className="titulo">
            <span>Nome do voucher</span>
            <h3>{data.alias_name}</h3>
          </div>
          <div className="opcoes">
            <img className="icone_option" src={iconePonto} alt="" />
          </div>
          <div className="opcoes-bottom" onClick={onClick}>
            <img
              className="icone_option"
              src={setClass === 1 ? iconeSetaAcima : iconeSeta}
              alt=""
            />
            {/* <img className="icone_option" src={setClass === 1 ? iconeSetaAcima : iconeSeta}  alt="" /> */}
          </div>
        </div>
        <div className="imagens-detalhes">
          <div className="imagem">
            <div className="img-destaque">
              <label className="icone" htmlFor={`file-${data.id}`}>
                <img src={iconeFoto} alt="" />{" "}
              </label>
              <input
                type="file"
                id={`file-${data.id}`}
                onChange={(e) => onChangeFilePrincipal(e, data.id)}
                style={{ display: "none" }}
                accept="image/png, image/jpeg"
              />
              <img
                className="img"
                src={data.alias_imagem_temp ? data.alias_imagem_temp : img}
                alt=""
              />
            </div>
            <span>Foto Principal</span>
          </div>
          <div className="imagem">
            <div className="img-destaque">
              <label className="icone" htmlFor={`file-modal-${data.id}`}>
                <img src={iconeFoto} alt="" />
              </label>
              <input
                type="file"
                id={`file-modal-${data.id}`}
                onChange={(e) => onChangeFileModal(e, data.id)}
                style={{ display: "none" }}
                accept="image/png, image/jpeg"
              />
              <img
                className="img"
                src={
                  data.alias_imagem_resgate_temp
                    ? data.alias_imagem_resgate_temp
                    : img
                }
                alt=""
              />
            </div>
            <span>Foto do Modal</span>
          </div>
          <div className="conteudoVariacao" id={data.id}>
            <div className="titulo">
              <span>ID</span>
              <span>Valor em Reais</span>
              <span>Estoque</span>
              <span></span>
            </div>
            <BoxProdutoVariacao data={data.variacoes} />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BoxProdutoEstoque;
