import React, { useState } from 'react';
import { useField, ErrorMessage } from 'formik';

import upload from '../../assets/img/admin/upload.svg';
import close from '../../assets/img/admin/fechar_input.png';

import { nomeArq } from '../../utils/helper';

import { PreviewContainer } from './styles';

function InputImg({
  name,
  placeholder,
  showPreview = true,
  previewBackground = undefined,
}) {
  // eslint-disable-next-line
  const [field, _, helpers] = useField({ name, type: 'file' });

  const [previewUrl, setPreviewUrl] = useState(undefined);

  function reset() {
    helpers.setValue(null);
    setPreviewUrl(undefined);
  }

  function handleChange(event) {
    const file = event.target.files[0];
    if (file) {
      helpers.setValue(file);
      getPreviewUrl(file);
    } else {
      helpers.setValue(null);
      setPreviewUrl(undefined);
    }
  }

  function getPreviewUrl(file) {
    const reader = new FileReader();

    reader.onload = function (event) {
      setPreviewUrl(event.target.result);
    };

    reader.readAsDataURL(file);
  }

  return (
    <div>
      <div className="container-form">
        <label
          htmlFor={name}
          className={field.value ? 'custom-file nome-arquivo' : 'custom-file'}
        >
          <input
            id={name}
            name={name}
            type="file"
            accept="image/png, image/gif, image/jpeg, image/svg+xml"
            multiple={false}
            onChange={handleChange}
            onBlur={() => helpers.setTouched(true)}
          />

          {field.value ? nomeArq(field.value.name, 26) : placeholder}
        </label>

        {field.value ? (
          <img src={close} alt="Remove file" onClick={reset} />
        ) : (
          <img src={upload} alt="Add file" />
        )}

        <ErrorMessage component="span" name={name} />
      </div>

      {!!showPreview && previewUrl && (
        <PreviewContainer background={previewBackground}>
          <img src={previewUrl} alt={placeholder} />
        </PreviewContainer>
      )}
    </div>
  );
}

export default InputImg;
