import React from "react";

import { Footer as Container } from "./styles";
import logoFooter from "../../assets/img/admin/logo-footer.svg";
// import siteLock from "../../assets/img/admin/site-lock-footer.svg";

function Footer() {
  return (
    <Container>
      <span className="span-cnpj">
      YETZ LTDA | CNPJ: 28.325.166/0001-05 | 2022 | Todos os direitos reservados
      </span>

      <div className="container-img">
        <img src={logoFooter} alt="" />
        {/* <img src={siteLock} alt="" /> */}
      </div>

      <span>
      Este site é melhor visualizado em navegadores com suporte às especificações HTML5 e CSS3 (Google Chrome, Mozilla Firefox, Microsoft Edge, Safari entre outros). Recomendamos que você utilize a versão mais atual destes navegadores, pois alguns recursos podem não ser exibidos corretamente caso não esteja atualizado.
      </span>
    </Container>
  );
}

export default Footer;
