import React from "react";
import {
  useTable,
  useResizeColumns,
  useFlexLayout,
  usePagination,
} from "react-table";

import OpcoesContratante from "./opcoesContratante";

import { Container } from "./styles";

function renderItens(cell) {
  switch (cell.column.Header) {
    case "Status":
      return (
        <button className={`status ${cell.value.toLowerCase()}`}>
          {cell.value}
        </button>
      );

    default:
      return <span>{cell.value ? cell.render("Cell") : " - "}</span>;
  }
}

function TableContratante(props) {
  const { opcao = true, modal } = props;
  // const { modal } = props;

  const headerProps = (props, { column }) => getStyles(props, "left");

  const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

  const getStyles = (props, align = "left") => [
    props,
    {
      style: {
        justifyContent:
          align === "right"
            ? "flex-end"
            : align === "left"
            ? "flex-start"
            : "center",
        alignItems: "flex-start",
        display: "flex",
      },
    },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: props.columns,
      data: props.data,
      // defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: props.pageSize ? props.pageSize : 20,
      },
    },
    useFlexLayout,
    useResizeColumns,
    usePagination
  );

  return (
    <Container className={modal ? "tabela-modal" : ""}>
      <div {...getTableProps()} className="table">
        <div>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr header">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(headerProps)} className="th">
                  {column.render("Header")}
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? "isResizing" : ""
                    }`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr body">
                {row.cells.map((cell) => {
                  const {
                    row: {
                      original: { id },
                    },
                  } = cell;
                  return (
                    <>
                      <div
                        {...cell.getCellProps(cellProps)}
                        className={`td ${cell.value ? "" : "center"}`}
                      >
                        {renderItens(cell)}
                      </div>

                      {opcao && <OpcoesContratante id={id} />}
                    </>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
}

export default TableContratante;
