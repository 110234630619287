import styled from "styled-components";

export const Container = styled.div`
  background: ${(props) => props.theme.colors.branco};
  border: 2px solid;
  border-color: ${(props) => (props.borderColor ? "#1CDE90" : "#dfdfdf")};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 30px 20px;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  .img-titulo {
    display: flex;
    grid-gap: 20px;
  }
  img {
    width: 73px;
  }
  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #575757;
  }
  .qtd {
    input {
      font-size: 18px;
      max-width: 90px;
      background-color: white;
      border: 1px solid #e0e0e0;
      padding: 0;
      text-align: center;
    }
    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #333333;
      &:first-child {
        background: #f6f6f6;
        border-radius: 4px 0px 0px 4px;
        padding: 15px;
      }
    }
  }
`;
