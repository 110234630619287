import styled from "styled-components";

import setaDir from "../../assets/img/admin/select.svg";

export const Container = styled.div`
  position: relative;
  min-width: 100px;
  height: fit-content;
  &:before {
    content: "";
    display: block;
    width: 14px;
    height: 8px;
    position: absolute;
    top: 50%;
    right: 18px;
    background-image: url(${setaDir});
    z-index: 20;
  }
  .react-select__control {
    /* height: 54px; */
    border: none !important;
    width: 100%;
    border-color: transparent !important;
    box-shadow: none !important;
    position: relative;
    background-color: transparent !important;
    min-height: auto;

    .react-select__value-container {
      padding: 0px;
      margin: 0px;
      height: 100%;
      .react-select__input {
        left: 23px;
        padding: 0px;
        margin: 0px;
        height: 100%;
      }
    }

    &.react-select__control--is-focused {
      .react-select__value-container {
        padding: 0px;
        margin: 0px;
        .react-select__input {
          left: 23px;
          padding: 0px;
          margin: 0px;
        }
      }
    }
  }

  .react-select__menu {
    width: 100%;
    z-index: 99;
    font-size: 15px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__placeholder {
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 2px;
    color: ${(props) => props.theme.colors.Cinza03};
    font-style: normal;
    margin-left: 26px;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  .react-select__single-value {
    letter-spacing: 0.01em;
    font-family: ${(props) => props.theme.fonts.gm};
    color: ${(props) => props.theme.colors.Azul02};
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin-left: 26px;
  }

  .react-select__indicator {
    display: none;
  }

  .react-select__input {
    position: relative;
    margin-left: 30px;
    left: -273px;
  }
`;
