import React, { useState, useEffect } from "react";

import Filtro from "../../../components/filtro/index";
import Topo from "../../../components/topo";
import BoxAcao from "../../../components/boxAcao";
import Row from "../../../components/boxAcao/row";
// import Notificacao from '../../../components/notificacao';
import ScrollToTop from "../../../utils/scrollToTop";
import Menu from "../../../components/menuLateral";

import { api } from "../../../services/api";

import { Container } from "./styles";

import img from "../../../assets/img/admin/loader-yetz-1.svg";

const Acoes = () => {
  const [active, toggleFiltro] = useState(false);

  const [activeBoxRow, setBoxRow] = useState("box");

  const [acoes, setAcoes] = useState([]);

  const [filters, setFilters] = useState({});

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/acao/all`)
      .then((data) => {
        setAcoes(data.acoes);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function handleFiltersChange({ filter, value }) {
    setFilters({ ...filters, [filter]: value });
  }

  function _renderContent() {
    let dadosFiltrados = acoes.filter((item) => {
      if (filters.nome) {
        const isValid = item.nome
          .toLowerCase()
          .includes(filters.nome.toLowerCase());

        if (!isValid) return false;
      }

      if (filters.status) {
        switch (filters.status) {
          case "Ativas": {
            const isValid = item.status == null || item.status === 0;

            if (!isValid) return false;

            break;
          }

          case "Inativas": {
            const isValid = item.status === 1;

            if (!isValid) return false;

            break;
          }

          default:
            break;
        }
      }

      return true;
    });

    switch (activeBoxRow) {
      case "box": {
        return (
          <div className="conteudo">
            {dadosFiltrados.map((data, index) => {
              return <BoxAcao data={data} key={index} />;
            })}
          </div>
        );
      }
      case "row": {
        return (
          <div className="conteudo">
            {dadosFiltrados.map((data, index) => {
              return <Row data={data} key={index} />;
            })}
          </div>
        );
      }
      default: {
        break;
      }
    }
  }

  return (
    <>
      <ScrollToTop />
      <>
        <Menu />
      </>
      <Container>
        <Topo
          activeFiltro={active}
          toggleFiltro={toggleFiltro}
          activeBoxRow={activeBoxRow}
          setBoxRow={setBoxRow}
        />
        <Filtro activeFiltro={active} onChange={handleFiltersChange} />
        {/* <Notificacao tipo="erro" /> */}
        {loading ? (
          <img className="img-loading" src={img} alt="" />
        ) : (
          _renderContent()
        )}
      </Container>
    </>
  );
};

export default Acoes;
