import React, { useEffect, useState } from "react";

import Filtro from "../../../components/filtro/index";
import Topo from "../../../components/topo/topoProdutos";
// import Notificacao from '../../../components/notificacao';
import ScrollToTop from "../../../utils/scrollToTop";
import Menu from "../../../components/menuLateral";
import { api } from "../../../services/api";
import TabelaProdutos from "../../../components/boxProdutoEstoque";
import { Container } from "./styles";

function Produtos() {
  const [active, toggleFiltro] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [selectedProduct, toggleProduct] = useState(-1);

  function openProduct(index) {
    toggleProduct(selectedProduct === index ? -1 : index);
  }
  const onChangeFilePrincipal = (event, id) => {
    event.stopPropagation();
    event.preventDefault();

    let file = event.target.files[0];
    let formData = new FormData();
    formData.append("id", id);
    formData.append("tipo_imagem", "imagem");
    formData.append("imagem", file);
    enviaImagem(formData);
  };

  const onChangeFileModal = (event, id) => {
    event.stopPropagation();
    event.preventDefault();

    let file = event.target.files[0];
    const formData = new FormData();
    formData.append("id", id);
    formData.append("tipo_imagem", "imagem_resgate");
    formData.append("imagem", file);
    enviaImagem(formData);
  };

  const enviaImagem = (form) => {
    api
      .post(`/aliases/atualizarImagem`, form)
      .then((data) => {
        alert("atualizado com sucesso");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const atualizaProduto = () => {
    api
      .post(`/aliases/atualizarCatalogo`)
      .then((data) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    api
      .get(`/aliases/produtos`)
      .then((data) => {
        setProdutos(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <ScrollToTop />
      <>
        <Menu />
      </>
      <Container>
        <Topo
          title={"Catálogo de Produtos"}
          activeFiltro={active}
          toggleFiltro={toggleFiltro}
          atualizaProduto={() => {
            atualizaProduto();
          }}
        />
        <Filtro activeFiltro={active} />
        {/* <Notificacao tipo="erro" /> */}
        <div className="conteudo">
          {produtos.map((data, index) => {
            return (
              <div className="conteudo-item" key={index}>
                <TabelaProdutos
                  data={data}
                  key={index}
                  onClick={() => openProduct(index)}
                  onChangeFileModal={onChangeFileModal}
                  onChangeFilePrincipal={onChangeFilePrincipal}
                  setClass={selectedProduct === index ? 1 : 0}
                />
              </div>
            );
          })}
        </div>
      </Container>
    </>
  );
}

export default Produtos;
