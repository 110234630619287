import styled from 'styled-components';

export const Container = styled.div`
  background: ${(props) => props.theme.colors.branco};
  width: 500px;
  display: flex;
  align-items: center;
  margin: 5px 15px;
  gap: 10px 20px; /* row-gap column gap */
  .img-titulo {
    display: flex;
    grid-gap: 20px;
    margin-top: 25px ;   
  }
  .name{
       margin-top: 25px;      
    }
  img {
    width: 73px;
  }
  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #575757;
  }
  .qtd {
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      &:first-child {
        /* background: #f6f6f6; */
        border-radius: 4px 0px 0px 4px;
        padding: 15px 0px 0px 0px;
      }
      &:last-child {  
        /* background: #f6f6f6; */
        border-radius: 0px 4px 4px 0px;
        border-left: 2px solid white;
              
       
      }
    }
  }
`;
