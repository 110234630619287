import React from 'react';
import { ResumoUpload as Container } from './styles';

import user from '../../assets/img/admin/user-cinza.svg';

export default function ResumoUpload({ resume }) {

  return (
    <Container>
      <h4>Resumo do Upload</h4>
      <div className="usuarios">
        <img src={user} alt="" />
        <span>{resume.usuarios_totais} usuários</span>
      </div>
      <div className={`${resume.usuarios_com_erros === 0 ? ' erro success' : 'erro error'}`}>
        <span>{resume.usuarios_com_erros} erros</span>
      </div>
    </Container>
  );
}
