import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    flex-grow: 6;
    align-items: flex-start;
    padding: 24px;
    width: auto;
    height: 135px;
    left: 0px;
    top: 0px;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0px 9.5px;
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #6F3E98;
        margin-bottom: 8px;
    }
    .title{
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #4B4B4B;
    }

    img{
        width:24px;
        height: 24px;
        margin-bottom : 27px;
    }
`;
