import React, {useContext} from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";

import { Container } from "./styles";


import img from "../../assets/img/admin/seta-branca.svg";

function TopoCadastro() {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const userSplit =() => {
    if (user && user.name) {
     return user.name.split(' ')[0];
    }
  }

  return (
    <Container>
      <h4 onClick={() => history.goBack()}>
        <img src={img} alt="" />
        Voltar
      </h4>

      <h3>Olá {userSplit()}</h3>
    </Container>
  );
}

export default TopoCadastro;
