import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 48px;
    .color {
        width: 221px;
        height: 28px;
        left: 0px;
        top: 86px;
        background: ${(props) => props.color};
        border-radius: 4px;
        margin-top: 24px;
    }

    span {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
    }
    .title{
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #4B4B4B;
    }

    img{
        width:10px;
        height: 10px;
        margin-bottom : 26px;
    }
`;
