import styled from 'styled-components';

export const Container = styled.div`

  .container-checks {
    .pretty {
      display: flex;
      align-items: center;     
      .state {
        label {
          margin-left: 10px;
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
          color: ${(props) => props.theme.colors.Cinza02};
        }
      }
    }
  }
`;
