import React from "react";
import { RelatoriosAcao } from "./styles";
import statusFinish from "../../assets/img/admin/statusFinish.png";
import statusProgress from "../../assets/img/admin/statusProgress.png";
import Button from "../button";

const relatoriosAcao = ({ data }) => {
  const dowload = () => {
    window.open(data.link);
  };

  return (
    <RelatoriosAcao>
      {data ? (
        <>
          <span>{data.data}</span>
          <span>{data.nome}</span>
          {/* eslint-disable-next-line */}
          <span>
            {data.status === 1 ? (
              <img className="icone" src={statusProgress} alt=""></img>
            ) : (
              <img className="icone" src={statusFinish} alt=""></img>
            )}
          </span>
          <span>
            {data.status === 1 ? (
              ""
            ) : (
              <Button
                title="Baixar"
                type="button"
                color="#703D98"
                bgc="#fff"
                onClick={() => dowload()}
              />
            )}{" "}
          </span>
        </>
      ) : (
        ""
      )}
    </RelatoriosAcao>
  );
};
export default relatoriosAcao;
