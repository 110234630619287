import Swal from 'sweetalert2'

export const Alert = ({title, text, icon}) => {

    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        confirmButtonText: 'OK'
      })
}