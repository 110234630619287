import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
	*{
		margin: 0px;
		padding: 0px;
		box-sizing: border-box;
		outline: 0px;
		font-family: "Poppins-Regular";
		font-weight: 400;
	}

	.fix-body{
		overflow: hidden;
  }

  .container-form{
		margin-bottom: 14px;
    position: relative;
    width: 383px;
    img {
      position: absolute;
      right: 10px;
      top: 20px;
      &.info {
        right: -30px;
      }
    }
    
  }

  .MuiFormControl-root {
    width: 100%;
  }
  .MuiAutocomplete-root {
    width: 100% !important;
  }
  .MuiInputLabel-root {
    font-family: ${(props) => props.theme.fonts.pr} !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 18px !important;
    letter-spacing: 2px !important;
    color: ${(props) => props.theme.colors.Cinza03} !important;
    &.Mui-focused {
      color: #9c27b0 !important;
    }
    &.Mui-error {
      color: #d32f2f !important;
    }
  }
  .MuiFilledInput-root {
    background-color: ${(props) => props.theme.colors.Cinza05} !important;
    border-bottom-left-radius: 4px; 
    border-bottom-right-radius: 4px;
    &.Mui-focused {
      border: 1px solid #9c27b0;
    }
    &:before,
    &:after {
      content: none !important;
    }
  }

  input, .custom-file  {
    background: ${(props) => props.theme.colors.Cinza05};
    border: none;
    border-radius: 4px;
    height: 58px;
    width: 383px;
    padding-left: 29px;
    padding-right: 20px;

    font-family: ${(props) => props.theme.fonts.pr};
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 2px;
    color: ${(props) => props.theme.colors.Cinza03};
    &::-webkit-input-placeholder {
      color: ${(props) => props.theme.colors.Cinza03};
    }
    &:-ms-input-placeholder {
      color: ${(props) => props.theme.colors.Cinza03};
    }
    &::placeholder {
      color: ${(props) => props.theme.colors.Cinza03};
    }
    /* &:focus {
      color: ${(props) => props.theme.colors.preto};
      border: 1px solid #762678;
      background: ${(props) => props.theme.colors.branco};
    } */
  }

  input[type='number'] {
    -moz-appearance:textfield;
  }

  input[type='checkbox'] {
    width: auto;
  }

  textarea{
    background: ${(props) => props.theme.colors.Cinza05};
    border: none;
    border-radius: 4px;
    height: 164px;
    width: 383px;
    padding-left: 29px;
    padding-right: 20px;
    padding-top: 20px;

    font-family: ${(props) => props.theme.fonts.pr};
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 2px;
    color: ${(props) => props.theme.colors.Cinza03};
    &::-webkit-input-placeholder {
      color: ${(props) => props.theme.colors.Cinza03};
    }
    &:-ms-input-placeholder {
      color: ${(props) => props.theme.colors.Cinza03};
    }
    &::placeholder {
      color: transparent;
    }
    /* &:focus {
      color: ${(props) => props.theme.colors.preto};
      border: 1px solid #762678;
      background: ${(props) => props.theme.colors.branco};
    } */
    &:focus + label,
    &:not(:placeholder-shown) + label{
      font-size: 13px;
      margin-top: 0;
      color: #3951b2;
    }
  }
  .container-form {
    .label-textarea{
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 13px;
      transition: all .3s ease-out;
      -webkit-transition: all .3s ease-out;
      -moz-transition: all .3s ease-out;
    }
  }

  .custom-file {
    position: static !important;
    pointer-events: all !important;
    border: 1px dashed #C7C7C7;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    input[type='file'] {
      display: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

	.scroll-y{
		&::-webkit-scrollbar {
			-webkit-appearance: none;
		}

		&::-webkit-scrollbar:vertical {
			width: 5px;
		}

		&::-webkit-scrollbar:horizontal {
			height: 12px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: rgba(89,92,118,0.5);

		}

		&::-webkit-scrollbar-track {

			background-color: rgba(255,255,255, 0.1);
		}
	}

	#root{
		margin-left: auto;
		margin-right: auto;
		/* max-width: 1920px; */
	}

	a,button{
		text-decoration: none;
		outline: none;
		border: none;
		cursor: pointer;
	}

	.container{
		width: 1215px;
		margin-left: auto;
		margin-right: auto;
	}

	li{
		list-style: none;
	}

	body{
		text-rendering: optimizeLegibility !important;
		-webkit-font-smoothing: antialiased !important;
		/* font-family: Montserrat; */
	}

	@media (max-width: 1366px) {
		.container {
			width: 90%;
		}
  }

  @media (max-width: 480px) {
    .container-form{
      width: 100%;
      input{
        width: 100%;
      }
    }
  }

`;
