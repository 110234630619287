import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Progress from '../progress';

import { Container } from './styles';

import img from '../../assets/img/admin/vendeu-ganhou.png';
import img1 from '../../assets/img/admin/calendario.svg';
import img2 from '../../assets/img/admin/participantes.svg';
import img3 from '../../assets/img/admin/seta-acao.svg';
import { AcaoContext } from '../../contexts/AcaoContext';

const BoxAcao = ({ data }) => {
  const { FormataStringData } = useContext(AcaoContext);

  return (
    <Container color={data.cor ? data.cor : '#6f3e98'}>
      <div className="s-topo">
        <div className="img-destaque">
          <img src={data.link_logo ? data.link_logo : img} alt="" />
          {data.status === 1 || data.msg_status === 1 ? (
            <span className="status finalizado">Inativo</span>
          ) : (
            <span className="status ativo">Ativo</span>
          )}
        </div>
        <div className="s-dir">
          <div className="titulo">
            <span>Nome da Ação</span>
            <h3>{data.nome}</h3>
            {data.job ?
            <span className="estoque job">{data?.job}</span>
            : ''}
            {data.reserva_estoque === 1 ?
              <span className="estoque demanda">Com Estoque</span>
              :
              <span className="estoque sobDemanda">Sob. Demanda</span>
            }
          </div>
          <Link className="opcoes" to={`/acoes/detalhes/${data.id}/${data.chave}`}>
            <img src={img3} alt="" />
          </Link>
          <div className="datas">
            <div className="data">
              <img src={img1} alt="" />
              <div>
                <span>Data de início</span>
                <p>{FormataStringData(data.data_inicio)}</p>
              </div>
            </div>
            <div className="data">
              <img src={img1} alt="" />
              <div>
                <span>Data de fim</span>
                <p>{FormataStringData(data.data_fim)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="s-footer">
        <div className="s-esq">
          <span>Nº de Participantes</span>
          <h2>
            <img src={img2} alt="" />
            {data.participantes_qtd}
          </h2>
        </div>
        <div className="s-dir">
          <div className="linkTitulo">
            <span className='spanLink'>Links</span>
          </div>
          <div className="links">
            {/* <span>Links</span> */}

            {data.reserva_estoque === 0 ?
              <span className="usados_reserva">Usados</span>
              :
              <>
                <span className="usados">Usados</span>
                <span className="reservados">Reservados</span>
                {/* <span className="estornados">Estornados</span> */}
              </>
            }
          </div>
          <Progress
            reserva_estoque={data.reserva_estoque}
            usados={data?.links_usados}
            reservados={data?.links_reservados}
            estornados={data?.links_estornados}
          />
        </div>
      </div>
    </Container>
  );
};

export default BoxAcao;
