import React, { useState, useEffect, useMemo } from "react";

import Button from "../button";
import CheckBox from "../checkbox";

import { AddProduto } from "./styles";
import { Container as BoxProduto } from "../boxProduto/stylesBox";
import { Products } from "./styles";
import { api } from "../../services/api";

import close from "../../assets/img/admin/close.png";
import produtoNotFound from "../../assets/img/admin/produto_not_found.png";

const ModalAddProduto = (props) => {
  const [produtos, setProdutos] = useState([]);
  const [produto, setProduto] = useState([]);
  const [busca, setBusca] = useState("");
  const [List, setList] = useState();
  const [SelectedList, setSelectedList] = useState([]);

  useEffect(() => {
    api
      .post(`/acao/produtos`)
      .then((data) => {
        setProduto(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    let tempListProduto = [];
    if (produto) {
      produto.map((produto) =>
        produto.variacoes?.map((variacoes) =>
          // variacoes.selected = false
          tempListProduto.push(variacoes)
        )
      );
      setProdutos(tempListProduto);
      setList(tempListProduto);
    }
  }, [produto]);

  const produtosFiltrados = useMemo(() => {
    return produtos.filter((produto) =>
      produto.nome.toLowerCase().includes(busca.toLowerCase())
    );
  }, [busca, produtos]);

  const onItemCheck = (e, item) => {
    let tempList = List;
    tempList.map((produto) => {
      if (produto.id === item.id) {
        produto.selected = e.target.checked;
      }
      return produto;
    });
    setList(tempList);
    setSelectedList(List.filter((e) => e.selected));
  };

  const addProdutoSelecionado = () => {
    props.setProdutos(SelectedList);
    props.close();
  };

  return (
    <AddProduto>
      <h3>Novo Produto</h3>
      <img className="close" src={close} alt="" onClick={() => props.close()} />

      <input
        type="text"
        placeholder="Nome do Produto"
        onChange={(e) => setBusca(e.target.value)}
      />

      <Products>
        {produtosFiltrados.length > 0 ? (
          // eslint-disable-next-line
          produtosFiltrados.map((item) => {
            if (item.total_disponivel) {
              return (
                <BoxProduto key={item.id} borderColor={item.selected}>
                  <CheckBox
                    type="checkbox"
                    checked={item.selected}
                    id={item.id}
                    onChange={(e) => onItemCheck(e, item)}
                  />
                  <img src={item.foto_capa} alt="" />
                  <h4>{item.nome}</h4>
                  <span>{"R$ " + parseInt(item.valor_reais)}</span>
                  <span>{"Estoque : " + item.total_disponivel}</span>
                </BoxProduto>
              );
            }
          })
        ) : (
          <BoxProduto>
            <img src={produtoNotFound} alt="" />
            <h4>Nenhum produto encontrado</h4>
            <span></span>
            <span></span>
          </BoxProduto>
        )}
      </Products>
      <Button title="Adicionar produto" onClick={addProdutoSelecionado} />
    </AddProduto>
  );
};

export default ModalAddProduto;
