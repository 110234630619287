import React, { useEffect, useState } from "react";
import {Tabela} from './styles';
import img from "../../assets/img/admin/loader-yetz-1.svg";
import UsuarioTable from "../../components/rowTabelas/usuariosAcao"
import { Paginacao } from '../../components/paginacao';
import { getAcaoParticipantes } from '../../utils/helper';
import { useDebounce} from '../../utils/helper';
import FiltroUsuario from '../../components/filtroUsuario'

function Usuarios(props) {
  const [dados, setDados] = useState([]);
  const [busca, setBusca] = useState(null);
  const [loading, setLoading] = useState(true);
  const [configPage, setConfigPage] = useState({});
  const [debouncedState, setDebouncedState] = useDebounce(busca);

  useEffect(() => {
    async function getItens() {
      if (props.id) {
        setLoading(true);
        const { result, page } = await getAcaoParticipantes(props.id, '', debouncedState);
        setDados(result);
        setLoading(false);
        setConfigPage(page);

      }
    }
    getItens();
  }, [props.id, debouncedState]);

  
  const handleChange = (event) => {
    if (event && event.value){
      setBusca(event.value);
      setDebouncedState(event.value);
    }else{
      setBusca(event.target.value);
      setDebouncedState(event.target.value);
    }
  };

  const filtroPolo =() =>{
    if (dados){
    const polos = dados.map(o => o.polo)
    const filtered = dados.filter(
      ({polo}, index) => !polos.includes(polo, index + 1)
    )
    const result = filtered.map(o => (
      {
        value: o.polo , label: o.polo
      }
    ))
    return result;
    }
  
  }
  const filtroCelula =() =>{
    if (dados){
      const celulas = dados.map(o => o.celula)
    const filtered = dados.filter(
      ({celula}, index) => !celulas.includes(celula, index + 1)
    )
    const result = filtered.map(o => (
      {
        value: o.celula , label: o.celula
      }
    ))
    return result;
    }
  }
  const filtroStatus =() =>{
   let options = [
      { value: 0, label: "Ativos" },
      { value: 1, label: "Inativos" },
    ]
    return options
  }

  async function paginacao(paginacao) {
    setLoading(true);
    const { result, page } = await getAcaoParticipantes(props.id, paginacao);
    setDados(result);
    setConfigPage(page);
    setLoading(false);
  }

  const nextPage =
    configPage?.current_page <= configPage.last_page
      ? configPage.current_page + 1
      : configPage.current_page;

  const previusPage =
    configPage?.current_page >= 1
      ? configPage.current_page - 1
      : configPage.current_page;

  const canNextPage = nextPage <= configPage.last_page;
  const canPreviustPage = configPage?.from - 1 >= 1 ? true : false;

  return (
    <>
      {loading ? (
          <img className="img-loading" src={img} alt="" />
        ) : (
          <>          
          <FiltroUsuario 
            value ={busca}
            handleChange={handleChange}            
            filtroCelula ={filtroCelula()}
            filtroStatus ={filtroStatus()}
            filtroPolo={filtroPolo()}  
            />
          <Tabela>
              <div className="conteudo">
                <div className="titulo">
                  <span>Login</span>
                  <span>Célula</span>
                  <span>Nome</span>
                  <span>Campo1</span>
                  <span>Campo2</span>
                  <span>Primeiro Acesso</span>
                  <span>Status</span>                               
                </div>                
                  {dados.map((data, index) => {
                    return <UsuarioTable data={data} key={index} />;
                    })
                  }
              </div>
              <Paginacao
                page={configPage?.current_page}
                nextPage={() => paginacao(nextPage)}
                previousPage={() => paginacao(previusPage)}
                canPreviousPage={canPreviustPage}
                canNextPage={canNextPage}
                goToPage={(page) => paginacao(page)}
                lastPage={configPage?.last_page}
             />  
            </Tabela>
          </>
        )}
    </>
  );
}

export default Usuarios;