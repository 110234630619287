import styled from 'styled-components';

export const Container = styled.div`
  background: #F8F8F8;
  width: 600px;
  height:78px;
  display: flex;
  align-items: center;
  padding: 16px 29px;
  margin-top:12px;
  border-radius: 8px;
  .name{
    display:flex;
    width:100%;
    align-items: center;
    justify-content: center;
    .items{
      flex: 1;
      text-align: center;
      align-content: center;
      h4{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #000000;
      }
      span{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #575757;
      }
    }
  }
  img{
     padding: 0 16px 0 16px;
     max-width: 73px;
     max-height:auto;
  }

  
`;
