import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";
import Button from "../button";
import InputColor from "../inputColor";
import InputImg from "../inputImg";

import { Btns } from "./styles";

function SkinDesign({ values = null, onSubmit }) {
  const history = useHistory();

  // const storage = JSON.parse(localStorage.getItem('skin'));

  const initialValues = {
    logo: values?.logo ?? null,
    cor: values?.cor ?? "",
    titulo: values?.titulo ?? "",
    subtitulo: values?.subtitulo ?? "",
    descricao: values?.descricao ?? "",
  };

  const validationSchema = Yup.object().shape({
    // logo: Yup.mixed().required('Campo Obrigatório'),
    cor: Yup.string().required("Campo Obrigatório"),
    titulo: Yup.string().required("Campo Obrigatório"),
    subtitulo: Yup.string().required("Campo Obrigatório"),
    descricao: Yup.string().required("Campo Obrigatório"),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={values ?? initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        return (
          <Form>
            <InputImg
              name="logo"
              placeholder="Logo da Ação"
              previewBackground={values.cor}
            />
            <InputColor name="cor" placeholder="Cor de Fundo" />
            <div className="container-form">
              <Field
                type="text"
                label="Título"
                name="titulo"
                component={TextField}
                color="secondary"
                variant="filled"
              />
            </div>
            <div className="container-form">
              <Field
                type="text"
                label="Subtítulo"
                name="subtitulo"
                component={TextField}
                color="secondary"
                variant="filled"
              />
            </div>
            <div className="container-form">
              <Field
                type="text"
                label="Descrição"
                component={TextField}
                name="descricao"
                multiline
                rows={4}
                color="secondary"
                variant="filled"
              />
            </div>
            <Btns>
              <Button
                title="Cancelar"
                type="button"
                bgc="red"
                onClick={() => history.goBack()}
              />
              <Button title="Avançar" />
            </Btns>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SkinDesign;
