import React, { useState, useEffect, useContext } from "react";
import Switch from "react-switch";

import { api } from "../../../services/api";

import Topo from "../../../components/topoCadastro";
import Button from "../../../components/button";
import Progress from "../../../components/progress";
import Usuario from "../../../components/detalhes/usuarios";
import Acessos from "../../../components/detalhes/acessos";
import Informacoes from "../../../components/detalhes/informacoes";
import Modal from "../../../components/modal";
import ModalAddUsuario from "../../../components/modal/addUsuario";
import ModalAddDetalhes from "../../../components/modal/addDetalhes";
import ModalAddVoucher from "../../../components/modal/addVoucher";
import ModalAlteraData from "../../../components/modal/alteraData";
import ModalVoucherProdutos from "../../../components/modal/voucherProdutos";
import ModalEstoqueParcial from "../../../components/modal/estoqueParcial";
import ModalExportaRelatorio from "../../../components/modal/exportaRelatorio";
import visualizar from "../../../assets/img/admin/olho-login.svg";
import { Detalhes } from "./styles";
import Swal from "sweetalert2";
import Relatorios from "../../../components/detalhes/relatorios";
import { AuthContext } from "../../../contexts/AuthContext";

function DetalhesAcao(props) {
  const { user } = useContext(AuthContext);
  const [checked, setChecked] = useState(false);
  const [currentForm, setForm] = useState("usuarios");
  const [modalExportaRelatorioIsOpen, setExportaRelatorioIsOpen] =
    useState(false);
  const [modalEstoqueParcialIsOpen, setEstoqueParcialIsOpen] = useState(false);
  const [modalAddUsuarioIsOpen, setModalAddUsuarioIsOpen] = useState(false);
  const [modalAddProdutoIsOpen, setModalAddProdutoIsOpen] = useState(false);
  const [modalAddDetalhesIsOpen, setModalAddDetalhesIsOpen] = useState(false);
  const [modalVoucherProdutoIsOpen, setmodalVoucherProdutoIsOpen] =
    useState(false);
  const [modalAlteraDataIsOpen, setModalAlteraDataIsOpen] = useState(false);
  const [dados, setDados] = useState([]);
  const [dadosAcao, setDadosAcao] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [produtoSelecionado, setProdutoSelecionado] = useState([]);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    if (nextChecked !== true) {
      setModalAddDetalhesIsOpen(true);
    } else {
      activeStatus();
    }
  };

  useEffect(() => {
    api
      .post(`/acao/busca`, { id: props.match.params.id })
      .then((data) => {
        setDados(data.acao);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.match.params.id]);

  async function activeStatus() {
    api
      .post(`/acao/mudaStatus`, {
        id_acao: props.match.params.id,
        status: "2",
      })
      .then((data) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    api
      .post(`/acao/produtos`)
      .then((data) => {
        setProdutos(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    api
      .post(`/buscaPorChave`, { chave: props.match.params.acao })
      .then((data) => {
        setDadosAcao(data.retorno);
        if (data.retorno.status === 1) {
          setChecked(false);
        } else {
          setChecked(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props, checked]);

  const active = (value) => {
    return currentForm === value ? "active" : "";
  };

  function _renderContent() {
    switch (currentForm) {
      case "usuarios": {
        return <Usuario id={props.match.params.id} />;
      }
      case "informacoes": {
        return (
          <Informacoes
            dados={dados}
            onClick={() => setModalAlteraDataIsOpen(true)}
          />
        );
      }
      case "acessos": {
        return <Acessos id={props.match.params.id} />;
      }
      case "relatorios": {
        return <Relatorios id={props.match.params.id} />;
      }
      default: {
        break;
      }
    }
  }
  const newPageUrl = () => {
    window.open(`https://yetzpromo.com.br/${dadosAcao.url}`);
  };

  const handleLiberarEstoque = () => {
    Swal.fire({
      title: "<strong>Tem certeza?</strong>",
      icon: "info",
      html:
        "Ao liberar estoque os vouchers não usados serão marcados como estornados. " +
        "Essa ação não poderá ser desfeita. Tem certeza que deseja continuar?",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Swal.fire({
          icon: "warning",
          title: "<strong>Confirma a liberação do estoque?</strong>",
          html: `Serão estornados <b> ${
            dadosAcao.links_reservados - dadosAcao.links_usados
          } </b> vouchers após a confirmação, isso não poderá ser desfeito.`,
          showCancelButton: true,
          confirmButtonColor: "#d33",
          confirmButtonText: "Sim, confirmo.",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            api
              .post(`/acao/estornaPedido`, { id_acao: props.match.params.id })
              .then((data) => {
                if (data.status === true) {
                  Swal.fire({
                    icon: "success",
                    title: "Sucesso ao estornar estoque!",
                    html: "O estorno está em processamento, aguarde alguns minutos até que seja concluído....",
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      } else if (result.isDenied) {
        Swal.fire("Erro ao estornar", "", "info");
      }
    });
  };

  const handleResetarAcao = () => {
    api
      .post(`acao/resetarAcao`, {
        id_acao: props.match.params.id,
      })
      .then((data) => {
        if (data.status === true) {
          Swal.fire({
            icon: "success",
            title: "Ação resetada!",
            html: data.mensagem,
          }).then((result) => {
            window.location.reload();
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "Erro ao resetar ação!",
            html: data.mensagem,
          });
        }
      })
      .catch((erro) => {
        console.log("error ", erro);
      });
  };

  const quantidadeEstornoEstoque =
    dadosAcao.links_reservados -
    (dadosAcao.links_usados + dadosAcao.links_estornados);

  return (
    <Detalhes>
      <Topo />
      <div className="container">
        <div className="progress-btn">
          <Progress
            reserva_estoque={dadosAcao.reserva_estoque}
            reservados={dadosAcao.links_reservados}
            usados={dadosAcao.links_usados}
            estornados={dadosAcao.links_estornados}
          />
          <div className="btns">
            <Button
              title=""
              color="#703D98"
              bgc="#fff"
              img={visualizar}
              onClick={() => newPageUrl()}
            />
            {dados.demo !== 1 && user?.tipo === 1 ? (
              <Button
                title={
                  quantidadeEstornoEstoque === 0
                    ? `Estoque Liberado`
                    : `Liberar Estoque: ${quantidadeEstornoEstoque} `
                }
                onClick={() => handleLiberarEstoque()}
                disabled={quantidadeEstornoEstoque === 0}
                color={quantidadeEstornoEstoque === 0 ? "#d3d3d3" : "#703D98"}
                bgc="#fff"
              />
            ) : (
              ""
            )}
            {dados.demo !== 1 &&
            user?.tipo === 1 &&
            quantidadeEstornoEstoque !== 0 ? (
              <Button
                title="Liberar Est. Parcial"
                onClick={() => setEstoqueParcialIsOpen(true)}
                color="#703D98"
                bgc="#fff"
              />
            ) : (
              ""
            )}
            {dados.demo === 1 && user?.tipo === 1 ? (
              <Button
                title="Resetar Ação"
                onClick={() => handleResetarAcao()}
                color="#703D98"
                bgc="#fff"
              />
            ) : (
              ""
            )}
            <Button
              title="Exportar Relatório"
              color="#703D98"
              bgc="#fff"
              onClick={() => setExportaRelatorioIsOpen(true)}
            />
            {user?.tipo === 1 ? (
              <>
                <Button
                  title="+ Adicionar Usuário"
                  color="#703D98"
                  bgc="#fff"
                  onClick={() => setModalAddUsuarioIsOpen(true)}
                />
                {dados.demo !== 1 ? (
                  <Button
                    title="+ Adicionar Vouchers"
                    color="#703D98"
                    bgc="#fff"
                    onClick={() => setModalAddProdutoIsOpen(true)}
                  />
                ) : (
                  ""
                )}
                <span>Ativar</span>
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  onColor="#44E0A1"
                  onHandleColor="#FFFFFF"
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                  className="react-switch"
                  id="material-switch"
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="abas-tabela">
          <div className="abas">
            <span
              className={"item " + active("acessos")}
              onClick={() => {
                setForm("acessos");
              }}
            >
              Acessos
            </span>
            <span
              className={"item " + active("usuarios")}
              onClick={() => {
                setForm("usuarios");
              }}
            >
              Usuários
            </span>
            <span
              className={"item " + active("informacoes")}
              onClick={() => {
                setForm("informacoes");
              }}
            >
              Informações
            </span>
            <span
              className={"item " + active("relatorios")}
              onClick={() => {
                setForm("relatorios");
              }}
            >
              Relatórios
            </span>
          </div>
        </div>
        {_renderContent()}
      </div>
      <Modal
        // onChange={onChange}
        component={ModalAddUsuario}
        active={modalAddUsuarioIsOpen}
        handleClose={() => setModalAddUsuarioIsOpen(false)}
        id_acao={props.match.params.id}
      />
      <Modal
        // onChange={onChange}
        component={ModalAddDetalhes}
        active={modalAddDetalhesIsOpen}
        handleClose={() => setModalAddDetalhesIsOpen(false)}
        id_acao={props.match.params.id}
        status={checked === true ? "0" : "1"}
      />

      <Modal
        produtosEstoque={produtos}
        produtosSelecionado={dados}
        component={ModalAddVoucher}
        active={modalAddProdutoIsOpen}
        setProdutos={(produto) => {
          setProdutoSelecionado(produto);
        }}
        close={() => {
          setModalAddProdutoIsOpen(false);
        }}
        closed={() => {
          setModalAddProdutoIsOpen(false);
          setmodalVoucherProdutoIsOpen(true);
        }}
      />
      <Modal
        reserva_estoque={dados?.reserva_estoque ?? 1}
        data={produtoSelecionado}
        acao={props.match.params.id}
        component={ModalVoucherProdutos}
        active={modalVoucherProdutoIsOpen}
        close={() => setmodalVoucherProdutoIsOpen(false)}
      />
      <Modal
        data={dados}
        acao={props.match.params.id}
        component={ModalAlteraData}
        active={modalAlteraDataIsOpen}
        close={() => setModalAlteraDataIsOpen(false)}
      />
      <Modal
        component={ModalEstoqueParcial}
        active={modalEstoqueParcialIsOpen}
        handleClose={() => setEstoqueParcialIsOpen(false)}
        id_acao={props.match.params.id}
      />
      <Modal
        component={ModalExportaRelatorio}
        active={modalExportaRelatorioIsOpen}
        handleClose={() => setExportaRelatorioIsOpen(false)}
        id_acao={props.match.params.id}
      />
    </Detalhes>
  );
}

export default DetalhesAcao;
