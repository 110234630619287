import React from 'react';

import { Container } from './styles';

const BoxProdutoDetalhe = ({produto}) =>  {

  return (
    <Container>
      {produto ?
          <>
            <div className="name">           
                <div className="items"><h4>{ produto.id_pedido?? ''}</h4></div> 
            </div>     
          </>
        : ''
       }
    </Container>
  );
}

export default BoxProdutoDetalhe;
