import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  background: linear-gradient(180deg, #6f3e98 53.23%, #8b288c 92.92%);
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
    padding: 100px 0;
    h1 {
      font-weight: 600;
      font-size: 42px;
      line-height: 38px;
      color: ${(props) => props.theme.colors.branco};
      margin-bottom: 50px;
      text-align: center;
      &::before {
        content: '';
        background-color: white;
        display: block;
        border-radius: 4px;
        width: 40px;
        height: 4px;
        margin: 0 auto;
        margin-bottom: 40px;
      }
    }
    .topo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.branco};
      }
    }
    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      span {
        font-weight: normal;
        font-size: 12px;
        line-height: 150%;
        color: ${(props) => props.theme.colors.branco};
        text-align: center;
        margin-top: 20px;
      }
      button {
        font-weight: 500;
        font-size: 16px;
        line-height: 38px;
        color: ${(props) => props.theme.colors.branco};
        background: none;
        border: none;
      }
    }
    .conteudo {
      align-self: center;
      .box {
        display: flex;
        grid-gap: 20px;
      }
    }
  }
`;
