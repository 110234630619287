import React from "react";
import { Container } from "./styles";
import Card from "../cardInformacao";
import LabelDetalhes from "../labelDetalhes";
import BoxProdutoDetalhe from "../boxProdutoDetalhe";
import BoxProdutoId from "../boxProdutoId";
import calendario from "../../assets/img/card-detalhes/calendario.png";
import url from "../../assets/img/card-detalhes/link.png";
import chave from "../../assets/img/card-detalhes/chave.png";
import perfil from "../../assets/img/card-detalhes/perfil.png";
import copy from "../../assets/img/card-detalhes/copy.png";
import userInfo from "../../assets/img/card-detalhes/user-info.png";
import linkInfo from "../../assets/img/card-detalhes/link-info.png";

function Informacoes({ dados, onClick }) {
  const countVouchers = dados?.produtos?.reduce(function (
    acumulador,
    objetoAtual
  ) {
    return acumulador + objetoAtual.qtd_disponivel;
  },
  0);

  return (
    <>
      <Container color={dados?.cor}>
        <div className="detalhes">
          <div className="logo">
            {/* eslint-disable-next-line */}
            <img src={dados?.link_logo}></img>
          </div>
          <div className="detalhes-card">
            <div className="detalhes-arquivo">
              <div className="detalhes-item">
                <span>Arquivo de usuários</span>
                <span className="subtitle">lista_de_usuarios.xls</span>
              </div>
              <div className="detalhes-item">
                <span>Total de usuários</span>
                <span className="subtitle">
                  {/* eslint-disable-next-line */}
                  <img src={userInfo} />
                  {dados?.qtdParticipantes} Usuários
                </span>
              </div>
              <div>
                <span>Total de vouchers</span>
                <span className="subtitle">
                  {/* eslint-disable-next-line */}
                  <img className="imgVoucher" src={linkInfo} />
                  {dados ? countVouchers : 0} Vouchers
                </span>
              </div>
            </div>
            <div className="block-card">
              <Card title="Nome" subtitle={dados?.nome} icon={copy} />
              <Card
                title="Contratante"
                subtitle={dados?.contratante ? dados?.contratante[0]?.nome : ""}
                icon={perfil}
              />
              <Card
                title="Chave de Ação"
                subtitle={dados?.chave}
                icon={chave}
              />
              <Card title="Url da Ação" subtitle={dados?.url} icon={url} />
              <Card
                title="Data e Hora de inicio"
                subtitle={dados?.data_inicio}
                icon={calendario}
              />
              <div className="clicavel" onClick={onClick}>
                <Card
                  title="Data e Hora de fim"
                  subtitle={dados?.data_fim}
                  icon={calendario}
                />
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="grid">
          <div className="item">
            <div className="label-detalhes">
              <LabelDetalhes title="Login" subtitle={dados?.tipo_login} />
              <LabelDetalhes title="Label Login" subtitle={dados?.tipo_login} />
              <LabelDetalhes
                title="Tipo de Senha"
                subtitle={dados?.tipo_senha}
              />
              <LabelDetalhes
                title="Exigir Cadastro Completo"
                subtitle={dados?.exigir_cadastro_completo === 1 ? "Sim" : "Não"}
              />
            </div>
          </div>
          <div className="item">
            <LabelDetalhes
              title="Cor de Fundo"
              subtitle={dados?.cor}
              color={dados?.cor ?? "white"}
            />
            <LabelDetalhes title="Título" subtitle={dados?.titulo} />
            <LabelDetalhes title="Subtítulo" subtitle={dados?.subtitulo} />
          </div>
          <div className="item">
            <LabelDetalhes title="Descrição" subtitle={dados?.descricao} />
            <div className={"produtos"}>
              <LabelDetalhes title="Pedidos" />
              {dados?.produtos
                ? dados?.pedidos_listagem.map((produto, index) => {
                    return <BoxProdutoId key={index} produto={produto} />;
                  })
                : ""}
            </div>
          </div>
          <div className="item">
            <div className={"produtos"}>
              <LabelDetalhes title="Produtos" />
              {dados?.produtos
                ? dados?.produtos.map((produto, index) => {
                    return <BoxProdutoDetalhe key={index} produto={produto} />;
                  })
                : ""}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Informacoes;
