import React from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import * as Yup from "yup";

import { api } from "../../../services/api";

import Button from "../../../components/button";
import Topo from "../../../components/topoCadastro";
import FormikTextField from "../../../components/inputMask";
import InputCpfCnpj from "../../../components/inputCpfCnpj";
import { Alert } from "../../../components/alert/index";

import { CONTRATANTES } from "../../../routes/paths";

import { Novo } from "./styles";

import logo from "../../../assets/img/admin/logo3.svg";

function NovoContratante({ values = null }) {
  const history = useHistory();

  const initialValues = {
    cnpj: values?.cnpj ?? "",
    nome: values?.nome ?? "",
    area: values?.area ?? "",
    telefone: values?.telefone ?? "",
    contato: values?.contato ?? "",
    email: values?.email ?? "",
    celular: values?.celular ?? "",
  };

  const validationSchema = Yup.object().shape({
    cnpj: Yup.string().required("Campo Obrigatório"),
    nome: Yup.string().required("Campo Obrigatório"),
    area: Yup.string().required("Campo Obrigatório"),
    telefone: Yup.string().required("Campo Obrigatório"),
    contato: Yup.string().required("Campo Obrigatório"),
    email: Yup.string().required("Campo Obrigatório"),
    celular: Yup.string().required("Campo Obrigatório"),
  });

  async function handleSubmit(values) {
    api
      .post(`/contratante`, {
        nome: values.nome,
        email: values.email,
        cnpj: values.cnpj,
        area: values.area,
        telefone: values.telefone,
        celular: values.celular,
        contato: values.contato,
      })
      .then((data) => {
        Alert({
          title: "sucesso",
          text: "Sucesso ao cadastro",
          icon: "success",
        });
        history.push(CONTRATANTES);
      })
      .catch((error) => {
        Alert({
          title: "Erro",
          text: error,
          icon: "error",
        });
      });
  }

  return (
    <Novo>
      <Topo />
      <div className="container">
        <div className="s-esq">
          <img src={logo} alt="" />
          <span>Dados do Contratante</span>
        </div>
        <div className="s-dir">
          <h1>Novo Contratante</h1>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              //   const { values, setFieldValue } = props;
              return (
                <Form>
                  <div className="container-form">
                    <Field
                      component={TextField}
                      label="CNPJ"
                      name="cnpj"
                      variant="filled"
                      InputProps={{
                        inputComponent: InputCpfCnpj,
                        inputProps: { formik: props },
                      }}
                    />
                  </div>
                  <div className="container-form">
                    <Field
                      type="text"
                      label="Nome do Contratante"
                      name="nome"
                      component={TextField}
                      variant="filled"
                      color="secondary"
                    />
                  </div>
                  <div className="container-form">
                    <Field
                      type="text"
                      label="Área"
                      name="area"
                      component={TextField}
                      variant="filled"
                      color="secondary"
                    />
                  </div>
                  <div className="container-form">
                    <Field
                      component={TextField}
                      label="Telefone"
                      name="telefone"
                      variant="filled"
                      InputProps={{
                        inputComponent: FormikTextField,
                        inputProps: { mask: "(99) 9999-9999", formik: props },
                      }}
                    />
                  </div>

                  <div className="container-form">
                    <Field
                      type="text"
                      label="Contato"
                      name="contato"
                      component={TextField}
                      variant="filled"
                      color="secondary"
                    />
                  </div>
                  <div className="container-form">
                    <Field
                      type="email"
                      label="Email"
                      name="email"
                      component={TextField}
                      variant="filled"
                      color="secondary"
                    />
                  </div>
                  <div className="container-form">
                    <Field
                      component={TextField}
                      label="Celular"
                      name="celular"
                      variant="filled"
                      InputProps={{
                        inputComponent: FormikTextField,
                        inputProps: { mask: "(99) 99999-9999", formik: props },
                      }}
                    />
                  </div>

                  <Button
                    title="Cadastrar"
                    // className={load ? "disabled" : ""}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Novo>
  );
}

export default NovoContratante;
