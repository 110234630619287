import styled from 'styled-components';

export const Container = styled.button`
  width: 234px;
  height: 46px;
  background-color: ${(props) =>
    props.bgc ? props.bgc : props.theme.colors.Verde1};
  color: ${(props) => (props.color ? props.color : props.theme.colors.branco)};
  font-family: ${(props) => props.theme.fonts.pr};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  border: 1px solid ${(props) => (props.color ? props.color : '')};
  .img{
    margin-top: 5px;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;
