import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Progress from '../progress';

import { Row } from './styles';

import img from '../../assets/img/admin/vendeu-ganhou.png';
import img1 from '../../assets/img/admin/calendario.svg';
import img2 from '../../assets/img/admin/participantes.svg';
import img3 from '../../assets/img/admin/seta-acao.svg';
import { AcaoContext } from '../../contexts/AcaoContext';

const RowAcao = ({ data }) => {
  const { FormataStringData } = useContext(AcaoContext);
  return (
    <Row color={data.cor ? data.cor : '#6f3e98'}>
      <div className="img-destaque">
        <img src={data.link_logo ? data.link_logo : img} alt="" />
        {data.status === 1 || data.msg_status === 1 ? (
          <span className="status finalizado">Inativo</span>
        ) : (
          <span className="status ativo">Ativo</span>
        )}
      </div>

      <div className="titulo">
        <span>Nome da Ação</span>
        <h3>{data.nome}</h3>
      </div>

      <div className="datas">
        <div className="data">
          <img src={img1} alt="" />
          <div>
            <span>Data de início</span>
            <p>{FormataStringData(data.data_inicio)}</p>
          </div>
        </div>
        <div className="data">
          <img src={img1} alt="" />
          <div>
            <span>Data de fim</span>
            <p>{FormataStringData(data.data_fim)}</p>
          </div>
        </div>
      </div>

      <div>
        <span>Nº de Part.</span>
        <h2>
          <img src={img2} alt="" />
          {data.participantes_qtd}
        </h2>
      </div>

      <div className="links-progress">
          <div className="linkTitulo">
              <span className='spanLink'>Links</span>
          </div>
        <div className="links">
          {/* <span>Links</span> */}
          {data.reserva_estoque === 1 ?
            <>
              <span className="usados">Usados</span>
              <span className="reservados">Reservados</span>
              {/* <span className="estornados">Estornados</span> */}
            </>
            :
            <span className="usados_reserva">Usados</span>
          }
        </div>
        <Progress
          reserva_estoque={data.reserva_estoque}
          reservados={data.links_reservados ?? 0}
          usados={data.links_usados ?? 0}
          estornados={data.links_estornados ?? 0}
        />
      </div>

      <Link className="opcoes" to={`/acoes/detalhes/${data.id}/${data.chave}`}>
        <img src={img3} alt="" />
      </Link>
    </Row>
  );
};

export default RowAcao;
