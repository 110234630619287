import React, { useState } from "react";

import { Container } from "./styles";

function BoxProduto(props) {
  const { produto, onKeyUp, reserva_estoque } = props;

  const [value, setValue] = useState("");

  const handleChange = (event) => {
    let { value, min, max } = event.target;
    let result = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setValue(result);
  };

  return (
    <Container>
      {produto ? (
        <>
          <div className="img-titulo">
            <img src={produto?.foto_capa} alt="" />
            <div>
              <h4>{produto?.nome}</h4>
              <span>{"R$ " + parseInt(produto.valor_reais)}</span>
            </div>
          </div>
          <span>{"Estoque : " + produto.total_disponivel}</span>
          {reserva_estoque ? (
            <div className="qtd">
              <span>Qtd.</span>
              <input
                onChange={handleChange}
                onBlur={(e) => onKeyUp(e, produto)}
                type="number"
                min="0"
                max={produto.total_disponivel}
                value={value}
              ></input>
            </div>
          ) : (
            ""
          )} 
        </>
      ) : (
        <h3>Nenhum produto selecionado </h3>
      )}
    </Container>
  );
}

export default BoxProduto;
